export const loginUrl = '/public/api/v2/auth/login';

export const updateToken = '/public/api/v2/auth/token/refresh';

export const orgUrl = '/public/api/v2/org/le/info';

export const regNewUser = '/public/api/v2/users';

export const docsUrl = '/public/api/v2/users/docs/agreement';

export const checkDocUrl = '/public/api/v2/users/docs/check'; // Проверить подписан ли документ по его типу

export const productsUrl =
  '/public/api/v2/products?withSketches=false';

export const getOfferUrl = '/public/api/v2/users/docs/body'; // Получить тело оферты по её типу

export const acceptOfferUrl =
  '/public/api/v2/users/docs/offer/accept'; // Согласиться с офертой по номеру уже созданной оферты

export const createAndAcceptOfferUrl =
  '/public/api/v2/users/docs/offer/create-and-accept'; // Создать и подписать оферту по её типу

export const checkEdoUrl =
  '/public/api/v2/users/edo/partners/connect';

export const checkDocFlowUrl =
  '/public/api/v2/users/docs/exchange/types';

export const dadataUrl =
  '/public/api/v2/dadata/suggest/address?query=';

export const deliveryUrl = '/public/api/v2/delivery/calc';

export const createOrderUrl = '/public/api/v2/fn/order';

export const listOrdersUrl = '/public/api/v2/fn/orders';

export const fileTypesUrl = '/public/api/v2/product/file/types';

export const getfileUrl = '/public/api/v2/product';

export const orderDocsUrl = '/public/api/v2/order/';

export const getfileBodyUrl = '/public/api/v2/product/file/id';

export const offertaDownloadUrl =
  '/public/api/v2/offer?contentDispositionType=inline';

export const downloadRouteInstructionUrl =
  '/public/api/v2/files/memo/id/2/body';

export const downloadFormProcurationUrl =
  '/public/api/v2/files/memo/id/3/body';

export const downloadXlxsOrdersUrl =
  '/public/api/v2/private/generate/file/xslx/fn/info';

export const dadataFindByOrganization =
  '/public/api/v2/dadata/suggest/organization';

export const LOGIN_FORM_URL_HASH = '#login';
