import { memo } from 'react';
import { useSelector } from 'react-redux';

import DownloadLink from 'components/download-link/download-link';

import { getOfferUrl } from '../../urls';
import styles from './documents.module.css';

const Documents = memo(() => {
  const { docs } = useSelector((store) => store.docs);

  return (
    <div className={styles.documents}>
      {docs?.length ? (
        <>
          {docs.map((item) => (
            <DownloadLink
              key={item.docId}
              link={`${getOfferUrl}?docType=${item.docTypeId}`}
              payload={{ responseType: 'blob' }}
              textLink={item.docName}
              linkStyle="long"
              fileName={`${item.docName}.pdf`}
              documentType="application/pdf"
            />
          ))}
        </>
      ) : (
        <p>нет документов</p>
      )}
    </div>
  );
});

Documents.displayName = 'Documents';

export default Documents;
