import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import Home from 'pages/home';
import NotFound404 from 'pages/not-found-404';
import Order from 'pages/order';
import Orders from 'pages/orders';
import ProductCard from 'pages/product-card';
import Products from 'pages/products';
import Profile from 'pages/profile';
import ServerError500 from 'pages/server-error-500';
import UserInformation from 'pages/user-information';

import { SET_DOCS_TYPES } from 'services/actions/actionTypes';
import { logOutUser, setUserType } from 'services/actions/auth';
import checkDocFlow from 'services/actions/check-doc-flow';
import checkEdo from 'services/actions/check-edo';
import checkOffer from 'services/actions/check-offer';
import getDocs from 'services/actions/documents';
import changeOrder from 'services/actions/order';
import getOrg from 'services/actions/organization';
import {
  addAlert,
  delAlert,
  delAlertByText,
} from 'services/actions/page-alerts';
import getProducts from 'services/actions/products';

import AppFooter from 'components/app-footer/app-footer';
import AppHeader from 'components/app-header/app-header';
import Loader from 'components/loader/loader';
import ModalCustomEdooffer from 'components/modal-custom-edooffer/modal-custom-edooffer';
import PageAlerts from 'components/page-alerts/page-alerts';

import { CONTENT } from 'shared/const';
import { AUTH_PROVIDERS } from 'shared/const';
import { useAuth } from 'shared/hooks/use-auth';
import httpClient from 'shared/utils/httpClient/httpClient';
import { tokens } from 'shared/utils/tokens.util';
import { authentication, getUserType } from 'shared/utils/user.util';

import { fileTypesUrl } from '../../urls';
import styles from './app.module.css';

const App = memo(() => {
  const { loader } = useSelector((store) => store.loader);
  const { modal } = useSelector((store) => store.modal);
  const { org } = useSelector((store) => store.org);
  const { products } = useSelector((store) => store.products);
  const { pageAlerts } = useSelector((store) => store.pageAlerts);

  const dispatch = useDispatch();
  const isAuth = tokens.getAccess() && tokens.getRefresh();
  const url = new URL(window.location.href);
  const token = url.searchParams.get('token');
  const refreshToken = url.searchParams.get('refresh_token');
  const serverErrorRoute = '/error500';
  const [curPage, setCurPage] = useState('');

  /* TODO:
      Временно вынес возможность авторизации через новый кейклок в консоль браузера,
      что-бы тестировщик смог проверить.
      Нужно убрать после готовности бэкенда
   */
  window.auth = useAuth();

  console.log('release/8.26.6');

  const getFileTypes = useCallback(async () => {
    try {
      const response = await httpClient.get(fileTypesUrl);
      if (response.data.length) {
        dispatch({ type: SET_DOCS_TYPES, docTypes: response.data });
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        return dispatch(logOutUser());
      }
      dispatch(
        addAlert('Ошибка получения типов документов', 'danger'),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getFileTypes();
  }, [getFileTypes]);

  useEffect(() => {
    if (!sessionStorage.order) {
      dispatch(changeOrder({ products: [], totalPrice: 0 }));
    } else {
      dispatch(changeOrder(JSON.parse(sessionStorage.order)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (token && refreshToken) {
      tokens.setAccess(token);
      tokens.setRefresh(refreshToken);
      authentication.setType(AUTH_PROVIDERS.http);
    }
    dispatch(setUserType(getUserType()));
  }, [token, refreshToken, dispatch]);

  useEffect(() => {
    if (window.location.pathname !== serverErrorRoute) {
      dispatch(getProducts());
    }

    if (isAuth) {
      dispatch(checkDocFlow()).then((isEdoFlow) => {
        if (isEdoFlow) {
          dispatch(checkEdo()).then(() => {
            dispatch(checkOffer());
          });
        } else {
          dispatch(checkOffer());
        }
      });
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (isAuth && !org) dispatch(getOrg());
  }, [dispatch, isAuth, org]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getDocs());
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (isAuth) {
      const pageAlertIndex = pageAlerts.findIndex(
        (alert) => alert.text === CONTENT.order.notAuthWarning,
      );

      if (pageAlertIndex !== -1) {
        dispatch(delAlert(pageAlertIndex));
      }
    }
  }, [pageAlerts, dispatch, isAuth]);

  useEffect(() => {
    if (!pageAlerts.length) return;
    pageAlerts.forEach((alert) => {
      setTimeout(() => {
        dispatch(delAlertByText(alert.text));
      }, alert.lifetime);
    });
  }, [dispatch, pageAlerts]);

  useEffect(() => {
    const updateOrder = () => {
      if (
        products &&
        JSON.parse(sessionStorage.order)?.products?.length
      ) {
        const updatedOrder = JSON.parse(sessionStorage.order);
        const updatedProducts = updatedOrder.products;
        updatedProducts.forEach((item) => {
          products.forEach((product) => {
            if (product.id === item.id) {
              item.price = product.price;
            }
            return false;
          });
        });

        const updatedTotalPrice = updatedProducts.reduce(
          (sum, current) => sum + current.price * current.quantity,
          0,
        );

        dispatch(
          changeOrder({
            products: updatedProducts,
            totalPrice: updatedTotalPrice,
          }),
        );
      }
    };

    updateOrder();
  }, [products, dispatch]);

  const getCurrentPage = useCallback((path) => {
    setCurPage(path);
  }, []);

  return (
    <div className="page">
      <div className={styles.wrap}>
        {curPage === '/' ? <div className={styles.back} /> : ''}
        <Router>
          <AppHeader
            func={getCurrentPage}
            homePage={curPage === '/'}
          />
          <PageAlerts />
          <main className={styles.main}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/order" element={<Order />} />
              <Route
                path="/information"
                element={<UserInformation />}
              />
              <Route path="/product/:id" element={<ProductCard />} />
              <Route
                path={serverErrorRoute}
                element={<ServerError500 />}
              />
              <Route path="*" element={<NotFound404 />} />
              <Route path="/card" element={<ProductCard />} />
            </Routes>
          </main>
          <AppFooter />
        </Router>
      </div>
      {!!loader && <Loader />}
      {!!modal && <ModalCustomEdooffer />}
    </div>
  );
});

App.displayName = 'App';

export default App;
