import cx from 'classnames';
import { FC, memo } from 'react';

import ProgressLine from 'components/progress-line/progress-line';

import styles from './progress-info-block.module.css';

interface IProgressInfoBlockProps {
  text: string;
  theme: string;
  percent?: number;
  icon: string;
}

const ProgressInfoBlock: FC<IProgressInfoBlockProps> = memo(
  ({ text, theme, percent, icon }) => (
    <div className={styles.wrap}>
      <img
        className={cx(styles.image, {
          [styles['disable-text']]: text === null || !text,
        })}
        src={icon}
        alt=""
      />
      <span
        className={cx(styles.text, {
          [styles['transform-text']]: text !== 'УПД',
        })}
      >
        {text}
      </span>
      <ProgressLine theme={theme} percent={percent} />
    </div>
  ),
);

ProgressInfoBlock.displayName = 'ProgressInfoBlock';

export default ProgressInfoBlock;
