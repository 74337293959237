import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import styles from './content-loader.module.css';
import { HistoryOrderLoader } from './history-order-loader';
import { ProductCardLoader } from './product-card-loader';
import { ProfileLoader } from './profile-loader';
import { TextLoader } from './text-loader';

const cx = classNames.bind(styles);

interface IContentLoader {
  placeholderType: string;
}

const ContentLoader: FC<IContentLoader> = memo(
  ({ placeholderType }) => {
    switch (placeholderType) {
      case 'text':
        return <TextLoader />;

      case 'image':
        return (
          <div
            className={cx('base-placeholder', 'image-placeholder')}
          />
        );

      case 'image-big':
        return (
          <div className={cx('base-placeholder', 'image-big')} />
        );

      case 'history-order':
        return <HistoryOrderLoader />;

      case 'profile':
        return <ProfileLoader />;

      case 'product-card':
        return <ProductCardLoader />;

      default:
        break;
    }

    return null;
  },
);

ContentLoader.displayName = 'ContentLoader';

export default ContentLoader;
