import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { addAlert } from 'services/actions/page-alerts';
import setSuccessOrder from 'services/actions/success-order';

import HistoryOrdersSubItem from 'components/history-orders-subitem/history-orders-subitem';
import ToolTip from 'components/tooltip/tooltip';

import { IUpdFileInfo } from 'shared/models/file.model';
import httpClient from 'shared/utils/httpClient/httpClient';

import arrow from 'images/icons/arrow.svg';

import { orderDocsUrl } from '../../urls';
import { DeliveryStatusIcon } from './components/delivery-status-icon';
import { DocumentStatusIcon } from './components/document-status-icon';
import { FnListIcon } from './components/fn-list-icon';
import { PayStatusIcon } from './components/pay-status-icon';
import { PickupStatusIcon } from './components/pickup-status-icon';
import styles from './history-orders-item.module.css';

export interface IHistoryOrdersItemProps {
  orderId: number;
  num: number;
  date: string;
  author: string;
  delivery: string;
  sum: string;
  payStatusName: string;
  deliveryStatusName: string;
  pickupStatusName?: string;
  signatureStatusName?: string;
  isNewOrder?: boolean;
  contactName: string;
  contactPhone: string;
  deliveryAddress: string;
  trackNumber: string;
  storageId: string;
  payRemain: number;
  price: number;
  adressDesc: string;
  documentStatusName: string;
  deliveryPrice: number;
  vendorName: string;
}

const HistoryOrdersItem: FC<IHistoryOrdersItemProps> = memo(
  ({
    orderId,
    num,
    date,
    author,
    delivery,
    sum,
    payStatusName,
    deliveryStatusName,
    isNewOrder,
    contactName,
    contactPhone,
    deliveryAddress,
    trackNumber,
    storageId,
    payRemain,
    price,
    adressDesc,
    documentStatusName,
    deliveryPrice,
    vendorName,
  }) => {
    const [openFlag, setOpenFlag] = useState(false);
    const [updFileInfo, setUpdFileInfo] = useState<IUpdFileInfo>();
    const [invoiceFileInfo, setInvoiceFileInfo] = useState<
      IUpdFileInfo | {}
    >({});

    const dispatch = useDispatch();

    const toggleFlag = useCallback(() => {
      setOpenFlag(!openFlag);
    }, [openFlag]);

    const getDocs = useCallback(async () => {
      try {
        const { data } = await httpClient.get(
          `${orderDocsUrl + orderId}/docs`,
        );
        data.reduce((current: IUpdFileInfo, item: IUpdFileInfo) => {
          let currentArr = current;
          if (item?.docType === 'UPD') {
            currentArr = { ...item };
            setUpdFileInfo(currentArr);
          } else if (item?.docType === 'INVOICE_ORDER') {
            currentArr = { ...item };
            setInvoiceFileInfo(currentArr);
          }
          return false;
        }, false);
      } catch (error) {
        dispatch(
          addAlert(
            'Ошибка получения документов по заказу.',
            'danger',
          ),
        );
      }
    }, [dispatch, orderId]);

    useEffect(() => {
      getDocs();
    }, [getDocs, orderId]);

    useEffect(() => {
      if (isNewOrder && !openFlag) {
        dispatch(setSuccessOrder(false));
        setOpenFlag(!openFlag);
      }
    }, [dispatch, isNewOrder, openFlag]);

    return (
      <div className={styles['orders-item']}>
        <div
          data-testid="his-order-click-1"
          onClick={toggleFlag}
          className={`${styles.main} ${
            openFlag ? styles.active : ''
          }`}
          role="button"
          tabIndex={0}
          aria-hidden="true"
        >
          <div className={styles.top}>
            <Row>
              <Col xs={8} md={1} lg={1} className="d-flex">
                <div className="number d-flex align-items-center">
                  {num}
                </div>
              </Col>

              <Col md={2} lg={2} className="d-none d-md-flex">
                <div
                  className={`${styles.date} align-items-center d-flex`}
                >
                  {date}
                </div>
              </Col>

              <Col lg={3} className="d-none d-lg-flex">
                <div
                  className={`${styles.author} align-items-center d-flex`}
                >
                  {author}
                </div>
              </Col>

              <Col md={2} lg={2} className="d-none d-md-flex">
                <div
                  className={`${styles.delivery} align-items-center d-flex`}
                >
                  {delivery}
                </div>
              </Col>

              <Col
                xs={4}
                md={1}
                lg={1}
                className="d-flex justify-content-end"
              >
                <div
                  className={`${styles.sum} d-flex align-items-center`}
                >
                  {sum}
                </div>
              </Col>

              <Col
                xs={12}
                md={6}
                lg={3}
                className={`${styles.border} d-flex pt-3 pt-md-0 mt-3 mt-md-0 justify-content-around justify-content-md-between align-items-center`}
              >
                <div className={styles['pay-status']}>
                  <ToolTip text={payStatusName ?? ''}>
                    <PayStatusIcon
                      payStatusName={payStatusName}
                      orderId={orderId}
                      invoiceFileInfo={invoiceFileInfo}
                    />
                  </ToolTip>
                </div>

                <div className={styles['delivery-status']}>
                  <ToolTip text={deliveryStatusName ?? 'Доставка'}>
                    {delivery === 'Доставка' ? (
                      <DeliveryStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    ) : (
                      <PickupStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    )}
                  </ToolTip>
                </div>

                <div className={styles['signature-status']}>
                  <ToolTip text="Закрывающие документы">
                    <DocumentStatusIcon
                      orderId={orderId}
                      updFileInfo={updFileInfo}
                    />
                  </ToolTip>
                </div>

                <div className={styles['fn-list']}>
                  <ToolTip text="Список ФН в заказе">
                    <FnListIcon
                      orderId={orderId}
                      deliveryStatusName={deliveryStatusName}
                    />
                  </ToolTip>
                </div>

                <div
                  className={`${styles.btn} ${
                    openFlag ? styles.open : ''
                  }`}
                >
                  <img src={arrow} alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {(openFlag || isNewOrder) && (
          <HistoryOrdersSubItem
            contactName={contactName}
            contactPhone={contactPhone}
            deliveryAddress={deliveryAddress}
            trackNumber={trackNumber}
            storageId={storageId}
            deliveryStatus={delivery}
            orderId={orderId}
            deliveryStatusName={deliveryStatusName}
            payStatusName={payStatusName}
            payRemain={payRemain}
            price={price}
            adressDesc={adressDesc}
            documentStatusName={documentStatusName}
            deliveryPrice={deliveryPrice}
            vendorName={vendorName}
          />
        )}
      </div>
    );
  },
);

HistoryOrdersItem.displayName = 'HistoryOrdersItem';

export default HistoryOrdersItem;
