import { FC, memo } from 'react';

import Authorization from 'components/authorization/authorization';
import { Registration } from 'components/registration/registration';

import { useAuthFormToggle } from 'shared/hooks/use-auth-form-toggle';

import { AuthContainerProps } from './auth-container.model';

const AuthContainer: FC<AuthContainerProps> = memo(
  ({ toggleMenu }) => {
    const { isCurrentAuthFormSingIn, isCurrentAuthFormSingUp } =
      useAuthFormToggle();

    if (isCurrentAuthFormSingIn) {
      return <Authorization toggleMenu={toggleMenu} />;
    }

    if (isCurrentAuthFormSingUp) {
      return <Registration toggleMenu={toggleMenu} />;
    }

    return null;
  },
);

AuthContainer.displayName = 'AuthContainer';

export default AuthContainer;
