import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { tokens } from 'shared/utils/tokens.util';

import authLogoMob from 'images/icons/auth-logo-mob.svg';
import burgerIco from 'images/icons/burger.svg';
import box from 'images/icons/gray-box.svg';

import styles from './auth-menu.module.css';

interface AuthMenuProps {
  logo: string;
  toggleMenu: (d: any) => void;
}

const AuthMenu: FC<AuthMenuProps> = memo(({ logo, toggleMenu }) => {
  const { org } = useSelector((store: any) => store.org);
  const { order } = useSelector((store: any) => store.order);
  const { isShow } = useSelector((store: any) => store.menu);

  const isAuth = tokens.getAccess();

  return (
    <div
      className={
        styles['auth-menu'] + (!isAuth ? ` ${styles.between}` : '')
      }
    >
      <div className={styles.title}>
        {isAuth ? (
          <p>{org ? org.shortName || org.longName : ''}</p>
        ) : (
          <>
            <button
              type="button"
              id="auth-btn"
              className="d-none d-lg-block"
              onClick={toggleMenu}
              data-testid="auth-menu-click-1"
            >
              Авторизация
            </button>
            {isShow ? (
              <button
                type="button"
                id="auth-btn"
                className="d-lg-none d-flex align-items-center"
                onClick={toggleMenu}
                data-testid="auth-menu-click-2"
              >
                <img src={authLogoMob} alt="" />
                <span className={styles['menu-item']}>
                  Авторизация
                </span>
              </button>
            ) : null}
          </>
        )}
      </div>

      {order?.products?.length ? (
        <button
          type="button"
          id="profile-btn"
          onClick={toggleMenu}
          className={styles.icon}
          data-testid="auth-menu-click-3"
        >
          <img className="d-none d-lg-block" src={box} alt="" />
          <img
            className="d-lg-none"
            src={isAuth ? box : burgerIco}
            alt=""
          />
          <span className={styles.counter}>
            {order.products.reduce(
              (sum: any, current: { quantity: any }) =>
                sum + (current.quantity || 1),
              0,
            )}
          </span>
        </button>
      ) : (
        <button
          type="button"
          id="profile-btn"
          onClick={toggleMenu}
          className={styles.icon}
          data-testid="auth-menu-click-4"
        >
          <img className="d-none d-lg-block" src={logo} alt="" />
          <img className="d-lg-none" src={burgerIco} alt="" />
        </button>
      )}
    </div>
  );
});

AuthMenu.displayName = 'AuthMenu';

export default AuthMenu;
