import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

export const useProducts = () => {
  const { id } = useParams();
  const location = useLocation();
  const { products } = useSelector((store) => store.products);

  const isMainPage = useMemo(() => {
    return location.pathname === '/';
  }, [location.pathname]);

  const currentProduct = useMemo(() => {
    if (!products?.length) {
      return null;
    }

    return products.find((item) => item.id === Number(id));
  }, [id, products]);

  return { isMainPage, currentProduct };
};
