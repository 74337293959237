import { FC, memo, useEffect, useState } from 'react';

import { IAddress, IAddressList } from 'shared/models/dadata.model';

import styles from './autocomplete-list.module.css';
import { ListItem } from './listItem';

interface IAutocompleteList {
  show: boolean;
  list: IAddressList[];
  changeCurrentAddressFromSuggest: (item: IAddress) => void;
}

const AutocompleteList: FC<IAutocompleteList> = memo(
  ({ show, list, changeCurrentAddressFromSuggest }) => {
    const useKeyPress = function (targetKey: string) {
      const [keyPressed, setKeyPressed] = useState(false);

      function downHandler({ key }: KeyboardEvent) {
        if (key === targetKey) {
          setKeyPressed(true);
        }
      }

      const upHandler = ({ key }: KeyboardEvent) => {
        if (key === targetKey) {
          setKeyPressed(false);
        }
      };

      useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
          window.removeEventListener('keydown', downHandler);
          window.removeEventListener('keyup', upHandler);
        };
      });

      return keyPressed;
    };

    const downPress = useKeyPress('ArrowDown');
    const upPress = useKeyPress('ArrowUp');
    const enterPress = useKeyPress('Enter');
    const [cursor, setCursor] = useState(0);
    const [hovered, setHovered] = useState(undefined);

    useEffect(() => {
      if (list.length && downPress) {
        setCursor((prevState) => {
          if (prevState < list.length - 1) {
            return prevState + 1;
          }
          return prevState;
        });
      }
    }, [downPress, list.length]);

    useEffect(() => {
      if (list.length && upPress) {
        setCursor((prevState) => {
          if (prevState > 0) {
            return prevState - 1;
          }
          return prevState;
        });
      }
    }, [upPress, list.length]);

    useEffect(() => {
      if (list.length && enterPress) {
        changeCurrentAddressFromSuggest(list[cursor]);
      }
    }, [cursor, enterPress, list, changeCurrentAddressFromSuggest]);

    useEffect(() => {
      if (list.length && hovered) {
        setCursor(list.indexOf(hovered));
      }
    }, [hovered, list]);

    return list.length && show ? (
      <ul className={styles.list}>
        {list.map((item, i) => (
          <ListItem
            key={item.value}
            active={i === cursor}
            item={item}
            setHovered={() => setHovered}
            id={i}
            changeCurrentAddressFromSuggest={
              changeCurrentAddressFromSuggest
            }
          />
        ))}
      </ul>
    ) : null;
  },
);

AutocompleteList.displayName = 'AutocompleteList';

export default AutocompleteList;
