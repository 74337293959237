import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { addAlert } from 'services/actions/page-alerts';

import ImageSlider from 'components/image-slider/image-slider';
import { Indicator } from 'components/indicator/indicator';
import ProductAmount from 'components/product-amount/product-amount';

import { PRODUCTS_STATUSES, USER_TYPES } from 'shared/const';
import { useProducts } from 'shared/hooks/use-products.hook';
import { getHtmlData } from 'shared/utils/readHtml.util';
import { tokens } from 'shared/utils/tokens.util';

import deliveryTruck from 'images/icons/delivery-truck.svg';

import { withKeyHasBlocked, withSetHasBlocked } from '../../utils';
import styles from './products-card.module.css';

const cx = classNames.bind(styles);

const TIMEOUT = 100;

const ProductsCard = memo(
  ({
    product,
    availableStatus,
    indicator,
    counter,
    photo,
    imageStyle,
  }) => {
    const [html, setHtml] = useState('');
    const [detailsPositionTop, setDetailsPositionTop] = useState(0);
    const innerHTMLRef = useRef();

    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { isMainPage } = useProducts();
    const { order } = useSelector((store) => store.order);
    const { userType } = useSelector((store) => store.auth);

    const isAuth = tokens.getAccess();

    const dispatch = useDispatch();

    const blockedCounterFnWithKey = withKeyHasBlocked(order, product);

    const blockedCounterFnSetWithOfd = withSetHasBlocked(
      order,
      product,
    );

    const blockedCounter = useMemo(() => {
      if (isMainPage) return false;
      return blockedCounterFnSetWithOfd || blockedCounterFnWithKey;
    }, [
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      isMainPage,
    ]);

    const unBlockedCounter = useMemo(() => {
      if (isMainPage) return true;
      return !blockedCounterFnSetWithOfd && !blockedCounterFnWithKey;
    }, [
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      isMainPage,
    ]);

    const borderStyle = useMemo(() => {
      if (isMainPage) return false;
      return !!id;
    }, [id, isMainPage]);

    const borderStyleUnavailable = useMemo(() => {
      return !id && blockedCounter;
    }, [id, blockedCounter]);

    const handleClick = useCallback(() => {
      navigate(`/product/${product.id}`);
    }, [navigate, product.id]);

    const changeDetailsPosition = useCallback(() => {
      const MAX_HEIGHT = 100;
      const MAX_PADDING = 25;
      const MIN_PADDING = 10;

      const { height } = innerHTMLRef.current.getBoundingClientRect();

      setDetailsPositionTop(
        height > MAX_HEIGHT
          ? MAX_HEIGHT + MAX_PADDING
          : height + MIN_PADDING,
      );
    }, []);

    useEffect(() => {
      if (!product.specificationFileUrl) return;

      (async () => {
        const htmlCode = await getHtmlData({
          url: product.specificationFileUrl,
          onError: () => {
            dispatch(addAlert('Ошибка получения данных', 'danger'));
          },
        });
        setHtml(htmlCode);
        setTimeout(changeDetailsPosition, TIMEOUT);
      })();
    }, [
      dispatch,
      changeDetailsPosition,
      product.specificationFileUrl,
    ]);

    useEffect(() => {
      setTimeout(changeDetailsPosition, TIMEOUT);
    }, [changeDetailsPosition, order.products.length]);

    return (
      <>
        {availableStatus !== PRODUCTS_STATUSES.NOT_FOR_ORDER && (
          <div
            className={cx('products-card', {
              'products-border-less': borderStyle,
              'products-border-unavailable': borderStyleUnavailable,
            })}
            onClick={handleClick}
            data-testid={`product-card-${product.name}`}
          >
            <div
              className={cx('wrap', {
                unavailable: blockedCounter,
              })}
            >
              <div className={cx('top')}>
                <ImageSlider
                  photo={photo}
                  classNameImg={imageStyle}
                  product={product}
                  isBig={
                    location.pathname === `/product/${product.id}`
                  }
                />

                <div className={cx('desc')}>
                  <div className={cx('title')}>{product.name}</div>

                  <div className={cx('text')}>
                    <div className={cx('server-html')}>
                      {!id && product.id === 7505 && (
                        <div className={cx('hidden-block')} />
                      )}

                      {id && (
                        <div className={cx('hidden-block-in-card')} />
                      )}

                      <div
                        className={cx('inner-html')}
                        ref={innerHTMLRef}
                        dangerouslySetInnerHTML={{ __html: html }}
                      />

                      <div className={cx('gradient')} />
                    </div>

                    {!id && (
                      <p
                        className={cx('pseudo-link')}
                        style={{ top: `${detailsPositionTop}px` }}
                      >
                        Подробнее
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {indicator && (
                <div className="justify-content-end d-flex">
                  <Indicator full remainder={availableStatus} />
                </div>
              )}

              <div
                className={cx('bottom', {
                  'justify-content-end': counter,
                })}
              >
                <div className={cx('product-amount')}>
                  <ProductAmount
                    product={product}
                    price={product.price}
                    hiddenBtn={counter}
                  />
                </div>
              </div>

              {(!isAuth || userType === USER_TYPES.LK) &&
                unBlockedCounter && (
                  <div className={cx('delivery-wrap')}>
                    <img
                      className={cx('delivery-img')}
                      src={deliveryTruck}
                      alt="delivery-truck"
                    />

                    <p className={cx('delivery-desc')}>
                      Доставка включена в стоимость
                    </p>
                  </div>
                )}

              <p
                className={cx('unavailable-text', {
                  hidden: unBlockedCounter,
                })}
              >
                Данный товар не может быть <br /> добавлен в текущий
                заказ
              </p>
            </div>
          </div>
        )}
      </>
    );
  },
);

ProductsCard.displayName = 'ProductsCard';

export default ProductsCard;

ProductsCard.propTypes = {
  product: PropTypes.shape({
    price: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.number,
    availableStatus: PropTypes.number,
    specificationFileUrl: PropTypes.string,
  }).isRequired,
  availableStatus: PropTypes.number.isRequired,
  indicator: PropTypes.bool.isRequired,
  counter: PropTypes.bool.isRequired,
  photo: PropTypes.arrayOf(
    PropTypes.shape({
      fileId: PropTypes.number,
      fileName: PropTypes.string,
      fileTypeId: PropTypes.number,
      fileMimeType: PropTypes.string,
      fileBody: PropTypes.string,
      filePosition: PropTypes.number,
      parentFileId: PropTypes.number,
      childFileId: PropTypes.number,
    }),
  ).isRequired,
  imageStyle: PropTypes.string,
};

ProductsCard.defaultProps = {
  imageStyle: PropTypes.null,
};
