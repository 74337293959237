import PropTypes from 'prop-types';
import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import OrderItem from 'components/order-item/order-item';

import boxIcon from 'images/icons/orange-box.svg';

import { currency } from '../../utils';
import styles from './order-items.module.css';

const OrderItems = memo(({ aside }) => {
  const { order } = useSelector((store) => store.order);

  return (
    <div
      className={`${styles['order-item']} d-flex flex-column ${
        aside ? styles.aside : ''
      }`}
    >
      <div className="pb-3">
        <Row className="align-items-center">
          <Col xs={6}>
            <div className={styles.title}>Заказ</div>
          </Col>
          <Col xs={6}>
            <img
              className={styles['box-icon']}
              src={boxIcon}
              alt=""
            />
          </Col>
        </Row>
      </div>
      <div className="body flex-md-shrink-1 flex-md-grow-1">
        {order?.products.length ? (
          order.products.map((product, i) => (
            <OrderItem
              key={product.id}
              product={product}
              index={i}
              aside={aside}
              price={product.price}
              qty={product.quantity}
              available={product.availableStatus}
            />
          ))
        ) : (
          <p className="pt-3">В корзине нет заказанных товаров</p>
        )}
      </div>
      {!aside && (
        <div
          className={`${styles['items-price']} mt-3 d-none d-md-flex justify-content-end align-items-baseline`}
        >
          <span>Сумма товаров:</span>
          {Boolean(order?.totalPrice) && (
            <span className={`${styles.price} ms-3`}>
              {`${currency(order.totalPrice)} ₽`}
            </span>
          )}
        </div>
      )}
    </div>
  );
});

OrderItems.displayName = 'OrderItems';

export default OrderItems;

OrderItems.propTypes = {
  aside: PropTypes.bool.isRequired,
};
