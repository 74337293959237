// TODO: refact comonent and add order func to store??
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { logOutUser } from 'services/actions/auth';
import { addAlert } from 'services/actions/page-alerts';
import { RootState } from 'services/reducers';

import ContentLoader from 'components/content-loader/content-loader';
import CustomBtn from 'components/custom-btn/custom-btn';
import CustomInput from 'components/custom-input/custom-input';
import Datepicker from 'components/datepicker/datepicker';
import HistoryOrderList from 'components/history-orders-list/history-orders-list';
import OrderSelect from 'components/order-select/order-select';

import { USER_TYPES } from 'shared/const/user.const';
import { OrdersListResponse } from 'shared/models/order.model';
import httpClient from 'shared/utils/httpClient/httpClient';
import { tokens } from 'shared/utils/tokens.util';

import { listOrdersUrl } from '../urls';
import { dateFormatter } from '../utils';
import styles from './orders.module.css';

export type TData = Date | null | undefined;

const deliveryTypeOptions = [
  { value: 0, text: 'Самовывоз' },
  { value: 1, text: 'Доставка' },
];

const Orders = () => {
  const isAuth = tokens.getAccess();
  const [orders, setOrders] = useState<OrdersListResponse>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [dateRange, setDateRange] = useState<TData[]>([]);
  const [orderNum, setOrderNum] = useState('');
  const [deliveryType, setDeliveryType] = useState<string | null>('');

  const [nextPaginationStep, setNextPaginationStep] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const { userType } = useSelector((store: RootState) => store.auth);
  const dispatch = useDispatch();

  const PAGINATION_STEP = 20;

  const paginationUrl = useMemo(() => {
    return `${listOrdersUrl}?rowsCount=${PAGINATION_STEP}&rowsOffset=${nextPaginationStep}`;
  }, [nextPaginationStep]);

  const isPartner = useMemo(
    () => userType === USER_TYPES.PK,
    [userType],
  );

  const getOrders = useCallback(() => {
    httpClient
      .get(paginationUrl)
      .then(({ data }) => {
        if (data.length !== PAGINATION_STEP) {
          setDisabled(true);
        }
        setOrders((prevstate) =>
          nextPaginationStep === 0 ? data : prevstate.concat(data),
        );
      })
      .catch((error) => {
        setDisabled(true);
        if (error?.response?.status === 401) {
          return dispatch(logOutUser());
        }
        dispatch(
          addAlert('Ошибка получения списка заказов', 'danger'),
        );
      })
      .finally(() => setIsLoading(false));
  }, [paginationUrl, dispatch, nextPaginationStep]);

  const filteredOrders = useMemo(() => {
    let filteredArr = [...orders];

    if (orderNum !== '') {
      filteredArr = filteredArr.filter((item) =>
        String(item.orderId).includes(orderNum),
      );
    }

    if (dateRange.length !== 0) {
      filteredArr = filteredArr.filter(
        (item) =>
          dateFormatter(dateRange[0], 'yyyymmdd') <=
            dateFormatter(item.orderDate, 'yyyymmdd') &&
          dateFormatter(dateRange[1], 'yyyymmdd') >=
            dateFormatter(item.orderDate, 'yyyymmdd'),
      );
    }

    if (deliveryType !== null) {
      filteredArr = filteredArr.filter((item) =>
        String(item.deliveryTypeId).includes(deliveryType),
      );
    }

    return filteredArr;
  }, [dateRange, deliveryType, orderNum, orders]);

  const getSearchOrdersResult = useCallback(() => {
    httpClient
      .get(listOrdersUrl)
      .then(({ data }) => {
        setOrders(data);
        setDisabled(true);
      })
      .catch((error) => {
        setDisabled(true);
        if (error?.response?.status === 401) {
          return dispatch(logOutUser());
        }
        dispatch(
          addAlert('Ошибка получения списка заказов', 'danger'),
        );
      })
      .finally(() => setIsLoading(false));
  }, [dispatch]);

  const handleDatepickerChange = (update: TData[]) => {
    setDateRange(update);
  };

  const handleDatepickerReset = () => {
    setDateRange([]);
  };

  const handleDeliveryTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setDeliveryType(e.target.value);
  };

  const handleDeliveryTypeReset = () => {
    setDeliveryType(null);
  };

  const handleButtonLoading = () => {
    setNextPaginationStep((prevstate) => prevstate + PAGINATION_STEP);
  };

  useEffect(() => {
    if (isAuth && userType !== null) {
      getOrders();
    }
  }, [getOrders, isAuth, paginationUrl, userType]);

  useEffect(() => {
    if (
      (filteredOrders.length === 0 && !!orderNum.length) ||
      (filteredOrders.length === 0 && !!dateRange.length) ||
      (filteredOrders.length === 0 && deliveryType)
    ) {
      getSearchOrdersResult();
    }
  }, [
    filteredOrders.length,
    getSearchOrdersResult,
    deliveryType,
    dateRange.length,
    orderNum.length,
    dispatch,
  ]);

  return isAuth ? (
    <div className={styles.orders}>
      <div className={styles.head}>
        <h3 className={styles.title}>История заказов</h3>
        <form action="" className={styles.form}>
          <div className={styles['form-desc']}>
            <Row className="align-items-center">
              <Col md={4} xs={12} className="flex-fill">
                <CustomInput
                  testId="orders-input-1"
                  onChange={(e) => setOrderNum(e.target.value)}
                  label="Номер заказа"
                  type="text"
                  theme="edo"
                  placeholder="Введите номер"
                />
              </Col>
              <Col md={4} xs={12} className="flex-fill mt-3 mt-md-0">
                <Datepicker
                  dateRange={dateRange}
                  func={handleDatepickerChange}
                  onDatepickerReset={handleDatepickerReset}
                />
              </Col>

              {isPartner && (
                <Col md={4} xs={12} className="flex-fill">
                  <div className={styles['select-wrapper']}>
                    <OrderSelect
                      testId="orders-select-1"
                      onChange={handleDeliveryTypeChange}
                      placeholder="Способ получения"
                      options={deliveryTypeOptions}
                      onReset={handleDeliveryTypeReset}
                      disabled={!deliveryTypeOptions.length}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </form>
      </div>
      <div className={styles.main}>
        <div className={styles.container}>
          <div className="list">
            <Row className={`${styles['col-name']} d-none d-lg-flex`}>
              <Col xs={1}>
                <div className="num">№ заказа</div>
              </Col>
              <Col xs={2}>
                <div className={styles.date}>Дата заказа</div>
              </Col>
              <Col xs={3}>
                <div className={styles.author}>Автор</div>
              </Col>
              <Col xs={2}>
                <div className={styles.delivery}>Тип доставки</div>
              </Col>
              <Col xs={4}>
                <div className={styles.sum}>Сумма заказа</div>
              </Col>
            </Row>
            {isLoading ? (
              <ContentLoader placeholderType="history-order" />
            ) : (
              orders !== null &&
              tokens.getAccess() && (
                <HistoryOrderList filteredOrders={filteredOrders} />
              )
            )}
            {!disabled && tokens.getAccess() && !isLoading && (
              <div className={styles['btn-wrapper']}>
                <CustomBtn
                  theme="edo"
                  type="button"
                  text="Загрузить ещё"
                  disabled={disabled}
                  onClick={handleButtonLoading}
                  testId="orders-btn-1"
                  loading={false}
                  isLink={false}
                  customCls={null}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles['no-auth']}>
      Для просмотра истории заказов пройдите авторизацию
    </div>
  );
};

Orders.displayName = 'Orders';

export default Orders;
