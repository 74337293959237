import {
  GET_DOCS,
  GET_DOCS_FAILED,
  GET_DOCS_SUCCESS,
  SET_DOCS_TYPES,
} from 'services/actions/actionTypes';

const initialState = {
  docs: null,
  docsRequest: false,
  docsFailed: false,
  docTypes: [],
};

const docsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCS: {
      return {
        ...state,
        docsRequest: true,
        docsFailed: false,
      };
    }

    case GET_DOCS_SUCCESS: {
      return {
        ...state,
        docs: action.docs,
        docsRequest: false,
      };
    }

    case GET_DOCS_FAILED: {
      return {
        ...state,
        docsFailed: true,
        docsRequest: false,
      };
    }

    case SET_DOCS_TYPES: {
      return {
        ...state,
        docTypes: action.docTypes,
      };
    }

    default: {
      return state;
    }
  }
};

export default docsReducer;
