import classNames from 'classnames/bind';
import { FC, memo, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import showLoader from 'services/actions/loader';
import { addAlert } from 'services/actions/page-alerts';

import useDownloadFile from 'shared/hooks/use-download-file';
import httpClient from 'shared/utils/httpClient/httpClient';

import docIcon from 'images/icons/doc.svg';
import downloadIconDoc from 'images/icons/download-doc.svg';
import downloadIcon from 'images/icons/download.svg';

import styles from './download-link.module.css';

const cx = classNames.bind(styles);

interface DownloadLinkProps {
  textLink: string;
  link: string;
  payload: { responseType: string };
  linkStyle: string;
  fileName?: string;
  documentType: string;
  iconSrc?: string;
  showedDownloadIcon?: boolean | null;
  isDisabled?: boolean;
}

const DownloadLink: FC<DownloadLinkProps> = memo(
  ({
    textLink,
    link,
    payload,
    linkStyle,
    fileName,
    documentType,
    iconSrc,
    showedDownloadIcon,
    isDisabled,
  }) => {
    const ref = useRef(null);
    const dispatch = useDispatch();

    const preDownloading = useCallback(
      () => dispatch(showLoader(true)),
      [dispatch],
    );

    const postDownloading = useCallback(
      () => dispatch(showLoader(false)),
      [dispatch],
    );

    const onErrorDownloadFile = useCallback(() => {
      window.scrollTo(0, 0);
      dispatch(addAlert('Документ не найден', 'danger'));
      postDownloading();
    }, [dispatch, postDownloading]);

    const downloadLink = useCallback(
      () =>
        httpClient.get(link, {
          ...payload,
          responseType: 'arraybuffer',
        }),
      [link, payload],
    );

    const { url, download, name } = useDownloadFile({
      refEl: ref,
      apiDefinition: downloadLink,
      preDownloading,
      postDownloading,
      onError: onErrorDownloadFile,
      getFileName: fileName,
      documentType,
    });

    return (
      <div
        className={cx({
          wrapper: linkStyle === 'long',
          disabled: isDisabled,
        })}
      >
        {linkStyle === 'long' && (
          <img
            src={docIcon}
            alt="Иконка документа"
            className={cx('doc-img')}
          />
        )}

        <a
          href={url}
          download={name}
          style={{ display: 'none' }}
          target="_blank"
          ref={ref}
          rel="noreferrer"
        >
          Ссылка на скачивание
        </a>

        <button
          type="button"
          data-testid="his-order-subitem-link-6"
          onClick={download}
          disabled={isDisabled}
          className={cx({
            'download-link-long': linkStyle === 'long',
            'download-link-short': linkStyle === 'short',
          })}
        >
          {linkStyle === 'icon' && (
            <>
              {!isDisabled && (
                <img
                  src={downloadIconDoc}
                  alt="Иконка скачивания файла"
                  className={cx('icon', {
                    hidden: !showedDownloadIcon && !isDisabled,
                  })}
                />
              )}

              <img
                src={iconSrc}
                alt="Иконка скачивания файла"
                className={cx('icon', {
                  hidden: showedDownloadIcon && !isDisabled,
                })}
              />
            </>
          )}

          {linkStyle !== 'icon' && (
            <>
              {textLink}
              <img src={downloadIcon} alt="Иконка скачивания файла" />
            </>
          )}
        </button>
      </div>
    );
  },
);

DownloadLink.displayName = 'DownloadLink';

export default DownloadLink;
