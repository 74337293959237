import image from 'images/500.svg';

import styles from './server-error-500.module.css';

const ServerError500 = () => (
  <div className={styles['server-error']}>
    <img className={`${styles.image} mt-5`} src={image} alt="" />
    <div className={styles.header}>Ошибка сервера</div>
    <p className={`${styles.text} mt-3 mb-5`}>
      Подождите, скоро данная ошибка будет исправлена.
    </p>
  </div>
);

export default ServerError500;
