import { FC, memo } from 'react';

import { ProductResponse } from 'shared/models/products.model';

import honestSign from 'images/product-page/honest-sign.svg';

import styles from './products-card.module.css';

const EXCEPTIONS = [7505, 7506, 7355, 7356, 7273, 7274];

interface IHonestSignProps {
  product: ProductResponse;
}

export const HonestSign: FC<IHonestSignProps> = memo(
  ({ product }) => {
    if (!product || EXCEPTIONS.includes(product.id)) {
      return null;
    }

    return (
      <img
        src={honestSign}
        className={styles['honest-sign']}
        alt="honest-sign"
      />
    );
  },
);

HonestSign.displayName = 'HonestSign';
