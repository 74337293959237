import { SHOW_MENU, SHOW_ON_MOBILE } from './actionTypes';

interface ShowMenuOnMobileAction {
  type: typeof SHOW_ON_MOBILE;
  isShow: boolean;
}

interface ShowMenuAction {
  type: typeof SHOW_MENU;
  isShow: boolean;
}

export type MenuActionTypes = ShowMenuAction | ShowMenuOnMobileAction;

export function showMenuOnMobile(
  isShow: boolean,
): ShowMenuOnMobileAction {
  return { type: SHOW_ON_MOBILE, isShow };
}

export default function showMenu(isShow: boolean): ShowMenuAction {
  return { type: SHOW_MENU, isShow };
}
