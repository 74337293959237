import { CHANGE_ORDER } from 'services/actions/actionTypes';

import { IExtendedOrder } from 'shared/models/order.model';

const initialState = {
  order: null,
};

type TOrderAction = {
  type: typeof CHANGE_ORDER;
  order: IExtendedOrder;
};

const orderReducer = (state = initialState, action: TOrderAction) => {
  switch (action.type) {
    case CHANGE_ORDER: {
      return {
        ...state,
        order: action.order,
      };
    }

    default: {
      return state;
    }
  }
};

export default orderReducer;
