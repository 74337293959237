import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import { AUTH_PROVIDERS } from 'shared/const';
import {
  MIN_VALIDITY_SECONDS,
  keycloak,
} from 'shared/const/keycloak.const';
import { IHttpClientError } from 'shared/models/simple-response.model';
import { IUserCredentials } from 'shared/models/user.model';
import { tokens } from 'shared/utils/tokens.util';

import * as urls from '../../../urls';
import { authentication } from '../user.util';

declare module 'axios' {
  export interface AxiosInstance {
    getTokens: typeof getTokensFromHttp;
  }
}

interface IOriginalRequest extends AxiosRequestConfig {
  retry?: boolean;
}

const httpClientError: IHttpClientError = {
  ...new Error('Не удалось обновить токен'),
  response: { status: null },
};

const createError = () => {
  tokens.removeAll();
  authentication.clear();
  httpClientError.response.status = 401;
  throw httpClientError;
};

const openLoginForm = () => {
  tokens.removeAll();
  authentication.clear();
  window.location.href = `/${urls.LOGIN_FORM_URL_HASH}`;
};

const getTokensFromHttp = async (data: IUserCredentials) => {
  try {
    if (!data) {
      throw new Error('Не переданы данные о пользователе');
    }

    const { username, password } = data;

    if (!username || !password) {
      throw new Error('Не переданы данные о пользователе');
    }

    const params = new URLSearchParams();
    params.append('login', username);
    params.append('password', password);

    const response = await axios.post(urls.loginUrl, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    tokens.setAccess(response.data.access_token);
    tokens.setRefresh(response.data.refresh_token);
    authentication.setType(AUTH_PROVIDERS.http);

    return {
      access_token: response.data.access_token,
      refresh_token: response.data.refresh_token,
    };
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.status === 500) {
        throw new Error('Ошибка сервера');
      }
    }

    throw error;
  }
};

const refreshTokenFromHttp = async (
  originalRequest: AxiosRequestConfig,
) => {
  try {
    const currentAccessToken = tokens.getAccess();
    const currentRefreshToken = tokens.getRefresh();

    if (!currentRefreshToken || !currentAccessToken) {
      return openLoginForm();
    }

    const params = new URLSearchParams();
    params.append('access_token', currentAccessToken);
    params.append('refresh_token', currentRefreshToken);

    const response = await axios.post(urls.updateToken, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (
      !response.data?.access_token ||
      !response.data?.refresh_token
    ) {
      return openLoginForm();
    }

    tokens.setAccess(response.data.access_token);
    tokens.setRefresh(response.data.refresh_token);
    authentication.setType(AUTH_PROVIDERS.http);
    axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
    return httpClient.request(originalRequest);
  } catch (error) {
    return openLoginForm();
  }
};

const refreshTokenFromKeycloak = async (
  originalRequest: AxiosRequestConfig,
) => {
  try {
    const isTokenRefreshed = await keycloak.updateToken(
      MIN_VALIDITY_SECONDS,
    );
    if (!isTokenRefreshed) return createError();

    keycloak.token && tokens.setAccess(keycloak.token);
    keycloak.refreshToken && tokens.setRefresh(keycloak.refreshToken);
    authentication.setType(AUTH_PROVIDERS.keycloak);
    axios.defaults.headers.common.Authorization = `Bearer ${keycloak.token}`;
    return httpClient.request(originalRequest);
  } catch (err) {
    createError();
  }
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

const onError = async (error: AxiosError) => {
  if (!error.response || error.response.status !== 401) {
    return Promise.reject(error);
  }

  const originalRequest = (error.config as IOriginalRequest) || {};

  if (!!originalRequest?.retry) {
    return openLoginForm();
  }

  originalRequest.retry = true;

  if (authentication.getType() === AUTH_PROVIDERS.http) {
    return refreshTokenFromHttp(originalRequest);
  }

  if (authentication.getType() === AUTH_PROVIDERS.keycloak) {
    return refreshTokenFromKeycloak(originalRequest);
  }

  return createError();
};

const httpClient = axios.create();

httpClient.interceptors.request.use((request) => {
  const accessToken = tokens.getAccess();
  request.headers.Authorization = accessToken
    ? `Bearer ${accessToken}`
    : '';
  return request;
});

httpClient.interceptors.response.use(onResponse, onError);

httpClient.getTokens = getTokensFromHttp;

export default httpClient;
