import {
  IS_EDO_FLOW,
  IS_EDO_FLOW_FAILED,
  IS_EDO_FLOW_SUCCESS,
} from 'services/actions/actionTypes';

const initialState = {
  isEdoFlow: false,
  isEdoFlowRequest: false,
  isEdoFlowFailed: false,
};

type TDocFlowAction =
  | { type: typeof IS_EDO_FLOW }
  | { type: typeof IS_EDO_FLOW_SUCCESS; isEdoFlow: boolean }
  | { type: typeof IS_EDO_FLOW_FAILED };

const docFlowReducer = (
  state = initialState,
  action: TDocFlowAction,
) => {
  switch (action.type) {
    case IS_EDO_FLOW: {
      return {
        ...state,
        isEdoFlowRequest: true,
        isEdoFlowFailed: false,
      };
    }
    case IS_EDO_FLOW_SUCCESS: {
      return {
        ...state,
        isEdoFlow: action.isEdoFlow,
        isEdoFlowRequest: false,
      };
    }
    case IS_EDO_FLOW_FAILED: {
      return {
        ...state,
        isEdoFlowFailed: true,
        isEdoFlowRequest: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default docFlowReducer;
