import { FC } from 'react';

import { DownloadDoc } from 'components/download-doc/download-doc';

import { FileProduct } from 'shared/models/products.model';

interface ITabProps {
  tabName: string;
  documents: FileProduct[];
  description: string;
  characteristic: string;
}

export const Tab: FC<ITabProps> = ({
  tabName,
  documents,
  description,
  characteristic,
}) => {
  switch (tabName) {
    case '1':
      return (
        <div
          dangerouslySetInnerHTML={{ __html: description || '' }}
        />
      );

    case '2':
      return (
        <div
          dangerouslySetInnerHTML={{ __html: characteristic || '' }}
        />
      );

    case '3':
      return (
        <div>
          {documents.length
            ? documents.map((document) => (
                <div key={document.fileName}>
                  <DownloadDoc document={document} />
                </div>
              ))
            : 'Нет документов'}
        </div>
      );
    default:
      return <div />;
  }
};
