import { ALERT_LIFETIME } from 'shared/const';

import {
  ADD_ALERT,
  DELL_ALERT,
  DELL_ALERT_BY_TEXT,
} from './actionTypes';

export function addAlert(
  data: string,
  variant: string,
  lifetime = ALERT_LIFETIME,
) {
  return {
    type: ADD_ALERT,
    data,
    variant,
    lifetime,
  };
}

export function delAlert(index: number) {
  return {
    type: DELL_ALERT,
    index,
  };
}

export function delAlertByText(text: string) {
  return {
    type: DELL_ALERT_BY_TEXT,
    text,
  };
}
