import { FC, memo } from 'react';

import enoughIcon from 'images/icons/enough.svg';
import fewIcon from 'images/icons/few.svg';
import muchIcon from 'images/icons/much.svg';
import unavailableIcon from 'images/icons/unavailable.svg';

interface IIndicatorImageProps {
  remainder: number;
}

export const IndicatorImage: FC<IIndicatorImageProps> = memo(
  ({ remainder }) => {
    switch (remainder.toString()) {
      case '1':
        return (
          <img
            src={muchIcon}
            alt="Шкала с обозначением количества товара"
          />
        );
      case '2':
        return (
          <img
            src={enoughIcon}
            alt="Шкала с обозначением количества товара"
          />
        );
      case '3':
        return (
          <img
            src={fewIcon}
            alt="Шкала с обозначением количества товара"
          />
        );

      default:
        return (
          <img
            src={unavailableIcon}
            alt="Шкала с обозначением количества товара"
          />
        );
    }
  },
);

IndicatorImage.displayName = 'IndicatorImage';
