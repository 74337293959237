import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDeliveryData } from 'services/actions/delivery';

import { USER_TYPES } from 'shared/const';

import styles from './switch-tabs.module.css';

const SwitchTabs = memo(() => {
  const dispatch = useDispatch();
  const { deliveryData } = useSelector((store) => store.deliveryData);
  const { userType } = useSelector((store) => store.auth);

  const isPartner = useMemo(
    () => userType === USER_TYPES.PK,
    [userType],
  );

  const switchFlag = useCallback(
    (e) => {
      deliveryData.deliveryType = e.target.value;

      if (deliveryData.deliveryType === 'Самовывоз') {
        deliveryData.tariff = null;
        deliveryData.deliveryPrice = 0;
      }

      dispatch(setDeliveryData(deliveryData));
    },
    [deliveryData, dispatch],
  );

  return (
    <div className="wrap">
      <p className={`${styles.title} mb-0`}>Способ получения</p>

      <div className={`${styles['switch-tabs']} d-flex mt-2`}>
        <div className={styles['switch-tab']}>
          <input
            checked={deliveryData.deliveryType === 'Доставка'}
            onChange={switchFlag}
            className={`${styles.input} d-none`}
            name="choice"
            type="radio"
            value="Доставка"
            id="delivery"
          />

          <label
            data-testid="switch-tabs-input-2"
            className={styles.label}
            htmlFor="delivery"
          >
            доставка
          </label>
        </div>

        {isPartner && (
          <div className={styles['switch-tab']}>
            <input
              checked={deliveryData.deliveryType === 'Самовывоз'}
              onChange={switchFlag}
              className={`${styles.input} d-none`}
              name="choice"
              type="radio"
              value="Самовывоз"
              id="pickup"
            />

            <label
              data-testid="switch-tabs-input-1"
              className={styles.label}
              htmlFor="pickup"
            >
              самовывоз
            </label>
          </div>
        )}
      </div>
    </div>
  );
});

SwitchTabs.displayName = 'SwitchTabs';

export default SwitchTabs;
