export const CONTENT = {
  order: {
    notAuthWarning:
      "Для оформления заказа необходимо войти в учетную запись. Используйте учетные данные ЛК 'Платформа ОФД'",
  },

  auth: {
    registration: {
      success: 'Регистрация прошла успешно.',

      duplicate:
        'Данный пользователь (организация) уже зарегистрирован в системе. Войдите в кабинет, используя логин и пароль от Личного кабинета "Торговый Дом Платформа", или авторизуйтесь с помощью "Платформа ОФД"',
    },

    login: {
      badCredentials: 'Неверные логин или пароль',
    },
  },

  http: {
    invalid: 'Неверный запрос',

    blocked: 'Ресурс заблокирован',

    notFound: 'Ресурс не найден',

    serverError: 'Ошибка сервера',
  },
};
