import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setDeliveryData } from 'services/actions/delivery';
import { addAlert } from 'services/actions/page-alerts';

import ProductCounter from 'components/product-counter/product-counter';

import { PRODUCTS_STATUSES } from 'shared/const';
import { useOrder } from 'shared/hooks/use-order.hook';
import { useProducts } from 'shared/hooks/use-products.hook';

import {
  currencyInt,
  withKeyHasBlocked,
  withSetHasBlocked,
} from '../../utils';
import styles from './product-amount.module.css';

const cx = classNames.bind(styles);

const ProductAmount = memo(({ price, product }) => {
  const { order } = useSelector((store) => store.order);
  const { deliveryData } = useSelector((store) => store.deliveryData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMainPage } = useProducts();

  const orderController = useOrder(product);

  const blockedCounterFnWithKey = withKeyHasBlocked(order, product);
  const blockedCounterFnSetWithOfd = withSetHasBlocked(
    order,
    product,
  );

  const isAvailableForOrder = useMemo(() => {
    return (
      product.availableStatus !== PRODUCTS_STATUSES.NOT_FOR_ORDER
    );
  }, [product.availableStatus]);

  const isBlockedCounter = useMemo(() => {
    if (isMainPage) return false;
    return blockedCounterFnSetWithOfd || blockedCounterFnWithKey;
  }, [
    blockedCounterFnSetWithOfd,
    blockedCounterFnWithKey,
    isMainPage,
  ]);

  const increaseCount = useCallback(() => {
    orderController.updateQuantity(1);
    deliveryData.start = true;
    dispatch(setDeliveryData(deliveryData));
  }, [orderController, deliveryData, dispatch]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();

      if (blockedCounterFnSetWithOfd || blockedCounterFnWithKey) {
        dispatch(
          addAlert(
            `Выбранный товар не может быть добавлен в текущий заказ. Разместите ${product.name} в отдельный заказ.`,
            'warning',
          ),
        );
      } else {
        increaseCount();
      }
      isMainPage && navigate('/products');
      return;
    },
    [
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      isMainPage,
      navigate,
      dispatch,
      product.name,
      increaseCount,
    ],
  );

  return (
    <div
      className={cx('product-amount', {
        hidden: isBlockedCounter,
        mt: !blockedCounterFnSetWithOfd && !blockedCounterFnWithKey,
      })}
    >
      {isAvailableForOrder && !isBlockedCounter && (
        <>
          <div className={styles.price}>
            {currencyInt(price)} ₽
            <span className={styles.quantity}>/ шт.</span>
          </div>

          {(orderController.currentProductQuantity === 0 ||
            isMainPage) && (
            <button
              className={styles['buy-btn']}
              onClick={handleClick}
              data-testid="product-amount-click-1"
              aria-hidden="true"
            >
              В корзину
            </button>
          )}

          {orderController.currentProductQuantity !== 0 &&
            !isMainPage && (
              <div
                className={styles['product-counter']}
                onClick={(e) => e.stopPropagation()}
              >
                <ProductCounter id={product.id} product={product} />
              </div>
            )}
        </>
      )}
    </div>
  );
});

ProductAmount.displayName = 'ProductAmount';

export default ProductAmount;

ProductAmount.propTypes = {
  product: PropTypes.shape({
    price: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.number,
    quantity: PropTypes.number,
  }).isRequired,
  price: PropTypes.number.isRequired,
  hiddenBtn: PropTypes.bool.isRequired,
};
