import {
  IS_OFFER_ACCEPTED,
  IS_OFFER_ACCEPTED_FAILED,
  IS_OFFER_ACCEPTED_SUCCESS,
} from 'services/actions/actionTypes';

const initialState = {
  isOfferAccepted: false,
  isOfferAcceptedRequest: false,
  isOfferAcceptedFailed: false,
};

const isOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_OFFER_ACCEPTED: {
      return {
        ...state,
        isOfferAcceptedRequest: true,
        isOfferAcceptedFailed: false,
      };
    }
    case IS_OFFER_ACCEPTED_SUCCESS: {
      return {
        ...state,
        isOfferAccepted: action.isOfferAccepted,
        isOfferAcceptedRequest: false,
      };
    }
    case IS_OFFER_ACCEPTED_FAILED: {
      return {
        ...state,
        isOfferAcceptedFailed: true,
        isOfferAcceptedRequest: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default isOfferReducer;
