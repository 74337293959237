import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';

import showMenu, {
  showMenuOnMobile,
} from 'services/actions/menu-state';
import { RootState } from 'services/reducers';

import AuthContainer from 'components/auth-container/auth-container';
import AuthMenu from 'components/auth-menu/auth-menu';
import ProfileMenu from 'components/profile-menu/profile-menu';

import { useAuthFormToggle } from 'shared/hooks/use-auth-form-toggle';
import { tokens } from 'shared/utils/tokens.util';

import authIcon from 'images/icons/auth-logo.svg';
import userIcon from 'images/icons/user.svg';
import logoIcon from 'images/logo.svg';

import { LOGIN_FORM_URL_HASH } from '../../urls';
import styles from './app-header.module.css';

interface AppHeaderProps {
  homePage: boolean;
  func: (path: string) => void;
}

const AppHeader: FC<AppHeaderProps> = memo(({ homePage, func }) => {
  const isAuth = tokens.getAccess();

  const ref = useRef<HTMLDivElement>(null);

  const { isShow, isShowOnMobile } = useSelector(
    (store: RootState) => store.menu,
  );
  const dispatch = useDispatch();

  const [isAuthMenu, setIsAuthMenu] = useState(false);
  const [isProfileMenu, setIsProfileMenu] = useState(false);

  const loc = useLocation();
  const authForm = useAuthFormToggle();

  useEffect(() => {
    func(loc.pathname);
  });

  useEffect(() => {
    dispatch(showMenu(loc.hash === LOGIN_FORM_URL_HASH));
  }, [dispatch, loc.hash]);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLDivElement;
      const isSelectDropdown =
        target?.classList?.contains(
          'ant-select-item-option-content',
        ) ||
        (
          target.parentNode?.parentNode?.parentNode as HTMLElement
        )?.classList.contains('ant-select-clear');

      const isInsideMenu =
        ref.current && ref.current.contains(target);

      if (!isInsideMenu && !isSelectDropdown) {
        dispatch(showMenu(false));
        dispatch(showMenuOnMobile(false));
        setIsProfileMenu(false);
        setIsAuthMenu(false);
        authForm.showSignIn();
      }
    },
    [authForm, dispatch],
  );

  const handleMenuToggle = useCallback(
    (e: React.MouseEvent) => {
      dispatch(showMenu(true));

      if (e.currentTarget.id === 'auth-btn' && !isAuthMenu) {
        setIsAuthMenu(true);
        setIsProfileMenu(false);
        return;
      }

      if (e.currentTarget.id === 'profile-btn' && !isProfileMenu) {
        setIsProfileMenu(true);
        setIsAuthMenu(false);
        return;
      }

      dispatch(showMenu(false));
      dispatch(showMenuOnMobile(false));
      setIsProfileMenu(false);
      setIsAuthMenu(false);
      authForm.showSignIn();
    },
    [authForm, dispatch, isAuthMenu, isProfileMenu],
  );

  useEffect(
    () => {
      document.addEventListener('mousedown', (e) =>
        handleClickOutside(e),
      );
      return () => {
        document.removeEventListener('mousedown', (e) =>
          handleClickOutside(e),
        );
      };
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <header
      className={`${styles['app-header']} ${
        !homePage ? styles.back : ''
      }`}
    >
      <Navbar expand="lg">
        <Container fluid="md" className="wrapper">
          <Link
            className="navbar-brand"
            to="/"
            data-testid="header-link-1"
          >
            <img src={logoIcon} alt="Торговый дом Платформа" />
          </Link>

          <Nav
            className="m-auto my-2 my-lg-0 d-none d-lg-block"
            style={{ maxHeight: '160px' }}
            navbarScroll
          >
            <NavLink
              data-testid="header-nav-1"
              className={(nav) =>
                `${styles['nav-link']}${
                  nav.isActive ? ` ${styles.active}` : ''
                }`
              }
              end
              to="/"
            >
              Главная
            </NavLink>

            <NavLink
              data-testid="header-nav-2"
              className={(nav) =>
                `${styles['nav-link']}${
                  nav.isActive ? ` ${styles.active}` : ''
                }`
              }
              to="/products"
            >
              Товары
            </NavLink>

            <NavLink
              data-testid="header-nav-3"
              className={(nav) =>
                `${styles['nav-link']}${
                  nav.isActive ? ` ${styles.active}` : ''
                }`
              }
              to="/orders"
            >
              История заказов
            </NavLink>

            <NavLink
              data-testid="header-nav-3"
              className={(nav) =>
                `${styles['nav-link']}${
                  nav.isActive ? ` ${styles.active}` : ''
                }`
              }
              to="/information"
            >
              Покупателям
            </NavLink>
          </Nav>

          <div className={styles['profile-block']} ref={ref}>
            {isAuth && (
              <>
                <AuthMenu
                  logo={userIcon}
                  toggleMenu={handleMenuToggle}
                />

                {isShow && (
                  <div>
                    <ProfileMenu toggleMenu={handleMenuToggle} />
                  </div>
                )}
              </>
            )}

            {!isAuth && (
              <>
                <AuthMenu
                  logo={authIcon}
                  toggleMenu={handleMenuToggle}
                />

                {isShow && (
                  <>
                    <div className="d-none d-lg-block">
                      <AuthContainer toggleMenu={handleMenuToggle} />
                    </div>

                    <div className="d-lg-none">
                      {isShowOnMobile ||
                      (isAuthMenu && !isProfileMenu) ? (
                        <AuthContainer
                          toggleMenu={handleMenuToggle}
                        />
                      ) : (
                        <ProfileMenu toggleMenu={handleMenuToggle} />
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </Container>
      </Navbar>
    </header>
  );
});

AppHeader.displayName = 'AppHeader';

export default AppHeader;
