import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/httpClient/httpClient';

import { checkDocUrl } from '../../urls';
import {
  IS_OFFER_ACCEPTED,
  IS_OFFER_ACCEPTED_FAILED,
  IS_OFFER_ACCEPTED_SUCCESS,
} from './actionTypes';
import { logOutUser } from './auth';
import { AppDispatch } from './check-doc-flow';
import { addAlert } from './page-alerts';

const checkOffer = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: IS_OFFER_ACCEPTED,
  });

  try {
    const { data } = await httpClient.get(
      `${checkDocUrl}?docType=400`,
    );
    dispatch({
      type: IS_OFFER_ACCEPTED_SUCCESS,
      isOfferAccepted: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: IS_OFFER_ACCEPTED_FAILED,
    });

    const err = error as IError;
    if (err?.response?.status === 401) {
      return dispatch(logOutUser());
    }
    dispatch(addAlert('Ошибка проверки оферты', 'danger'));
    return false;
  }
};

export default checkOffer;
