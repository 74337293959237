import jwtDecode from 'jwt-decode';

import { TAuthProviders } from 'shared/models/authProviders.model';
import { tokens } from 'shared/utils/tokens.util';

export const getUserType = () => {
  const token = tokens.getAccess();
  if (!token) return null;

  const decoded = jwtDecode(token) as { groups: string[] };
  if (!decoded) return null;

  const firstGroup = decoded.groups[0];
  if (!firstGroup) return null;

  return firstGroup.match(/\w+/)?.[0] || null;
};

export const authentication = {
  getType() {
    return localStorage.getItem('authenticationType');
  },

  setType(newType: TAuthProviders) {
    localStorage.setItem('authenticationType', newType);
  },

  clear() {
    localStorage.removeItem('authenticationType');
  },
};
