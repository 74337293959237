import { Dispatch } from 'redux';

import { FileProduct } from 'shared/models/products.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/httpClient/httpClient';
import { getHtmlData } from 'shared/utils/readHtml.util';

import { getfileUrl } from '../../urls';
import {
  GET_CHAR_SUCCESS,
  GET_DESC_SUCCESS,
  GET_DOC_SUCCESS,
  GET_INFO,
} from './actionTypes';
import { logOutUser } from './auth';
import { AppDispatch } from './check-doc-flow';
import showLoader from './loader';
import { addAlert } from './page-alerts';

interface IDocument {
  [key: string]: string;
}

async function getProductInformation(
  product: FileProduct,
  typeDispatch: string,
  dispatch: Dispatch,
  id: number,
) {
  const obj: IDocument = {};

  try {
    const htmlCode = await getHtmlData<string>({
      url: product.fileUrl,
      onError: () => {
        dispatch(addAlert('Ошибка получения данных', 'danger'));
      },
    });

    obj[`${id}`] = htmlCode ?? '';
    dispatch({ type: typeDispatch, obj });
  } catch (e) {
    dispatch(addAlert('Ошибка получения данных', 'danger'));
  }
}

async function getProductInfoFileId(
  item: FileProduct,
  dispatch: AppDispatch,
  id: number,
) {
  const document: Record<string, FileProduct> = {};

  try {
    if (item.fileTypeId === 8) {
      document[`${id}`] = item;
      dispatch({ type: GET_DOC_SUCCESS, document });
    }

    if (item.fileTypeId === 2) {
      await getProductInformation(
        item,
        GET_DESC_SUCCESS,
        dispatch,
        id,
      );
    }

    if (item.fileTypeId === 3) {
      await getProductInformation(
        item,
        GET_CHAR_SUCCESS,
        dispatch,
        id,
      );
    }
  } catch (error) {
    const err = error as IError;

    if (err?.response?.status === 401) {
      return dispatch(logOutUser());
    }

    dispatch(addAlert('Ошибка получения файлов продукта', 'danger'));
  }
}

const getProductInfo =
  (id: number, typeID: number) => async (dispatch: Dispatch) => {
    dispatch({ type: GET_INFO });
    dispatch(showLoader(true));

    try {
      const URL = `${getfileUrl}/id/${id}/file/type/id/${typeID}`;
      const { data } = await httpClient.get(URL);

      // eslint-disable-next-line no-restricted-syntax
      for (const item of data) {
        // eslint-disable-next-line no-await-in-loop
        await getProductInfoFileId(item, dispatch, id);
      }
    } catch (error) {
      dispatch(
        addAlert('Ошибка получения файлов продукта по id', 'danger'),
      );
    } finally {
      dispatch(showLoader(false));
    }
  };

export default getProductInfo;
