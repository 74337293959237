import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { memo } from 'react';

import TableRow from 'components/table-row/table-row';

import styles from './table.module.css';

const cx = classNames.bind(styles);

const Table = memo(({ tableCategory, tableHeader, tableContent }) => (
  <table>
    <thead>
      <tr>
        {tableCategory.length > 1 && <td>&nbsp;</td>}
        {tableCategory.map(
          (category) =>
            tableCategory.length > 1 && (
              <td
                key={category}
                colSpan="2"
                align="center"
                className={cx('category', {
                  'category-separator': tableCategory.length > 1,
                })}
              >
                {category}
              </td>
            ),
        )}
      </tr>
      <tr>
        <td>&nbsp;</td>
        {tableCategory.map(() =>
          tableHeader.map((header) => (
            <td
              key={header.text}
              className={cx('title-value', {
                separator:
                  tableCategory.length > 1 &&
                  tableContent.some(
                    (item) => item.value.length === 4,
                  ),
              })}
              align="center"
            >
              {header.image}
              {header.text}
            </td>
          )),
        )}
      </tr>
    </thead>
    <tbody>
      <TableRow tableContent={tableContent} />
    </tbody>
  </table>
));

Table.displayName = 'Table';

export default Table;

Table.propTypes = {
  tableCategory: PropTypes.arrayOf(PropTypes.string),
  tableHeader: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.element,
      text: PropTypes.string,
    }),
  ).isRequired,
  tableContent: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.arrayOf(PropTypes.element),
    }),
  ).isRequired,
};

Table.defaultProps = {
  tableCategory: PropTypes.null,
};
