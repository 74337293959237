import { AxiosError } from 'axios';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { regNewUser } from 'urls';

import showLoader from 'services/actions/loader';
import showMenu from 'services/actions/menu-state';
import { addAlert } from 'services/actions/page-alerts';

import { AUTH_PROVIDERS, CONTENT } from 'shared/const';
import { useAuthFormToggle } from 'shared/hooks/use-auth-form-toggle';
import { ISuggestionData } from 'shared/models/dadata.model';
import { IUserRegistrationRequest } from 'shared/models/user.model';
import httpClient from 'shared/utils/httpClient/httpClient';
import { tokens } from 'shared/utils/tokens.util';
import { authentication } from 'shared/utils/user.util';

type TError = {
  id: string;
  message: string;
  isActive: boolean;
};

interface UseFormState {
  regData: IUserRegistrationRequest | null;
  errorsList: TError[];
  token: string;
}

interface UseFormParams {
  email: string;
  phone: string;
  password: string;
  passwordRepeat: string;
  selectedOrg: {
    data: ISuggestionData;
    unrestrictedValue: string;
    value: string;
  } | null;
  token: string;
  showCaptcha: () => void;
  anyFormErrors: string[];
}

const ONE_MINUTE = 1000 * 60;

export const useForm = ({
  email,
  phone,
  password,
  passwordRepeat,
  selectedOrg,
  token,
  showCaptcha,
  anyFormErrors,
}: UseFormParams) => {
  const [state, setState] = useState<UseFormState>({
    token: '',
    regData: null,
    errorsList: [
      {
        id: 'inputEmpty',
        message: 'Заполните все поля формы регистрации',
        isActive: false,
      },
      {
        id: 'inputError',
        message: 'Форма содержит ошибки',
        isActive: false,
      },
      {
        id: 'captcha',
        message: 'Подтвердите, что вы не робот!',
        isActive: false,
      },
    ],
  });

  const dispatch = useDispatch();
  const authForm = useAuthFormToggle();

  const errorMessage = useMemo(
    () =>
      state.errorsList.filter((err) => err.isActive)?.[0]?.message ??
      '',
    [state.errorsList],
  );

  const toggleError = useCallback(
    (id: string, status: boolean) => {
      return state.errorsList.map((err) => {
        if (err.id === id) {
          err.isActive = status;
          return err;
        }
        return err;
      });
    },
    [state.errorsList],
  );

  const validateInputsEmpty = useCallback(() => {
    const isAnyInputEmpty =
      email.length === 0 ||
      phone.length === 0 ||
      password.length === 0 ||
      passwordRepeat.length === 0 ||
      selectedOrg === null;

    setState((prev) => ({
      ...prev,
      errorsList: toggleError('inputEmpty', isAnyInputEmpty),
    }));

    return !isAnyInputEmpty;
  }, [
    email.length,
    password.length,
    passwordRepeat.length,
    phone.length,
    selectedOrg,
    toggleError,
  ]);

  const validateInputsErrors = useCallback(() => {
    const isInputsHaveErrors =
      anyFormErrors.filter((err) => !!err.length).length > 0;

    setState((prev) => ({
      ...prev,
      errorsList: toggleError('inputError', isInputsHaveErrors),
    }));

    return !isInputsHaveErrors;
  }, [anyFormErrors, toggleError]);

  const createRegistrationData = useCallback(() => {
    if (!selectedOrg || !phone) return;

    const director = (
      selectedOrg.data?.management?.name ||
      selectedOrg?.data?.name?.fullName
    )?.split(' ');

    const address = { ...selectedOrg.data.address };

    const regionCode = address.data.regionKladrId.slice(0, 2);

    const doesHaveKpp = !!selectedOrg.data?.kpp;

    const street = `${address.data.streetWithType}${
      !!address.data.stead_type ? ', ' + address.data.stead_type : ''
    } ${address.data.stead || ''}`.trim();

    const building =
      !!address.data?.blockType && !!address.data?.block
        ? `${address.data.blockType}. ${address.data.block}`
        : '';

    const houseNumber =
      !!address.data?.houseType && !!address.data?.house
        ? `${address.data?.houseType}. ${address.data?.house}`
        : '';

    const floor = !!address.data?.floor
      ? ` , эт. ${address.data.floor}`
      : '';

    const flat = !!address.data?.flat
      ? ` , ${address.data.flatType}. ${address.data.flat}`
      : '';

    const room = !!address.data?.room
      ? ` , ${address.data.room_type}. ${address.data.room}`
      : '';

    const office = `${floor}${flat}${room}`;

    const result: IUserRegistrationRequest = {
      contact: {
        email,
        phone,
      },
      director: {
        firstName: director?.[1] ?? '',
        lastName: director?.[0] ?? '',
        middleName: director?.[2] ?? '',
      },
      password,
      inn: selectedOrg.data.inn,
      ...(doesHaveKpp && { kpp: selectedOrg.data.kpp }),
      legalAddress: {
        region: address.data.regionWithType,
        city: address.data.cityWithType,
        settlement: address.data.settlementWithType ?? '',
        fiasCode: address.data.fiasId,
        postalCode: address.data.postalCode,
        houseNumber,
        regionCode,
        street,
        building,
        office,
      },
      postAddress: {},
      ogrn: selectedOrg.data.ogrn,
      orgName: selectedOrg.value,
      token,
    };

    setState((prev) => ({ ...prev, regData: result }));

    return result;
  }, [email, password, phone, selectedOrg, token]);

  const handleSubmit = useCallback(
    async (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!validateInputsEmpty() || !validateInputsErrors()) return;

      if (!token) {
        showCaptcha();
        return;
      }

      const regData = createRegistrationData();

      dispatch(showLoader(true));

      try {
        const res = await httpClient.post(regNewUser, regData);
        tokens.setAccess(res.data.access_token);
        tokens.setRefresh(res.data.refresh_token);
        authentication.setType(AUTH_PROVIDERS.http);
        dispatch(
          addAlert(CONTENT.auth.registration.success, 'success'),
        );
      } catch (err) {
        const responseCode = (err as AxiosError).response?.status;
        const responseError = (
          err as {
            response?: {
              data?: {
                message?: string;
              };
            };
          }
        ).response?.data?.message;

        switch (responseCode) {
          case 400:
            dispatch(addAlert(CONTENT.http.invalid, 'danger'));
            break;

          case 403:
            dispatch(addAlert(CONTENT.http.blocked, 'danger'));
            break;

          case 404:
            dispatch(addAlert(CONTENT.http.notFound, 'danger'));
            break;

          case 405:
            dispatch(
              addAlert(
                '<h4>&#128276; Регистрация прошла успешно!</h4>' +
                  responseError,
                'warning',
                ONE_MINUTE,
              ),
            );
            break;

          case 409:
            dispatch(
              addAlert(
                CONTENT.auth.registration.duplicate,
                'danger',
                15000,
              ),
            );
            break;

          default:
            dispatch(addAlert(CONTENT.http.serverError, 'danger'));
            break;
        }
      } finally {
        dispatch(showLoader(false));
        dispatch(showMenu(false));
        authForm.showSignIn();
      }
    },
    [
      authForm,
      token,
      createRegistrationData,
      dispatch,
      showCaptcha,
      validateInputsEmpty,
      validateInputsErrors,
    ],
  );

  useEffect(() => {
    if (!token || !!state.token) return;
    setState((prev) => ({ ...prev, token }));
    handleSubmit();
  }, [handleSubmit, state.token, token]);

  return {
    ...state,
    errorMessage,
    handleSubmit,
  };
};
