import { memo } from 'react';
import { useSelector } from 'react-redux';

import PageAlert from './page-alert/page-alert';

const PageAlerts = memo(() => {
  const { pageAlerts } = useSelector((store) => store.pageAlerts);

  return (
    <div className="page-alerts">
      {pageAlerts.map((pageAlert, i) => (
        <PageAlert
          key={Math.random(0, 88)}
          text={pageAlert.text}
          variant={pageAlert.variant}
          index={i}
        />
      ))}
    </div>
  );
});

PageAlerts.displayName = 'PageAlerts';

export default PageAlerts;
