import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setDocFlowSuccess } from 'services/actions/check-doc-flow';

import CustomBtn from 'components/custom-btn/custom-btn';

import { USER_TYPES } from 'shared/const';
import { getUserType } from 'shared/utils/user.util';

import checkEdoImg from 'images/accept-edo.svg';

import styles from './edo-registration.module.css';

const EdoRegistration = memo(() => {
  const [isLkUser, setIsLkUser] = useState(false);
  const dispatch = useDispatch();

  const goToRegistration = useCallback(() => {
    const url = isLkUser
      ? 'https://lk.platformaofd.ru/web/auth/documents/edo'
      : 'https://pk.platformaofd.ru/edo/about';
    window.open(url, '_blank');
  }, [isLkUser]);

  const closeModal = useCallback(() => {
    dispatch(setDocFlowSuccess(false));
  }, [dispatch]);

  useEffect(() => {
    setIsLkUser(getUserType() === USER_TYPES.LK);
  }, []);

  return (
    <>
      <p>
        <img className="mx-auto" src={checkEdoImg} alt="" />
      </p>

      <p className={`${styles.text} mt-3`}>
        Обмен документами с «Торговый дом Платформа» происходит в ЭДО.
        Пройдите регистрацию в сервисе «Платформа ЭДО»
      </p>

      <p className={styles.text}>
        Если Вы уже работаете с оператором ЭДО, то отправьте
        приглашение ООО «Торговый дом Платформа» по ID =
        2VO-9704104605-7704010010000000000000000000000 для настройки
        роуминга.
      </p>

      <div className="mt-auto">
        <CustomBtn
          theme="edo"
          type="button"
          text="Перейти к регистрации"
          disabled={false}
          onClick={goToRegistration}
          testId="edo-reg-1"
          loading={false}
          isLink
        />

        {isLkUser && (
          <CustomBtn
            theme="lkfn"
            type="button"
            text="Продолжить без регистрации"
            disabled={false}
            onClick={closeModal}
            testId="edo-reg-2"
            loading={false}
            customCls="mt-2"
            isLink={false}
          />
        )}
      </div>
    </>
  );
});

EdoRegistration.displayName = 'EdoRegistration';

export default EdoRegistration;
