import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { logOutUser } from 'services/actions/auth';
import { setDeliveryData } from 'services/actions/delivery';
import { TState } from 'services/reducers';

import AuthMenu from 'components/auth-menu/auth-menu';

import { tokens } from 'shared/utils/tokens.util';

import authLogo from 'images/icons/auth-logo.svg';
import box from 'images/icons/gray-box.svg';
import history from 'images/icons/history.svg';
import info from 'images/icons/info-gray.svg';
import main from 'images/icons/main.svg';
import out from 'images/icons/out-icon.svg';
import products from 'images/icons/products.svg';
import userLogo from 'images/icons/user.svg';

import styles from './profile-menu.module.css';

interface IProfileMenuProps {
  toggleMenu: (e: React.MouseEvent) => void;
}

const ProfileMenu: FC<IProfileMenuProps> = memo(({ toggleMenu }) => {
  const isAuth = tokens.getAccess();
  const { order } = useSelector((store: TState) => store.order);
  const { deliveryData } = useSelector(
    (store: TState) => store.deliveryData,
  );
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    deliveryData.start = true;
    dispatch(setDeliveryData(deliveryData));
    dispatch(logOutUser());
  }, [deliveryData, dispatch]);

  return (
    <div className={styles['profile-menu']}>
      <div className="main">
        <div className={styles.head}>
          <AuthMenu
            logo={isAuth ? userLogo : authLogo}
            toggleMenu={toggleMenu}
          />
        </div>
        <div className={styles.border} />
        <div className="body">
          {Boolean(order?.products.length) && (
            <>
              <div className="orders">
                <div className={styles['menu-block']}>
                  <NavLink
                    data-testid="profile-menu-navlink-1"
                    className={(navData) =>
                      `${styles['menu-item']}${
                        navData.isActive ? ` ${styles.active}` : ''
                      }`
                    }
                    to="/order"
                  >
                    <div className={styles['menu-icon']}>
                      <img src={box} alt="" />
                    </div>
                    <p>Заказ</p>
                    <div className={styles.counter}>
                      {order.products.reduce(
                        (sum, current) => sum + current.quantity,
                        0,
                      )}
                    </div>
                  </NavLink>
                </div>
              </div>
              <div className={`${styles.border} d-lg-none`} />
            </>
          )}
          {/* меню профиля */}
          <div className={styles['menu-block']}>
            <NavLink
              data-testid="profile-menu-navlink-2"
              className={(navData) =>
                `${styles['menu-item']} d-lg-none${
                  navData.isActive ? ` ${styles.active}` : ''
                }`
              }
              end
              to="/"
            >
              <div className={styles['menu-icon']}>
                <img src={main} alt="" />
              </div>
              <p>Главная</p>
            </NavLink>
            <NavLink
              data-testid="profile-menu-navlink-3"
              className={(navData) =>
                `${styles['menu-item']} d-lg-none${
                  navData.isActive ? ` ${styles.active}` : ''
                }`
              }
              to="/products"
            >
              <div className={styles['menu-icon']}>
                <img src={products} alt="" />
              </div>
              <p>Товары</p>
            </NavLink>
            <NavLink
              data-testid="profile-menu-navlink-4"
              className={(navData) =>
                `${styles['menu-item']}${
                  navData.isActive ? ` ${styles.active}` : ''
                }`
              }
              to="/information"
            >
              <div className={styles['menu-icon']}>
                <img src={info} alt="" />
              </div>
              <p>Покупателям</p>
            </NavLink>
            {isAuth && (
              <>
                <NavLink
                  data-testid="profile-menu-navlink-5"
                  className={(navData) =>
                    `${styles['menu-item']} d-lg-none${
                      navData.isActive ? ` ${styles.active}` : ''
                    }`
                  }
                  to="/orders"
                >
                  <div className={styles['menu-icon']}>
                    <img src={history} alt="" />
                  </div>
                  <p>История заказов</p>
                </NavLink>
                <NavLink
                  data-testid="profile-menu-navlink-6"
                  className={(navData) =>
                    `${styles['menu-item']}${
                      navData.isActive ? ` ${styles.active}` : ''
                    }`
                  }
                  to="/profile"
                >
                  <div className={styles['menu-icon']}>
                    <img src={userLogo} alt="" />
                  </div>
                  <p>Профиль</p>
                </NavLink>
              </>
            )}
          </div>
          <div className={styles.border} />
          {isAuth && (
            <div className={styles['out-profile']}>
              <div
                className={styles['menu-block']}
                onClick={logOut}
                role="button"
                aria-hidden="true"
              >
                <div className={styles['menu-item']}>
                  <div className={styles['menu-icon']}>
                    <img src={out} alt="" />
                  </div>
                  <p data-testid="profile-menu-click-1">Выход</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

ProfileMenu.displayName = 'ProfileMenu';

export default ProfileMenu;
