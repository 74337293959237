import { SHOW_LOADER } from 'services/actions/actionTypes';

const initialState = {
  loader: 0,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_LOADER: {
      return {
        loader: (state.loader += !!action.loader ? 1 : -1),
      };
    }
    default: {
      return state;
    }
  }
};

export default loaderReducer;
