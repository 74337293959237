import PropTypes from 'prop-types';
import { memo } from 'react';
import Checkbox from 'react-custom-checkbox';

import styles from './custom-checkbox.module.css';
import { IconCheckbox } from './iconCheckbox';

const CustomCheckbox = memo(
  ({ margin, label, checked, disabled }) => (
    <div
      className={`${styles.main} ${
        margin ? styles[`${margin}`] : ''
      } ${disabled ? styles.disabled : ''}`}
    >
      <Checkbox
        disabled={disabled || false}
        checked={checked || false}
        icon={<IconCheckbox />}
        borderColor="#EA8242"
        borderRadius={2}
        size={16}
        label={label}
      />
    </div>
  ),
);

CustomCheckbox.displayName = 'CustomCheckbox';

export default CustomCheckbox;

CustomCheckbox.propTypes = {
  margin: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};
