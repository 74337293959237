import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { RootState } from 'services/reducers';

import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/httpClient/httpClient';

import { checkDocFlowUrl } from '../../urls';
import {
  IS_EDO_FLOW,
  IS_EDO_FLOW_FAILED,
  IS_EDO_FLOW_SUCCESS,
} from './actionTypes';
import { logOutUser } from './auth';
import { addAlert } from './page-alerts';

export type AppDispatch = ThunkDispatch<RootState, undefined, any>;

/** Способы обмена документами */
const EXCHANGE_TYPES_DTO = {
  edo: 1,
  paper: 2,
};

const checkDocFlow = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: IS_EDO_FLOW,
  });
  try {
    const { data } = await httpClient.get(checkDocFlowUrl);
    dispatch({
      type: IS_EDO_FLOW_SUCCESS,
      isEdoFlow: data === EXCHANGE_TYPES_DTO.edo,
    });
    return data;
  } catch (error) {
    dispatch({
      type: IS_EDO_FLOW_FAILED,
    });

    const err = error as IError;

    if (err?.response?.status === 401) {
      return dispatch(logOutUser());
    }
    dispatch(
      addAlert('Ошибка проверки способа документооборота', 'danger'),
    );
    return false;
  }
};

export const setDocFlowSuccess =
  (data: boolean) => (dispatch: Dispatch) =>
    dispatch({
      type: IS_EDO_FLOW_SUCCESS,
      isEdoFlow: data,
    });

export const setDocFlowFailed = () => async (dispatch: Dispatch) =>
  dispatch({
    type: IS_EDO_FLOW_FAILED,
  });

export default checkDocFlow;
