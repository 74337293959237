import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import App from 'components/app/app';

import { AuthContextProvider } from 'shared/hooks/use-auth';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  GET_CHAR_SUCCESS,
  GET_DESC_SUCCESS,
  GET_DOC_SUCCESS,
  GET_INFO,
  SHOW_LOADER,
} from './services/actions/actionTypes';
import rootReducer from './services/reducers';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 50,
        actionsBlacklist: [
          SHOW_LOADER,
          GET_INFO,
          GET_DOC_SUCCESS,
          GET_DESC_SUCCESS,
          GET_CHAR_SUCCESS,
        ],
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(rootReducer, enhancer);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
