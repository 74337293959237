import classNames from 'classnames/bind';
import { FC, memo } from 'react';

import styles from './content-loader.module.css';

const cx = classNames.bind(styles);

export const ProfileLoader: FC = memo(() => {
  return (
    <div className={cx('placeholder-wrapper')}>
      <div className={cx('base-placeholder', 'placeholder-title')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('col-4-row', 'col-row')}>
        <div className={cx('base-placeholder', 'placeholder-row')} />
        <div className={cx('base-placeholder', 'placeholder-row')} />
        <div className={cx('base-placeholder', 'placeholder-row')} />
        <div className={cx('base-placeholder', 'placeholder-row')} />
      </div>
      <div className={cx('base-placeholder', 'placeholder-row')} />
      <div className={cx('col-2-row', 'col-row')}>
        <div className={cx('base-placeholder', 'placeholder-row')} />
        <div className={cx('base-placeholder', 'placeholder-row')} />
      </div>
      <div className={cx('placeholder-separator')} />
      <div className={cx('base-placeholder', 'placeholder-title')} />
      <div className={cx('base-placeholder', 'placeholder-row')} />
    </div>
  );
});

ProfileLoader.displayName = 'ProfileLoader';
