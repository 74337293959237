import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { memo } from 'react';

import styles from './numbered-item.module.css';

const cx = classNames.bind(styles);

const NumberedItem = memo(({ content, number }) => (
  <li className={cx('item')}>
    <p className={cx('marker')}>{number}</p>
    <div className={cx('text-wrapper')}>{content}</div>
  </li>
));

NumberedItem.displayName = 'NumberedItem';

export default NumberedItem;

NumberedItem.propTypes = {
  number: PropTypes.number.isRequired,
  content: PropTypes.node.isRequired,
};
