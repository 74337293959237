import { FC, memo } from 'react';

import awaitingDelivery from 'images/icons/awaiting-delivery.svg';
import deliveredIcon from 'images/icons/delivered.svg';
import deliveryDisabledIcon from 'images/icons/delivery-disabled.svg';

import styles from '../history-orders-item.module.css';

interface DeliveryStatusIconProps {
  deliveryStatusName: string;
}

export const DeliveryStatusIcon: FC<DeliveryStatusIconProps> = memo(
  ({ deliveryStatusName = null }) => {
    let statusIcon;

    switch (deliveryStatusName) {
      case 'Ожидает отгрузку':
        statusIcon = awaitingDelivery;
        break;

      case 'Отгружен':
        statusIcon = deliveredIcon;
        break;

      default:
        statusIcon = deliveryDisabledIcon;
        break;
    }

    return <img src={statusIcon} className={styles.icon} alt="" />;
  },
);
