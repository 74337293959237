import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { memo } from 'react';

import styles from './dropdown-content.module.css';

const cx = classNames.bind(styles);

const DropdownContent = memo(({ content, openFlag }) => (
  <div
    className={cx('content-wrapper', {
      'show-content': openFlag,
    })}
  >
    {content}
  </div>
));

DropdownContent.displayName = 'DropdownContent';

export default DropdownContent;

DropdownContent.propTypes = {
  content: PropTypes.node.isRequired,
  openFlag: PropTypes.bool.isRequired,
};
