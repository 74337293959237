import { FC, memo, useMemo } from 'react';

import DownloadLink from 'components/download-link/download-link';

import docIcon from 'images/icons/doc.svg';
import downloadIconDoc from 'images/icons/download-doc.svg';

import { orderDocsUrl } from '../../../urls';

interface DocumentStatusIconProps {
  orderId: number;
  updFileInfo?: {
    orderId: number;
    docType: string;
    docId: number;
    docFile: {
      docName: string;
      docBody: string;
    };
  };
}

export const DocumentStatusIcon: FC<DocumentStatusIconProps> = memo(
  ({ orderId, updFileInfo = null }) => {
    const statusIcon = useMemo(() => {
      return updFileInfo?.docId ? downloadIconDoc : docIcon;
    }, [updFileInfo?.docId]);

    return (
      <div
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
        aria-hidden="true"
      >
        <DownloadLink
          link={`${orderDocsUrl}${orderId}/doc/${updFileInfo?.docId}/attachment`}
          payload={{ responseType: 'blob' }}
          textLink="Скачать УПД"
          linkStyle="icon"
          fileName={updFileInfo?.docFile.docName}
          documentType="application/pdf"
          iconSrc={statusIcon}
          showedDownloadIcon={false}
          isDisabled={!updFileInfo?.docId}
        />
      </div>
    );
  },
);
