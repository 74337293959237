import {
  CLEAR_ORG,
  GET_ORG,
  GET_ORG_FAILED,
  GET_ORG_SUCCESS,
} from 'services/actions/actionTypes';

const initialState = {
  org: null,
  orgRequest: false,
  orgFailed: false,
};

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORG: {
      return {
        ...state,
        orgRequest: true,
        orgFailed: false,
      };
    }
    case GET_ORG_SUCCESS: {
      return {
        ...state,
        org: action.org,
        orgRequest: false,
      };
    }
    case GET_ORG_FAILED: {
      return {
        ...state,
        orgFailed: true,
        orgRequest: false,
      };
    }
    case CLEAR_ORG: {
      return {
        ...state,
        org: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default orgReducer;
