import PropTypes from 'prop-types';
import { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import styles from './overlay.module.css';

const Overlay = memo(({ onClose }) => {
  const { modal } = useSelector((store) => store.modal);

  const handleClose = useCallback(
    (e) => {
      if (e.keyCode === 27 && modal) {
        onClose(e);
      }
    },
    [modal, onClose],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleClose);
    return () => window.removeEventListener('keydown', handleClose);
  });

  return (
    <div
      onClick={onClose}
      role="button"
      aria-hidden="true"
      className={styles.overlay}
    />
  );
});

Overlay.displayName = 'Overlay';

export default Overlay;

Overlay.propTypes = {
  onClose: PropTypes.func,
};

Overlay.defaultProps = {
  onClose: null,
};
