import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import showMenu from 'services/actions/menu-state';

import CustomBtn from 'components/custom-btn/custom-btn';
import OrderDelivery from 'components/order-delivery/order-delivery';
import OrderItems from 'components/order-items/order-items';

import { tokens } from 'shared/utils/tokens.util';

import { currency } from '../../utils';
import styles from './aside.module.scss';

const Aside = memo(() => {
  const { order } = useSelector((store) => store.order);
  const { isTariffLoading } = useSelector(
    (store) => store.deliveryData,
  );
  const [startOrder, setStartOrder] = useState(false);
  const [deliveryPrice, setDeliverPrice] = useState(0);
  const [orderLoaderFlag, setOrderLoaderFlag] = useState(false);
  const isAuth = tokens.getAccess();

  const dispatch = useDispatch();

  const changeDeliveryPriceOrder = useCallback((price) => {
    setDeliverPrice(price);
  }, []);

  const handleOrderLoaderFlagToggle = useCallback((val) => {
    setOrderLoaderFlag(val);
  }, []);

  const startOrderOn = useCallback(() => {
    window.scrollTo(0, 0);
    if (!isAuth) {
      return dispatch(showMenu(true));
    }
    handleOrderLoaderFlagToggle(true);
    setStartOrder(true);
  }, [dispatch, handleOrderLoaderFlagToggle, isAuth]);

  const startOrderOff = useCallback(() => {
    setStartOrder(false);
    window.scrollTo(0, 0);
  }, []);

  return (
    <aside className={`${styles.aside} d-none d-lg-block`}>
      <div className="aside-item mt-3">
        <OrderItems aside />
      </div>
      <div className="aside-delivery mt-3">
        <OrderDelivery
          aside
          startOrder={startOrder}
          startOrderOff={startOrderOff}
          onOrderLoaderFlagToggle={handleOrderLoaderFlagToggle}
          changeDeliveryPriceOrder={changeDeliveryPriceOrder}
        />

        {Boolean(order?.totalPrice) && (
          <div className={`${styles['total-price']} mt-3 pt-3`}>
            <div className={styles.total}>Итого:</div>
            {`${currency(order.totalPrice + deliveryPrice)} ₽`}
          </div>
        )}

        <div className="mt-3 mb-3">
          <CustomBtn
            theme="edo"
            type="button"
            text={isAuth ? 'Заказать' : 'Авторизуйтесь'}
            onClick={startOrderOn}
            testId="products-btn-1"
            loading={orderLoaderFlag || isTariffLoading}
            isLink={false}
            customCls={null}
            disabled={false}
          />
        </div>
      </div>
    </aside>
  );
});

Aside.displayName = 'Aside';

export default Aside;
