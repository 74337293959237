import { FC, memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { logOutUser } from 'services/actions/auth';
import showLoader from 'services/actions/loader';
import { addAlert } from 'services/actions/page-alerts';

import DownloadLink from 'components/download-link/download-link';

import { IUpdFileInfo } from 'shared/models/file.model';
import { IExtendedOder } from 'shared/models/order.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/httpClient/httpClient';

import copy from 'images/icons/copy.svg';
import info from 'images/icons/info.svg';
import sign from 'images/icons/sign.svg';
import link from 'images/icons/transition.svg';

import { createOrderUrl, orderDocsUrl } from '../../urls';
import { PICKUP_ADRESS, currency } from '../../utils';
import { DeliveryStatusIcon } from './deliveryStatusIcon';
import { DocumentStatusIcon } from './documentStatusIcon';
import styles from './history-orders-subitem.module.css';
import { PayStatusStatusIcon } from './payStatusStatusIcon';
import { PickupStatusIcon } from './pickupStatusIcon';

const patternForFullTelephone =
  /(\+7|8|7)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;

const copyToClipboard = (str: string, id: string) => {
  if (
    navigator &&
    navigator.clipboard &&
    navigator.clipboard.writeText
  ) {
    const tooltip = document.getElementById(id);
    if (tooltip) {
      tooltip.innerHTML = 'Скопировано!';
    }

    return navigator.clipboard.writeText(str);
  }
  return Promise.reject(
    new Error('The Clipboard API is not available.'),
  );
};

const showTooltip = (str: string, id: string) => {
  const tooltip = document.getElementById(id);
  if (tooltip) {
    tooltip.innerHTML = str;
  }
};

interface IHistoryOrdersSubItemProps {
  orderId: number;
  contactName: string;
  contactPhone: string;
  deliveryAddress: string;
  trackNumber: string;
  storageId: string;
  deliveryStatus: string;
  deliveryStatusName: string;
  payStatusName: string;
  payRemain: number;
  price: number;
  adressDesc: string;
  documentStatusName: string;
  deliveryPrice: number;
  vendorName: string;
}

const HistoryOrdersSubItem: FC<IHistoryOrdersSubItemProps> = memo(
  ({
    orderId,
    contactName,
    contactPhone,
    deliveryAddress,
    trackNumber,
    storageId,
    deliveryStatus,
    deliveryStatusName,
    payStatusName,
    payRemain,
    price,
    adressDesc,
    documentStatusName,
    deliveryPrice,
    vendorName,
  }) => {
    const progressPercent = Math.trunc((payRemain * 100) / price);

    const [orderInfo, setOrderInfo] = useState<IExtendedOder[]>([]);
    const [updFileInfo, setUpdFileInfo] = useState<IUpdFileInfo>();
    const [invoiceFileInfo, setInvoiceFileInfo] =
      useState<IUpdFileInfo>();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
      const getOrderData = async () => {
        try {
          const { data } = await httpClient.get(
            `${createOrderUrl}/${orderId}`,
          );
          setOrderInfo((prevstate) => prevstate.concat(data));
        } catch (error) {
          const err = error as IError;

          if (err.response.status === 401) {
            return dispatch(logOutUser());
          }
          dispatch(
            addAlert('Ошибка получения списка заказов', 'danger'),
          );
        }
      };

      const getFileInfo = async () => {
        try {
          const { data } = await httpClient.get(
            `${orderDocsUrl + orderId}/docs`,
          );
          data.reduce((current: IUpdFileInfo, item: IUpdFileInfo) => {
            let currentArr = current;
            if (item.docType === 'UPD') {
              currentArr = { ...item };
              setUpdFileInfo(currentArr);
            } else if (item.docType === 'INVOICE_ORDER') {
              currentArr = { ...item };
              setInvoiceFileInfo(currentArr);
            }
            return false;
          }, false);
        } catch (error) {
          dispatch(
            addAlert(
              'Ошибка получения документов по заказу',
              'danger',
            ),
          );
        }
      };

      const setData = async () => {
        dispatch(showLoader(true));
        setLoading(true);
        await getOrderData();
        await getFileInfo();
        dispatch(showLoader(false));
        setLoading(false);
      };

      setData();
    }, [dispatch, orderId]);

    return (
      <div className={styles['detailed-info']}>
        <div className={`${styles.block} text-start text-md-end`}>
          <div
            className={`${styles['col-name']} d-none d-md-block mb-2`}
          >
            <Row>
              <Col md={6} xs={12}>
                <div className="text-start">Модель</div>
              </Col>
              <Col md={2} xs={12}>
                <div>Кол-во</div>
              </Col>
              <Col md={2} xs={12}>
                <div>Цена за шт.</div>
              </Col>
              <Col md={2} xs={12}>
                <div>Сумма за модель</div>
              </Col>
            </Row>
          </div>
          <ol className={styles.list}>
            {orderInfo.map((item, i) => (
              <li key={item.productName + String(i)} className="mb-4">
                <div className={styles['col-desc']}>
                  <Row className="text-center text-md-end">
                    <Col md={6} xs={12}>
                      <div
                        className={`${styles.desc} ${styles['text-left']}`}
                      >
                        {item.productName}
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xs={3}
                      className="pt-3 pt-md-0 pl-0 pr-0"
                    >
                      <div>{item.quantity}</div>
                    </Col>
                    <Col
                      md={1}
                      xs={1}
                      className="pt-3 pt-md-0 pl-0 pr-0"
                    >
                      <div
                        className={`${styles['text-center']} ${styles.prefix}`}
                      >
                        x
                      </div>
                    </Col>
                    <Col
                      md={1}
                      xs={3}
                      className="pt-3 pt-md-0 pl-0 pr-0"
                    >
                      <div>{item.price} ₽</div>
                    </Col>
                    <Col
                      md={1}
                      xs={1}
                      className="pt-3 pt-md-0 d-block d-md-none pl-0 pr-0"
                    >
                      <div
                        className={`${styles['text-center']} ${styles.prefix}`}
                      >
                        =
                      </div>
                    </Col>
                    <Col
                      md={2}
                      xs={4}
                      className="pt-3 pt-md-0 pl-5 pr-0"
                    >
                      <div>{item.sum} ₽</div>
                    </Col>
                  </Row>
                </div>
              </li>
            ))}
          </ol>
        </div>
        <div className={styles.block}>
          {deliveryStatus === 'Доставка' && (
            <div
              className={`${styles['col-desc']} ${styles['text-left']}`}
            >
              <Row className="mb-4">
                <Row>
                  <Col
                    xs={12}
                    md={4}
                    xl={3}
                    className={styles['media-width']}
                  >
                    <div className="d-flex">
                      <DeliveryStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={5}
                    xl={3}
                    className={`${styles['media-width']} pt-3 pt-md-0`}
                  >
                    <a
                      data-testid="his-order-subitem-link-1"
                      className={styles.link}
                      href="https://my.versta24.ru/trackOrder"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Отслеживание на сайте ТК
                      <img className={styles.img} src={link} alt="" />
                    </a>
                  </Col>
                </Row>
                <div className={`${styles['flex-wrap']} d-flex mt-3`}>
                  <Col
                    xs={12}
                    md={10}
                    xl={4}
                    className={`${styles['media-width']} pt-3 pt-md-0`}
                  >
                    <div
                      className={`${styles['with-icon']} ${styles['order-num']} ${styles['with-icon-media']}`}
                    >
                      Номер заказа в транспортной компании
                      <div className={styles['tooltip-copy']}>
                        <p
                          className={styles['tooltiptext-copy']}
                          id="tooltipText"
                        >
                          Отслеживайте путь своего заказа по &quot;
                          номеру заказа в ТК &quot; на сайте:{' '}
                          <a
                            data-testid="his-order-subitem-link-2"
                            href="https://my.versta24.ru/trackOrder"
                          >
                            https://my.versta24.ru/trackOrder
                          </a>
                        </p>
                        <img
                          data-testid="his-order-mouse-event-1"
                          src={info}
                          onMouseOut={() => {
                            showTooltip(
                              "Отслеживайте путь своего заказа по 'номеру заказа в ТК' на сайте: <a data-testid='his-order-subitem-link-3' href='https://my.versta24.ru/trackOrder'>https://my.versta24.ru/trackOrder</a>",
                              'tooltipText',
                            );
                          }}
                          alt=""
                          onBlur={() => false}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={2}
                    xl={2}
                    className={`${styles.centered} pt-3 pt-md-0`}
                  >
                    <div
                      className={`${styles['with-icon']} ${styles['track-num']}`}
                    >
                      {trackNumber}
                      <div className={styles['tooltip-copy']}>
                        <span
                          className={styles['tooltiptext-copy']}
                          id="tooltipCopy"
                        >
                          Копировать
                        </span>
                        <img
                          data-testid="his-order-event-1"
                          src={copy}
                          onClick={() => {
                            copyToClipboard(
                              trackNumber,
                              'tooltipCopy',
                            );
                          }}
                          onMouseOut={() => {
                            showTooltip('Копировать', 'tooltipCopy');
                          }}
                          alt=""
                          onBlur={() => false}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
          {deliveryStatus === 'Самовывоз' && (
            <div
              className={`${styles['col-desc']} ${styles['text-left']}`}
            >
              <Row className="mb-4">
                <div className={`${styles['flex-wrap']} d-flex`}>
                  <Col xs={12} md={4} xl={3}>
                    <div className="d-flex">
                      <PickupStatusIcon
                        deliveryStatusName={deliveryStatusName}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={5} xl={3} className="pt-3 pt-md-0">
                    <Link
                      to="/information#pickup"
                      data-testid="his-order-subitem-link-4"
                      className={styles.link}
                    >
                      Подробнее о самовывозе
                      <img className={styles.img} src={link} alt="" />
                    </Link>
                  </Col>
                </div>
                <div className={`${styles['flex-wrap']} d-flex mt-3`}>
                  <Col
                    xs={12}
                    md={10}
                    xl={4}
                    className={`${styles['media-width']} pt-3 pt-md-0`}
                  >
                    <div
                      className={`${styles['with-icon']} ${styles['order-num']} ${styles['with-icon-media']}`}
                    >
                      Номер заказа на складе
                      <div className={styles['tooltip-copy']}>
                        <p
                          className={styles['tooltiptext-copy']}
                          style={{ width: '14rem' }}
                          id="tooltipText"
                        >
                          Сообщите номер заказа на складе сотруднику
                          склада при получении заказа. Если забор
                          заказа со склада осуществляет курьер или
                          транспортная компания, то обязательно
                          сообщите им номер заказа для получения.
                        </p>
                        <img
                          data-testid="his-order-event-2"
                          src={info}
                          onMouseOut={() => {
                            showTooltip(
                              `Cообщите номер заказа на складе сотруднику склада при получении заказа.
                            Если забор заказа со склада осуществляет курьер или транспортная компания,
                            то обязательно сообщите им номер заказа для получения.`,
                              'tooltipText',
                            );
                          }}
                          alt=""
                          onBlur={() => false}
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={2}
                    xl={2}
                    className={`${styles.centered} pt-3 pt-md-0`}
                  >
                    <div
                      className={`${styles['with-icon']} ${styles['track-num']}`}
                    >
                      {storageId}
                      <div className={styles['tooltip-copy']}>
                        <span
                          className={styles['tooltiptext-copy']}
                          id="tooltipCopy"
                        >
                          Копировать
                        </span>
                        <img
                          data-testid="his-order-event-3"
                          src={copy}
                          onClick={() => {
                            copyToClipboard(storageId, 'tooltipCopy');
                          }}
                          onMouseOut={() => {
                            showTooltip('Копировать', 'tooltipCopy');
                          }}
                          onBlur={() => false}
                          alt=""
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
          <div
            className={
              deliveryStatus === 'Доставка'
                ? 'd-flex align-items-center justify-content-between'
                : ''
            }
          >
            <ul
              className={`${styles['profile-list']} d-sm-flex justify-content-between flex-wrap`}
            >
              <li className={`${styles['profile-item']} mb-3`}>
                <div className={styles['profile-title']}>
                  Контактное лицо
                </div>
                <div className={styles['profile-desc']}>
                  {contactName}
                </div>
              </li>
              <li className={`${styles['profile-item']} mb-3`}>
                <div className={styles['profile-title']}>
                  Контактный телефон
                </div>
                <div className={styles['profile-desc']}>
                  {contactPhone
                    .replace(/[^0-9]/g, '')
                    .replace(
                      patternForFullTelephone,
                      '+7 ($2) $3-$4-$5',
                    )}
                </div>
              </li>
              <li className={`${styles['profile-item']} mb-3`}>
                <div className={styles['profile-title']}>
                  {' '}
                  {deliveryStatus === 'Самовывоз'
                    ? 'Адрес самовывоза'
                    : 'Адрес доставки'}
                </div>
                <div className={styles['profile-desc']}>
                  {deliveryStatus === 'Самовывоз'
                    ? PICKUP_ADRESS
                    : deliveryAddress}
                </div>
              </li>
              {adressDesc !== null ? (
                <li className={`${styles['profile-item']} mb-3`}>
                  <div className={styles['profile-title']}>
                    Комментарии к заказу
                  </div>
                  <div className={styles['profile-desc']}>
                    {adressDesc}
                  </div>
                </li>
              ) : null}
              {deliveryStatus === 'Доставка' && (
                <li className={`${styles['profile-item']} mb-3`}>
                  <div className={styles['profile-title']}>
                    Тариф доставки
                  </div>
                  <div className={styles['profile-desc']}>
                    {vendorName}
                  </div>
                </li>
              )}
            </ul>
            {deliveryStatus === 'Доставка' && (
              <div
                className={`${styles['delivery-price']} text-nowrap`}
              >
                {deliveryPrice} ₽
              </div>
            )}
          </div>
        </div>
        {/* УПД */}
        <div className={styles.block}>
          <Row>
            <Col md={4} xl={3} xs={12}>
              {Boolean(!loading && updFileInfo) && (
                <DocumentStatusIcon
                  documentStatusName={documentStatusName}
                />
              )}
            </Col>
            <Col md={3} xl={2} xs={12} className="pt-3 pt-md-0">
              <div
                className={`${styles.load} d-flex align-items-center`}
              >
                {Boolean(!loading && updFileInfo?.docId) && (
                  <DownloadLink
                    link={`${orderDocsUrl}${orderId}/doc/${updFileInfo?.docId}/attachment`}
                    payload={{ responseType: 'blob' }}
                    textLink="Скачать УПД"
                    linkStyle="short"
                    fileName={updFileInfo?.docFile.docName}
                    documentType="application/pdf"
                    iconSrc={''}
                    showedDownloadIcon={null}
                    isDisabled={!updFileInfo?.docId}
                  />
                )}
              </div>
            </Col>
            <Col md={5} xl={7} xs={12} className="pt-3 pt-md-0">
              <a
                data-testid="his-order-subitem-link-6"
                href="https://edo.platformaofd.ru/"
                target="_blank"
                className={`${styles.button} float-md-end justify-content-center`}
                rel="noreferrer"
              >
                <img src={sign} alt="" />
                Подписать в «Платформа ЭДО»
              </a>
            </Col>
          </Row>
        </div>
        {/* pay */}
        <div className="mt-4">
          <Row>
            <Col md={4} lg={4} xs={12} xl={3}>
              <div className="">
                <PayStatusStatusIcon
                  payStatusName={payStatusName}
                  percent={progressPercent}
                />
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xs={12}
              xl={3}
              className="pt-3 pt-md-0"
            >
              <div
                className={`${styles.load} d-flex align-items-center`}
              >
                {Boolean(!loading && invoiceFileInfo?.docFile) && (
                  <DownloadLink
                    link={`${orderDocsUrl}${orderId}/invoice`}
                    payload={{ responseType: 'blob' }}
                    textLink="Скачать счет на оплату"
                    linkStyle="short"
                    fileName={invoiceFileInfo?.docFile.docName}
                    documentType="application/pdf"
                    iconSrc={''}
                    showedDownloadIcon={null}
                    isDisabled={!invoiceFileInfo?.docFile}
                  />
                )}

                {Boolean(!loading && !invoiceFileInfo) && (
                  <>Счет формируется</>
                )}
              </div>
            </Col>
            <Col
              md={12}
              lg={5}
              xs={12}
              xl={6}
              className="pt-3 pt-md-0"
            >
              {payRemain === 0 ? (
                <div
                  className={`${styles['total-sum']} ${styles['pull-right']}`}
                >
                  <p className={styles.text}>Сумма заказа</p>
                  <p className={styles.price}>{currency(price)}₽</p>
                </div>
              ) : (
                <div className="float-sm-end d-flex align-items-end">
                  <div className={styles['text-gray']}>
                    Осталось оплатить:
                  </div>
                  <div className={styles['remain-sum']}>
                    {currency(payRemain)} ₽
                  </div>
                  <div>из {currency(price)} ₽</div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  },
);

HistoryOrdersSubItem.displayName = 'HistoryOrdersSubItem';

export default HistoryOrdersSubItem;
