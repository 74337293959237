import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  changePasswordError,
  loginUser,
} from 'services/actions/auth';
import { setDeliveryData } from 'services/actions/delivery';

import AuthMenu from 'components/auth-menu/auth-menu';
import CustomBtn from 'components/custom-btn/custom-btn';
import CustomInput from 'components/custom-input/custom-input';
import ToolTip from 'components/tooltip/tooltip';

import { CONTENT } from 'shared/const';
import { useAuthFormToggle } from 'shared/hooks/use-auth-form-toggle';
import { tokens } from 'shared/utils/tokens.util';

import authLogo from 'images/icons/auth-logo.svg';
import info from 'images/icons/info-gray.svg';
import passIconClosed from 'images/icons/pass-icon-closed.svg';
import passIconOpened from 'images/icons/pass-icon-opened.svg';
import switchIcon from 'images/icons/switch-icon.svg';
import userLogo from 'images/icons/user.svg';
import whiteSwitchIcon from 'images/icons/white-switch-icon.svg';

import styles from './authorization.module.css';

const RESTORE_URL =
  'https://lk.platformaofd.ru/web/noauth/password/restore';

interface AuthorizationProps {
  toggleMenu: (e: Event) => void;
}

const Authorization: FC<AuthorizationProps> = ({ toggleMenu }) => {
  const dispatch = useDispatch();
  const { loader } = useSelector((store: any) => store.loader);
  const { deliveryData } = useSelector(
    (store: any) => store.deliveryData,
  );
  const { passwordError } = useSelector((store: any) => store.auth);

  const [platformaFlag, setPlatformaFlag] = useState(false);
  const [login, setLogin] = useState('');
  const [loginErrorText, setLoginErrorText] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const isAuth = tokens.getAccess();
  const { showSignUp } = useAuthFormToggle();

  const changePlatformaFlag = useCallback(() => {
    setPlatformaFlag(!platformaFlag);
  }, [platformaFlag]);

  const clearErrors = useCallback(() => {
    setLoginError(false);
    setLoginErrorText('');
    dispatch(changePasswordError(''));
  }, [dispatch]);

  const clearAuthData = useCallback(() => {
    setLogin('');
    setPassword('');
  }, []);

  const changeLogin = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLogin(e.target.value);
    },
    [],
  );

  const changePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    [],
  );

  const validatePassword = useCallback(() => {
    const isValid = Boolean(password.length);
    dispatch(changePasswordError(isValid ? '' : 'Введите пароль'));
    return isValid;
  }, [dispatch, password.length]);

  const validateLogin = useCallback(() => {
    const hasLength = Boolean(login.length);
    if (!hasLength) {
      setLoginErrorText('Укажите логин');
      setLoginError(true);
      return {
        isLoginValid: false,
        username: login,
      };
    }

    const isEmail = login.includes('@');
    if (isEmail) {
      return {
        isLoginValid: true,
        username: login,
      };
    }

    let clearedLogin = login.replace(/[^0-9]/g, '');
    if (clearedLogin.length === 0) {
      clearAuthData();
      dispatch(
        changePasswordError(CONTENT.auth.login.badCredentials),
      );
      return {
        isLoginValid: false,
        username: clearedLogin,
      };
    }

    if (clearedLogin.length === 11) {
      const isWriteFirstDigit = ['7', '8'].includes(clearedLogin[0]);
      if (!isWriteFirstDigit) {
        dispatch(
          changePasswordError(CONTENT.auth.login.badCredentials),
        );
        return {
          isLoginValid: false,
          username: clearedLogin,
        };
      }
      clearedLogin = '7' + clearedLogin.slice(1);
    }

    if (clearedLogin.length === 10) {
      clearedLogin = '7' + clearedLogin;
    }

    setLogin(clearedLogin);
    const isValidPhoneNumber = clearedLogin.match(/^(7)\d{10}$/);

    if (isValidPhoneNumber?.length) {
      return {
        isLoginValid: true,
        username: clearedLogin,
      };
    }

    dispatch(changePasswordError(CONTENT.auth.login.badCredentials));
    return {
      isLoginValid: false,
      username: login,
    };
  }, [clearAuthData, dispatch, login]);

  const validate = useCallback(() => {
    const isPasswordValid = validatePassword();
    const { isLoginValid, username } = validateLogin();
    return {
      isAllValid: isLoginValid && isPasswordValid,
      username,
    };
  }, [validateLogin, validatePassword]);

  const logIn = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      clearErrors();
      const { isAllValid, username } = validate();
      if (!isAllValid) return;
      dispatch(
        loginUser({
          username,
          password,
        }),
      );
      deliveryData.start = true;
      navigate('/');
      dispatch(setDeliveryData(deliveryData));
    },
    [
      clearErrors,
      deliveryData,
      dispatch,
      navigate,
      password,
      validate,
    ],
  );

  useEffect(
    () => {
      clearErrors();
      clearAuthData();
    },
    // eslint-disable-next-line
    [platformaFlag],
  );

  return (
    <div className={styles.authorization}>
      <div className="main">
        <div className={styles.head}>
          <AuthMenu
            logo={isAuth ? userLogo : authLogo}
            toggleMenu={toggleMenu}
          />
        </div>

        <div className="body">
          <div className="auth-block">
            {!platformaFlag && (
              <form onSubmit={logIn}>
                <CustomInput
                  testId="auth-1"
                  placeholder="Телефон или Email"
                  label="Логин"
                  type="text"
                  error={loginError ? loginErrorText : ''}
                  theme="edo"
                  margin="mt"
                  value={login}
                  onChange={changeLogin}
                  customRightElement={
                    <div className={styles.tooltip}>
                      <ToolTip
                        text={
                          'Авторизация по email доступна для партнеров и сотрудников клиента Платформы ОФД.'
                        }
                      >
                        <img src={info} alt="" />
                      </ToolTip>
                    </div>
                  }
                />

                <CustomInput
                  testId="auth-2"
                  placeholder="Введите пароль"
                  label="Пароль"
                  type="password"
                  error={passwordError}
                  theme="edo"
                  margin="mt"
                  revealFlag
                  passIconOpened={passIconOpened}
                  passIconClosed={passIconClosed}
                  onChange={changePassword}
                />

                <div className={styles['pass-links']}>
                  <button
                    type="button"
                    rel="noreferrer"
                    className={styles['pass-link']}
                    onClick={showSignUp}
                  >
                    Зарегистрироваться
                  </button>

                  <a
                    className={styles['pass-link']}
                    target="_blank"
                    href={RESTORE_URL}
                    rel="noreferrer"
                    data-testid="restore-pwd-link"
                  >
                    Восстановить пароль
                  </a>
                </div>

                <div className="mt-4">
                  <CustomBtn
                    theme="edo"
                    type="submit"
                    text={loader ? 'Авторизация...' : 'Войти'}
                    disabled={false}
                    testId="auth-1"
                    loading={false}
                    isLink={false}
                    customCls={null}
                  />
                </div>
              </form>
            )}

            {platformaFlag && (
              <button
                type="button"
                data-testid="auth-click-1"
                onClick={changePlatformaFlag}
                className={styles['switch-edo']}
              >
                <span>Авторизоваться c помощью «Торговый Дом»</span>
              </button>
            )}
          </div>

          <div className={styles.separator}>
            <p>или</p>
          </div>

          {!platformaFlag && (
            <button
              type="button"
              data-testid="auth-click-2"
              onClick={changePlatformaFlag}
              className={styles['switch-platforma']}
            >
              <p>Авторизоваться с помощью «Платформа ОФД»</p>

              <img
                className={styles['switch-icon']}
                src={whiteSwitchIcon}
                alt=""
              />
            </button>
          )}

          {platformaFlag && (
            <form onSubmit={logIn}>
              <CustomInput
                testId="auth-3"
                placeholder="Е-mail или Телефон"
                label="Логин"
                type="text"
                error={loginError ? loginErrorText : ''}
                iconFlag="true"
                icon={switchIcon}
                theme="platforma"
                margin="mt"
                onChange={changeLogin}
              />

              <CustomInput
                testId="auth-4"
                placeholder="Введите пароль"
                label="Пароль"
                type="password"
                error={passwordError}
                theme="platforma"
                margin="mt"
                icon={switchIcon}
                revealFlag
                passIconOpened={passIconOpened}
                passIconClosed={passIconClosed}
                onChange={changePassword}
              />

              <a
                className={styles['pass-link']}
                target="_blank"
                href={RESTORE_URL}
                rel="noreferrer"
                data-testid="restore-pwd-link"
              >
                Восстановить пароль
              </a>

              <div className="mt-4">
                <CustomBtn
                  theme="platforma"
                  type="submit"
                  text={loader ? 'Авторизация...' : 'Войти'}
                  disabled={false}
                  testId="auth-2"
                  loading={false}
                  isLink={false}
                  customCls={null}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
Authorization.displayName = 'Authorization';

export default Authorization;
