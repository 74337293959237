import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/httpClient/httpClient';

import { checkEdoUrl } from '../../urls';
import {
  IS_EDO_REGISTERED,
  IS_EDO_REGISTERED_FAILED,
  IS_EDO_REGISTERED_SUCCESS,
} from './actionTypes';
import { logOutUser } from './auth';
import { AppDispatch } from './check-doc-flow';
import { addAlert } from './page-alerts';

const checkEdo = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: IS_EDO_REGISTERED,
  });
  try {
    const { data } = await httpClient.get(checkEdoUrl);
    dispatch({
      type: IS_EDO_REGISTERED_SUCCESS,
      isEdoRegistered: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: IS_EDO_REGISTERED_FAILED,
    });

    const err = error as IError;
    if (err?.response?.status === 401) {
      return dispatch(logOutUser());
    }
    dispatch(addAlert('Ошибка проверки регистрации в ЭДО', 'danger'));
    return false;
  }
};

export default checkEdo;
