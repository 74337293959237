import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDeliveryData } from 'services/actions/delivery';
import { addAlert } from 'services/actions/page-alerts';

import { useOrder } from 'shared/hooks/use-order.hook';

import decrement from 'images/counter/minus.svg';
import increment from 'images/counter/plus.svg';

import { withKeyHasBlocked, withSetHasBlocked } from '../../utils';
import styles from './product-сounter.module.css';

const ProductCounter = memo(({ product }) => {
  const { order } = useSelector((store) => store.order);
  const { deliveryData } = useSelector((store) => store.deliveryData);

  const dispatch = useDispatch();

  const orderController = useOrder(product);

  const blockedCounterFnWithKey = withKeyHasBlocked(order, product);
  const blockedCounterFnSetWithOfd = withSetHasBlocked(
    order,
    product,
  );

  const allowDeliveryRecalculation = useCallback(() => {
    deliveryData.start = true;
    dispatch(setDeliveryData(deliveryData));
  }, [deliveryData, dispatch]);

  const handleInputChange = useCallback(
    (num) => {
      allowDeliveryRecalculation();

      num === 0
        ? orderController.deleteProduct()
        : orderController.updateQuantity(num, 'set');
    },
    [orderController, allowDeliveryRecalculation],
  );

  const increaseCount = useCallback(() => {
    allowDeliveryRecalculation();
    orderController.updateQuantity(1);
  }, [allowDeliveryRecalculation, orderController]);

  const decreaseCount = useCallback(() => {
    allowDeliveryRecalculation();
    orderController.updateQuantity(-1);
  }, [allowDeliveryRecalculation, orderController]);

  const handleChange = useCallback(
    (event) => {
      if (blockedCounterFnSetWithOfd || blockedCounterFnWithKey) {
        return dispatch(
          addAlert(
            `Выбранный товар не может быть добавлен в текущий заказ. Разместите ${product.name} в отдельный заказ.`,
            'warning',
          ),
        );
      }

      return handleInputChange(
        Number(event?.target?.value.replace(/\D/, '')),
      );
    },
    [
      dispatch,
      product.name,
      blockedCounterFnSetWithOfd,
      blockedCounterFnWithKey,
      handleInputChange,
    ],
  );

  const handleClick = useCallback(() => {
    if (blockedCounterFnSetWithOfd || blockedCounterFnWithKey) {
      return dispatch(
        addAlert(
          `Выбранный товар не может быть добавлен в текущий заказ. Разместите ${product.name} в отдельный заказ.`,
          'warning',
        ),
      );
    }

    return increaseCount();
  }, [
    dispatch,
    product.name,
    blockedCounterFnSetWithOfd,
    blockedCounterFnWithKey,
    increaseCount,
  ]);

  return (
    <div className={styles['product-counter']}>
      <div className={styles.decrement}>
        <button
          type="button"
          data-testid="product-counter-btn-1"
          disabled={orderController.currentProductQuantity === 0}
          onClick={decreaseCount}
          className={`${styles.btn} decrement-btn`}
        >
          <img src={decrement} alt="minus" />
        </button>
      </div>

      <div className={styles.score}>
        <input
          data-testid="product-counter-input-1"
          type="text"
          value={orderController.currentProductQuantity}
          onInput={handleChange}
        />
      </div>

      <div className={styles.increment}>
        <button
          type="button"
          data-testid="product-counter-btn-2"
          onClick={handleClick}
          className={`${styles.btn} increment-btn`}
        >
          <img src={increment} alt="plus" />
        </button>
      </div>
    </div>
  );
});

ProductCounter.displayName = 'ProductCounter';

export default ProductCounter;

ProductCounter.propTypes = {
  product: PropTypes.shape({
    price: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.number,
    quantity: PropTypes.number,
  }).isRequired,
};
