import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { Tab } from 'pages/tab';

import getProductInfo from 'services/actions/product-card-info';

import Aside from 'components/aside/aside';
import ContentLoader from 'components/content-loader/content-loader';
import ProductsCard from 'components/products-card/products-card';

import { useProducts } from 'shared/hooks/use-products.hook';
import { metrika } from 'shared/utils/metrika.util';
import { tokens } from 'shared/utils/tokens.util';

import leftArr from 'images/icons/left-arr.svg';

import { getfileBodyUrl } from '../urls';
import styles from './product-card.module.scss';

const ProductCard = () => {
  const { id } = useParams();

  const [checkTab, setCheckTab] = useState('1');
  const [documents, setDocuments] = useState([]);
  const isAuth = tokens.getAccess();
  const { currentProduct } = useProducts();

  const { order } = useSelector((store) => store.order);
  const { document } = useSelector((store) => store.document);
  const { docTypes } = useSelector((store) => store.docs);
  const { description } = useSelector((store) => store.description);
  const { characteristic } = useSelector(
    (store) => store.characteristic,
  );

  const dispatch = useDispatch();

  const productDescription = useMemo(() => {
    if (!description?.length || !id) return '';
    const currentDescription = description?.find((item) =>
      Object.keys(item).includes(id),
    );
    return currentDescription?.[id] || '';
  }, [description, id]);

  const productCharacteristic = useMemo(() => {
    if (!characteristic?.length || !id) return '';
    const currentCharacteristic = characteristic?.find((item) =>
      Object.keys(item).includes(id),
    );
    return currentCharacteristic?.[id] || '';
  }, [characteristic, id]);

  const handleClickTab = useCallback((e) => {
    const curTab = e.target.getAttribute('data-tab');
    if (curTab) setCheckTab(curTab);
  }, []);

  useEffect(() => {
    if (!document.length || !id) return;

    const arr = [];

    document?.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (key === id) {
          arr.push(item[key]);
        }
      });
    });
    setDocuments(arr);
  }, [id, document]);

  useEffect(() => {
    if (!isAuth || !currentProduct) return;

    metrika.sendProductsStat({
      type: 'detail',
      products: [
        {
          id: currentProduct.id,
          name: currentProduct.name,
          price: currentProduct.price,
        },
      ],
    });
  }, [isAuth, currentProduct]);

  useEffect(() => {
    if (!docTypes.length || !id) return;

    docTypes.forEach((docType) => {
      dispatch(getProductInfo(id, docType.id));
    });
  }, [dispatch, docTypes, id]);

  return (
    <div className="product-card mt-4 mb-4">
      <Container>
        <div className="wrap">
          <div className="return">
            <Link
              to="/products"
              data-testid="product-card-link-1"
              className="d-flex align-items-center text-decoration-none"
            >
              <img src={leftArr} alt="" />

              <span className={`${styles['return-link']} ms-2`}>
                Вернуться к списку товаров
              </span>
            </Link>
          </div>

          <Row>
            <Col
              xs={12}
              lg={Boolean(order?.products.length ? 9 : 12)}
            >
              <div className="mt-4 product">
                {Boolean(currentProduct) && (
                  <ProductsCard
                    product={currentProduct}
                    counter
                    availableStatus={currentProduct.availableStatus}
                    indicator
                    imageUrl={getfileBodyUrl}
                    photo={currentProduct?.images}
                  />
                )}
              </div>

              <div
                className="mt-4 description"
                onClick={handleClickTab}
                aria-hidden="true"
              >
                <div
                  className={`${styles.tabs} d-flex align-items-center`}
                >
                  <div
                    data-tab="1"
                    className={`${styles.tab} ${
                      checkTab === '1' ? styles['active-tab'] : ''
                    }`}
                  >
                    Описание
                  </div>

                  <div
                    data-tab="2"
                    className={`${styles.tab} ${
                      checkTab === '2' ? styles['active-tab'] : ''
                    }`}
                  >
                    Характеристики
                  </div>

                  <div
                    data-tab="3"
                    className={`${styles.tab} ${
                      checkTab === '3' ? styles['active-tab'] : ''
                    }`}
                  >
                    Документация
                  </div>
                </div>

                <div className={`${styles['main-desc']} mt-3`}>
                  {description?.length ||
                  characteristic?.length ||
                  document.length ? (
                    <Tab
                      tabName={checkTab}
                      documents={documents}
                      description={productDescription}
                      characteristic={productCharacteristic}
                    />
                  ) : (
                    <ContentLoader placeholderType="text" />
                  )}
                </div>
              </div>
            </Col>

            {Boolean(order?.products.length) && (
              <Col className="d-none d-lg-block" lg={3}>
                <Aside />
              </Col>
            )}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ProductCard;
