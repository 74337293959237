import {
  ADD_ALERT,
  DELL_ALERT,
  DELL_ALERT_BY_TEXT,
} from 'services/actions/actionTypes';

interface Alert {
  text: string;
  variant: string;
  lifetime: number;
}

export interface IPageAlertsState {
  pageAlerts: Alert[];
}

type PageAlertAction =
  | {
      type: typeof ADD_ALERT;
      data: string;
      variant: string;
      lifetime?: number;
    }
  | { type: typeof DELL_ALERT; index: number }
  | { type: typeof DELL_ALERT_BY_TEXT; text: string };

const initialState: IPageAlertsState = {
  pageAlerts: [],
};

const pageAlertsReducer = (
  state = initialState,
  action: PageAlertAction,
) => {
  switch (action.type) {
    case ADD_ALERT: {
      window.scrollTo(0, 0);

      const alerts = [
        ...state.pageAlerts,
        {
          text: action.data,
          variant: action.variant,
          lifetime: action?.lifetime,
        },
      ];

      const uniqueAlertArray = [
        ...new Map(alerts.map((item) => [item.text, item])).values(),
      ];

      return {
        ...state,
        pageAlerts: uniqueAlertArray,
      };
    }
    case DELL_ALERT: {
      const pageAlertsNew = state.pageAlerts.slice();
      pageAlertsNew.splice(action.index, 1);
      return {
        ...state,
        pageAlerts: pageAlertsNew,
      };
    }
    case DELL_ALERT_BY_TEXT: {
      return {
        ...state,
        pageAlerts: state.pageAlerts.filter(
          (alert) => alert.text !== action.text,
        ),
      };
    }
    default: {
      return state;
    }
  }
};

export default pageAlertsReducer;
