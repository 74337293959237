export const PICKUP_ADRESS = `г. Москва, 4-я Кабельная ул., 6А 
(МЕТРО «Авиамоторная» Склад ООО «БетаПРО»)`;

export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' +
        // eslint-disable-next-line
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        // eslint-disable-next-line
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function currency(value) {
  const string = Number(value).toFixed(2);

  const parts = string.split('.');
  parts[0] = parts[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    .reverse()
    .map((item) => item.split('').reverse().join(''))
    .join(' ');
  return parts.join('.');
}

export function currencyInt(value) {
  const number = Number(value);
  const hasDecimals = number % 1 !== 0;
  const string = hasDecimals ? number.toFixed(2) : String(number);
  const parts = string.split('.');

  parts[0] = parts[0]
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    .reverse()
    .map((item) => item.split('').reverse().join(''))
    .join(' ');

  return hasDecimals ? parts.join('.') : parts[0];
}

export const emailRegExp =
  // eslint-disable-next-line
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
export const telephoneRegExp =
  // eslint-disable-next-line
  /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

export function dateFormatter(date, type) {
  const fullDate = new Date(date);

  let day = fullDate.getDate();
  day = day.toString().length > 1 ? day : `0${day}`;

  let month = fullDate.getMonth() + 1;
  month = month.toString().length > 1 ? month : `0${month}`;

  let hours = fullDate.getHours();
  hours = hours.toString().length > 1 ? hours : `0${hours}`;

  const min =
    fullDate.getMinutes().toString().length > 1
      ? fullDate.getMinutes()
      : `0${fullDate.getMinutes()}`;

  switch (type) {
    case 'full':
      return `${day}.${month}.${fullDate.getFullYear()} ${hours}:${min}`;

    case 'dd.mm.yyyy':
      return `${day}.${month}.${fullDate.getFullYear()}`;

    case 'time':
      return `${hours}:${min}`;

    case 'yyyymmdd':
      return `${fullDate.getFullYear()}${month}${day}`;

    default:
      break;
  }

  return false;
}

export const getFileName = (contentDisposition) => {
  let fileName;
  try {
    const fileNameMatch = contentDisposition
      .split(';')[1]
      .split('=')[1]
      .replaceAll('"', '')
      .replaceAll("'", '')
      .replace('UTF-8', '');
    if (fileNameMatch) fileName = decodeURI(fileNameMatch);
  } catch (e) {
    fileName = 'file';
  }
  return fileName;
};

export const b64DecodeUnicode = (b64Data) => {
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  return new TextDecoder().decode(byteArray);
};

export const saveDoc = (paramObj) => {
  const { response, fileType, defaultFileName } = paramObj;
  const blob = new Blob([response.data], { type: fileType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const contentDisposition = response.headers['content-disposition'];

  link.download = contentDisposition
    ? getFileName(contentDisposition)
    : defaultFileName;
  link.click();
};

export const withKeyHasBlocked = (order, product) =>
  order?.products?.some(
    (item) =>
      item.type === 'SET' &&
      item.id !== product.id &&
      (product.type === 'SET' ||
        product.type === 'KIT' ||
        product.type === 'FN'),
  );

export const withSetHasBlocked = (order, product) =>
  order?.products?.some(
    (item) =>
      product.type === 'SET' &&
      (item.type === 'KIT' || item.type === 'FN'),
  );
