import { SUCCESS_ORDER } from 'services/actions/actionTypes';

const initialState = {
  successOrder: false,
};

const successOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUCCESS_ORDER: {
      return {
        ...state,
        successOrder: action.successOrder,
      };
    }

    default: {
      return state;
    }
  }
};

export default successOrderReducer;
