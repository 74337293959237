import PropTypes from 'prop-types';
import { memo } from 'react';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';

import { delAlert } from 'services/actions/page-alerts';

import styles from './page-alert.module.css';

const PageAlert = memo(({ text, variant, index }) => {
  const dispatch = useDispatch();

  const onClose = () => dispatch(delAlert(index));

  return (
    <Alert
      data-testid={`alert-${text}`}
      className={styles['fn-alert']}
      variant={variant}
      onClose={onClose}
      dismissible
    >
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </Alert>
  );
});

PageAlert.displayName = 'PageAlert';

export default PageAlert;

PageAlert.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
