import classname from 'classnames/bind';
import React, { FC, memo, useCallback, useState } from 'react';

import ContentLoader from 'components/content-loader/content-loader';
import { HonestSign } from 'components/products-card/honestSign';

import {
  FileProduct,
  ProductResponse,
} from 'shared/models/products.model';

import styles from './image-slider.module.css';
import { ProductImage } from './productImage';

const cx = classname.bind(styles);

interface IImageSliderProps {
  photo: FileProduct[];
  classNameImg: string;
  isBig: boolean;
  product: ProductResponse;
}

const ImageSlider: FC<IImageSliderProps> = memo((props) => {
  const { photo, classNameImg, isBig, product } = props;
  const [slideIndex, setSlideIndex] = useState(0);

  const moveDot = useCallback(
    (index: number, e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setSlideIndex(index);
    },
    [],
  );

  if (photo?.length === 0) {
    return <ContentLoader placeholderType="image-big" />;
  }

  return (
    <div
      className={cx({
        'container-big': isBig,
        'container-min': !isBig,
      })}
    >
      <div className={cx('honest')}>
        <HonestSign product={product} />
      </div>

      {photo?.map((item, index) => (
        <ProductImage
          key={item.fileId}
          alt={item.fileName}
          classNameImg={classNameImg}
          classNameContainer={cx('slide', {
            'active-slide': slideIndex === index,
          })}
          fileUrl={item.fileUrl}
        />
      ))}

      <div className={styles.pagination}>
        {photo.map((item, index) => (
          <button
            aria-label="Image switch"
            type="button"
            key={item.fileId}
            className={cx('dot', {
              'active-dot': slideIndex === index,
            })}
            onClick={(e) => moveDot(index, e)}
          />
        ))}
      </div>
    </div>
  );
});

ImageSlider.displayName = 'ImageSlider';

export default ImageSlider;
