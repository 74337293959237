import { Dispatch } from 'redux';

import { AUTH_FORM_TYPES, CONTENT } from 'shared/const';
import { IError } from 'shared/models/simple-response.model';
import { IUserCredentials } from 'shared/models/user.model';
import httpClient from 'shared/utils/httpClient/httpClient';
import { tokens } from 'shared/utils/tokens.util';
import { authentication, getUserType } from 'shared/utils/user.util';

import {
  AUTH_FORM_TYPE,
  AUTH_PASSWORD_ERROR,
  AUTH_USER_TYPE,
  CLEAR_ORG,
} from './actionTypes';
import showLoader from './loader';
import { addAlert } from './page-alerts';

const ONE_MINUTE = 1000 * 60;

type TAuthFormType =
  typeof AUTH_FORM_TYPES[keyof typeof AUTH_FORM_TYPES];

export const setUserType = (data: string | null) => ({
  type: AUTH_USER_TYPE,
  data,
});

export const loginUser =
  (postData: IUserCredentials) => async (dispatch: Dispatch) => {
    dispatch(showLoader(true));
    try {
      await httpClient.getTokens(postData);
      dispatch(setUserType(getUserType()));
    } catch (error) {
      let message;
      let messageType;

      const err = error as IError;

      if (err?.response?.status === 401) {
        dispatch(
          changePasswordError(CONTENT.auth.login.badCredentials),
        );
        return;
      }

      if (err?.response?.status === 405) {
        message = err.response.data.message;
        messageType = 'warning';
        dispatch(addAlert(message, messageType, ONE_MINUTE));
        return;
      }

      message = err.response.data.message;
      messageType = 'danger';
      dispatch(addAlert(message, messageType));
    } finally {
      dispatch(showLoader(false));
    }
  };

export const logOutUser = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAR_ORG,
  });
  const url = new URL(window.location.href);
  if (url.searchParams.get('id')) window.location.href = '/';
  tokens.removeAll();
  authentication.clear();
  dispatch(setUserType(null));
};

export const changeAuthFormType = (fromType: TAuthFormType) => ({
  type: AUTH_FORM_TYPE,
  data: fromType,
});

export const changePasswordError = (errorMsg: string) => ({
  type: AUTH_PASSWORD_ERROR,
  data: errorMsg,
});
