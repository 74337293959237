import { FC, memo } from 'react';

import styles from './progress-line.module.css';

interface IProgressLineProps {
  theme: string;
  percent?: number;
}

const ProgressLine: FC<IProgressLineProps> = memo(
  ({ theme, percent }) => {
    const backColorTheme = theme;
    const currentPercent = 100 - percent!;

    return (
      <div className={styles.main}>
        <div className={`${styles.back} ${styles[backColorTheme]}`} />
        {theme === 'error' && (
          <div
            className={`${styles.progress} ${styles.success}`}
            style={{ width: `${currentPercent}%` }}
          />
        )}
      </div>
    );
  },
);

ProgressLine.displayName = 'ProgressLine';

export default ProgressLine;
