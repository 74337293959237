import httpClient from 'shared/utils/httpClient/httpClient';

import { docsUrl } from '../../urls';
import {
  GET_DOCS,
  GET_DOCS_FAILED,
  GET_DOCS_SUCCESS,
} from './actionTypes';
import { logOutUser } from './auth';
import showLoader from './loader';
import { addAlert } from './page-alerts';

const getDocs = () => async (dispatch) => {
  dispatch({ type: GET_DOCS });
  dispatch(showLoader(true));
  try {
    const { data } = await httpClient.get(docsUrl);
    dispatch({ type: GET_DOCS_SUCCESS, docs: data });
    return data;
  } catch (error) {
    dispatch({ type: GET_DOCS_FAILED });
    if (error?.response?.status === 401) {
      return dispatch(logOutUser());
    }
    dispatch(addAlert('Ошибка получения документов', 'danger'));
    return false;
  } finally {
    dispatch(showLoader(false));
  }
};

export default getDocs;
