import { memo } from 'react';
import ReactDOM from 'react-dom';

import LoaderSpinner from 'components/loader-spinner/loader-spinner';
import Overlay from 'components/overlay/overlay';

import styles from './loader.module.css';

const loaderRoot = document.getElementById('loader');

const Loader = memo(() =>
  ReactDOM.createPortal(
    <>
      <div className={styles.loader}>
        <LoaderSpinner customClassName={styles.spinner} />
      </div>
      <Overlay onClose={null} />
    </>,
    loaderRoot,
  ),
);

Loader.displayName = 'Loader';

export default Loader;
