import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { logOutUser } from 'services/actions/auth';
import checkDocFlow from 'services/actions/check-doc-flow';
import checkEdo from 'services/actions/check-edo';
import checkOffer from 'services/actions/check-offer';
import {
  setDeliveryAddressError,
  setDeliveryData,
  setDeliveryFormReady,
  setTariffLoading,
} from 'services/actions/delivery';
import showLoader from 'services/actions/loader';
import showModal from 'services/actions/modal';
import { addAlert } from 'services/actions/page-alerts';
import getProducts from 'services/actions/products';
import setSuccessOrder from 'services/actions/success-order';

import AutocompleteList from 'components/autocomplete-list/autocomplete-list';
import CustomInput from 'components/custom-input/custom-input';
import CustomSelect from 'components/custom-select';
import SwitchTabs from 'components/switch-tabs/switch-tabs';

import { USER_TYPES } from 'shared/const/user.const';
import httpClient from 'shared/utils/httpClient/httpClient';
import { metrika } from 'shared/utils/metrika.util';
import { tokens } from 'shared/utils/tokens.util';

import bagIcon from 'images/icons/bag.svg';
import linkIcon from 'images/icons/transition.svg';
import truckIcon from 'images/icons/truck.svg';

import {
  createOrderUrl,
  dadataUrl,
  deliveryUrl,
  listOrdersUrl,
} from '../../urls';
import { PICKUP_ADRESS, currency } from '../../utils';
import styles from './order-delivery.module.css';
import { checkPhone, hasLength } from './order-delivery.utils';

const inputMask = (e) => {
  if (
    e.target.value.substring(0, 2) === '+7' ||
    e.target.value.substring(0, 1) === '7' ||
    (e.target.value.substring(0, 1) === '8' &&
      e.target.value.substring(0, 3) !== '800')
  ) {
    const patternForFullTelephone =
      /(\+7|8|7)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    const correctNumber = e.target.value.replace(
      patternForFullTelephone,
      '+7 ($2) $3-$4-$5',
    ); // пробуем замену если есть  7 8 +7
    // Исключение для этого правила, потому что иначе валидация не работает
    // eslint-disable-next-line no-return-assign
    return (e.target.value = correctNumber);
  }
  if (
    e.target.value.substring(0, 1) === '9' ||
    e.target.value.substring(0, 3) === '800'
  ) {
    const patternForTelephone =
      /[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
    const correctNumber = e.target.value.replace(
      patternForTelephone,
      '($1) $2-$3-$4',
    ); // пробуем замену если нет  7 8 +7
    // Исключение для этого правила, потому что иначе валидация не работает
    // eslint-disable-next-line no-return-assign
    return (e.target.value = `+7 ${correctNumber}`);
  }

  return false;
};

const hideListDelay = 200;

const orderBodyTemplate = {
  orderInfo: {
    contactName: '',
    contactPhone: '',
    contactEmail: '',
    comment: '',
    fiasId: '',
    vendorId: '',
    vendorName: '',
    urgentTypeId: '',
    tariffName: '',
    insurance: '',
    period: '',
    address: '',
    addressFiasId: '',
    cityFiasId: '',
    settlementFiasId: '',
    regionFiasId: '',
    country: '',
    countryIsoCode: '',
    postalCode: '',
    region: '',
    regionType: '',
    city: '',
    settlement: '',
  },
  orderLines: [],
};

const OrderDelivery = memo(
  ({
    changeDeliveryPriceOrder,
    startOrder,
    startOrderOff,
    aside,
    onOrderLoaderFlagToggle,
  }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuth = tokens.getAccess();

    const { order } = useSelector((store) => store.order);

    const {
      deliveryData,
      isTariffLoading,
      isDeliveryFormWasEdit,
      isDeliveryAddressError,
    } = useSelector((store) => store.deliveryData);

    const isEdoFlow = useSelector(
      (store) => store.isEdoFlow.isEdoFlow,
    );

    const isEdoRegistered = useSelector(
      (store) => store.isEdo.isEdoRegistered,
    );

    const isOfferAccepted = useSelector(
      (store) => store.isOffer.isOfferAccepted,
    );

    const { userType } = useSelector((store) => store.auth);

    const [list, setList] = useState([]);
    const [focusAddress, setFocusAddress] = useState(false);
    const [tariffErrorText, setTariffErrorText] = useState('');
    const [addressErrorText, setAddressErrorText] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [listShow, setListShow] = useState(false);
    const [searchAddress, setSearchAddress] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    const [nameError, setNameError] = useState(false);
    const [phoneErrorText, setPhoneErrorText] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [isLoadingAdres, setIsLoadingAdres] = useState(false);

    const isPartner = useMemo(
      () => userType === USER_TYPES.PK,
      [userType],
    );

    const clearPhoneError = useCallback(() => {
      setPhoneError(false);
      setPhoneErrorText('');
    }, []);

    const clearNameError = useCallback(() => {
      setNameError(false);
      setNameErrorText('');
    }, []);

    const clearAddressError = useCallback(() => {
      setAddressError(false);
      setAddressErrorText('');
    }, []);

    const clearTariffError = useCallback(() => {
      setTariffErrorText('');
    }, []);

    const clearErrors = useCallback(() => {
      clearPhoneError();
      clearNameError();
      clearAddressError();
      clearTariffError();
    }, [
      clearAddressError,
      clearNameError,
      clearPhoneError,
      clearTariffError,
    ]);

    const changeComments = useCallback(
      (e) => {
        deliveryData.comments = e.target.value;
        dispatch(setDeliveryData(deliveryData));
      },
      [deliveryData, dispatch],
    );

    const checkName = useCallback(
      () => hasLength(deliveryData.contactName),
      [deliveryData.contactName],
    );

    const checkPhoneLength = useCallback(
      () => hasLength(deliveryData.contactPhone),
      [deliveryData.contactPhone],
    );

    const checkRegExpPhoneNumber = useCallback(
      () => checkPhone(deliveryData.contactPhone),
      [deliveryData.contactPhone],
    );

    const checkAddress = useCallback(
      () => deliveryData.fiasId !== null,
      [deliveryData.fiasId],
    );

    const checkTariff = useCallback(
      () => deliveryData.tariff !== null,
      [deliveryData.tariff],
    );

    const changeName = (e) => {
      clearNameError();
      deliveryData.contactName = e.target.value;
      dispatch(setDeliveryData(deliveryData));
    };

    const changePhone = (e) => {
      clearPhoneError();
      inputMask(e);
      deliveryData.contactPhone = e.target.value;
      dispatch(setDeliveryData(deliveryData));
    };

    const clearTariffs = useCallback(() => {
      deliveryData.deliveryList = [];
      deliveryData.tariff = null;
      deliveryData.deliveryPrice = 0;
      dispatch(setDeliveryData(deliveryData));
    }, [deliveryData, dispatch]);

    const hideList = useCallback(() => {
      setList([]);
      setFocusAddress(false);
      setSearchAddress(false);
    }, []);

    const hideListAfterDelay = useCallback(() => {
      setTimeout(() => {
        hideList();
      }, hideListDelay);
    }, [hideList]);

    const changeCurrentAddress = useCallback(
      (e) => {
        clearAddressError();
        setSearchAddress(true);
        deliveryData.tariff = null;
        deliveryData.deliveryList = [];
        deliveryData.currentAddress = e.target.value;
        dispatch(setDeliveryData(deliveryData));
      },
      [clearAddressError, deliveryData, dispatch],
    );

    const changeCurrentAddressWithoutSearching = useCallback(
      (e) => {
        setFocusAddress(true);
        deliveryData.currentAddress = e.target.value;
        dispatch(setDeliveryData(deliveryData));
      },
      [deliveryData, dispatch],
    );

    const changeCurrentAddressFromSuggest = useCallback(
      (item) => {
        if (item) {
          clearAddressError();
          deliveryData.start = true;
          deliveryData.currentAddress = item.value;
          deliveryData.fiasId = item.data.fiasId;
          deliveryData.dadataInfo = item.data;
          dispatch(setDeliveryData(deliveryData));
          hideList();
        }
      },
      [clearAddressError, deliveryData, dispatch, hideList],
    );

    const changeTariff = useCallback(
      (e) => {
        const tariff = deliveryData.deliveryList.filter(
          (item) => item.urgentTypeId === e,
        )[0];
        clearTariffError();
        deliveryData.tariff = tariff;
        deliveryData.start = false;
        deliveryData.deliveryPrice = tariff.price;
        dispatch(setDeliveryData(deliveryData));
      },
      [clearTariffError, deliveryData, dispatch],
    );

    const validateAddress = useCallback(() => {
      let isValid;

      if (deliveryData.deliveryType === 'Доставка') {
        isValid = checkAddress();
      } else {
        isValid = true;
      }

      if (!isValid) {
        setAddressErrorText(
          'Введите  адрес или ближайший город\n' +
            '(с Вами свяжется оператор)',
        );
      }

      setAddressError(!isValid);

      return isValid;
    }, [deliveryData.deliveryType, checkAddress]);

    const validateName = useCallback(() => {
      const isValid = checkName();
      if (!isValid) setNameErrorText('Введите контактное лицо');
      setNameError(!isValid);
      return isValid;
    }, [checkName]);

    const validateTariff = useCallback(() => {
      let isValid;

      if (deliveryData.deliveryType === 'Доставка') {
        isValid = checkTariff();
      } else {
        isValid = true;
      }

      if (!isValid) {
        setTariffErrorText('Выберите тариф');
      }
      return isValid;
    }, [deliveryData.deliveryType, checkTariff]);

    const validatePhone = useCallback(() => {
      if (!checkPhoneLength()) {
        setPhoneError(true);
        setPhoneErrorText('Введите номер телефона');
        return false;
      }

      const isValid = checkPhoneLength() && checkRegExpPhoneNumber();
      if (!isValid) setPhoneErrorText('Введите корректный номер');
      setPhoneError(!isValid);

      return isValid;
    }, [checkPhoneLength, checkRegExpPhoneNumber]);

    const validateOrder = useCallback(() => {
      const isPasswordValid = validateName();
      const isLoginValid = validatePhone();
      const isAddressValid = validateAddress();
      const isTariffValid = isPartner ? validateTariff() : true;

      return (
        isLoginValid &&
        isPasswordValid &&
        isAddressValid &&
        isTariffValid
      );
    }, [
      isPartner,
      validateAddress,
      validateName,
      validatePhone,
      validateTariff,
    ]);

    const formatPhoneForOrder = useCallback(() => {
      const phoneOnlyNumber = deliveryData.contactPhone.replace(
        /\D+/g,
        '',
      );
      const phoneOnlyNumberArray = phoneOnlyNumber.split('');
      return (
        '8' +
        `-${phoneOnlyNumberArray[1]}${phoneOnlyNumberArray[2]}${phoneOnlyNumberArray[3]}-${phoneOnlyNumberArray[4]}${phoneOnlyNumberArray[5]}${phoneOnlyNumberArray[6]}${phoneOnlyNumberArray[7]}-${phoneOnlyNumberArray[8]}${phoneOnlyNumberArray[9]}${phoneOnlyNumberArray[10]}`
      );
    }, [deliveryData.contactPhone]);

    const fillOrderBody = useCallback(() => {
      const orderBodyTemplateCopy = { ...orderBodyTemplate };
      orderBodyTemplateCopy.orderInfo.contactName =
        deliveryData.contactName;
      orderBodyTemplateCopy.orderInfo.contactPhone =
        formatPhoneForOrder();
      orderBodyTemplateCopy.orderInfo.comment = deliveryData.comments;
      orderBodyTemplateCopy.orderInfo.fiasId = deliveryData.fiasId;
      if (
        deliveryData.deliveryType === 'Доставка' &&
        deliveryData.tariff &&
        deliveryData.dadataInfo
      ) {
        orderBodyTemplateCopy.orderInfo.vendorId =
          deliveryData.tariff.vendorId;
        orderBodyTemplateCopy.orderInfo.vendorName =
          deliveryData.tariff.vendorName;
        orderBodyTemplateCopy.orderInfo.urgentTypeId =
          deliveryData.tariff.urgentTypeId;
        orderBodyTemplateCopy.orderInfo.tariffName =
          deliveryData.tariff.tariffName;
        orderBodyTemplateCopy.orderInfo.insurance =
          deliveryData.tariff.insurance;
        orderBodyTemplateCopy.orderInfo.period =
          deliveryData.tariff.period;
        orderBodyTemplateCopy.orderInfo.address =
          deliveryData.currentAddress;
        orderBodyTemplateCopy.orderInfo.addressFiasId =
          deliveryData.dadataInfo.fiasId;
        orderBodyTemplateCopy.orderInfo.cityFiasId =
          deliveryData.dadataInfo.cityFiasId;
        orderBodyTemplateCopy.orderInfo.settlementFiasId =
          deliveryData.dadataInfo.settlementFiasId;
        orderBodyTemplateCopy.orderInfo.regionFiasId =
          deliveryData.dadataInfo.regionFiasId;
        orderBodyTemplateCopy.orderInfo.country =
          deliveryData.dadataInfo.country;
        orderBodyTemplateCopy.orderInfo.countryIsoCode =
          deliveryData.dadataInfo.countryIsoCode;
        orderBodyTemplateCopy.orderInfo.postalCode =
          deliveryData.dadataInfo.postalCode;
        orderBodyTemplateCopy.orderInfo.region =
          deliveryData.dadataInfo.region;
        orderBodyTemplateCopy.orderInfo.regionType =
          deliveryData.dadataInfo.regionType;
        orderBodyTemplateCopy.orderInfo.city =
          deliveryData.dadataInfo.city;
        orderBodyTemplateCopy.orderInfo.settlement =
          deliveryData.dadataInfo.settlement;
      }
      orderBodyTemplateCopy.orderLines = order?.products.map(
        (product) => ({
          productId: product.id,
          quantity: product.quantity,
        }),
      );
      return orderBodyTemplateCopy;
    }, [deliveryData, formatPhoneForOrder, order?.products]);

    const putOrder = useCallback(
      async (url, data) => {
        dispatch(showLoader(true));
        try {
          await httpClient.post(url, data);
          navigate('/orders');
          dispatch(setSuccessOrder(true));
          dispatch(
            addAlert(
              'Ваш заказ принят. Счет будет доступен для скачивания в детализации по заказу на странице История.',
              'success',
            ),
          );

          const orders = await httpClient.get(listOrdersUrl);

          metrika.sendPurchase({
            actionFieldID: orders?.data?.[0]?.orderId,
            products: [
              ...order.products.map((product) => ({
                id: product.id.toString(),
                name: product.name,
                price: product.price,
                quantity: product.quantity,
              })),
              {
                id: '1',
                name: 'Доставка',
                price:
                  deliveryData.tariff === null
                    ? currency(0)
                    : currency(deliveryData.deliveryPrice),
              },
            ],
          });
        } catch (error) {
          switch (error?.response?.status) {
            case 401:
              startOrderOff();
              dispatch(logOutUser());
              break;
            case 400:
              startOrderOff();
              dispatch(
                addAlert(
                  'Часть товаров в корзине закончилась, проверьте пожалуйста корзину',
                  'danger',
                ),
              );
              break;
            case 409:
              startOrderOff();
              dispatch(
                addAlert(
                  'Ошибка создания заказа, попробуйте еще раз',
                  'danger',
                ),
              );
              break;
            default:
              startOrderOff();
              dispatch(
                addAlert(
                  'Ошибка создания заказа, попробуйте еще раз',
                  'danger',
                ),
              );
              break;
          }
        } finally {
          onOrderLoaderFlagToggle(false);
          dispatch(showLoader(false));
        }
      },
      [
        deliveryData.deliveryPrice,
        deliveryData.tariff,
        dispatch,
        navigate,
        onOrderLoaderFlagToggle,
        order,
        startOrderOff,
      ],
    );

    const createOrder = useCallback(async () => {
      dispatch(showLoader(true));
      const data = fillOrderBody();

      let url = createOrderUrl;

      url +=
        deliveryData.deliveryType === 'Доставка'
          ? `?delivery=true&price=${currency(
              deliveryData.deliveryPrice,
            )}`
          : '?delivery=false';

      if (
        (!isEdoFlow && isOfferAccepted) ||
        (isEdoFlow && isEdoRegistered && isOfferAccepted)
      ) {
        dispatch(getProducts()).then(() => putOrder(url, data));
      } else {
        let isByEdo = false;
        let isEdo = false;
        let isOffer = false;

        dispatch(checkDocFlow()).then((checkDocFlowRes) => {
          isByEdo = checkDocFlowRes;
          return dispatch(checkEdo());
        });

        dispatch(checkDocFlow()).then((checkEdoRes) => {
          isEdo = checkEdoRes;
          return dispatch(checkOffer());
        });

        dispatch(checkDocFlow()).then((checkOfferRes) => {
          isOffer = checkOfferRes;
          return true;
        });

        if ((!isByEdo && isOffer) || (isByEdo && isEdo && isOffer)) {
          dispatch(getProducts()).then(() => putOrder(url, data));
          putOrder(url, data);
        } else {
          dispatch(showModal(true));
        }
      }
      dispatch(showLoader(false));
    }, [
      deliveryData.deliveryPrice,
      deliveryData.deliveryType,
      dispatch,
      fillOrderBody,
      isEdoFlow,
      isEdoRegistered,
      isOfferAccepted,
      putOrder,
    ]);

    const getDelivery = useCallback(
      async (counter) => {
        let counterReducer;
        clearTariffs();

        dispatch(setTariffLoading(true));

        const count = order?.products.reduce(
          (sum, current) => sum + current.quantity,
          0,
        );

        try {
          const { data } = await httpClient.get(
            `${deliveryUrl}?cityId=${deliveryData.fiasId}&count=${count}&cost=${order.totalPrice}`,
          );
          deliveryData.deliveryList = data;
          deliveryData.start = false;
          if (!data.length) {
            setAddressError(true);
            setAddressErrorText(
              'Введите другой адрес или ближайший город\n' +
                '(с Вами свяжется оператор)',
            );
          }
          dispatch(setDeliveryData(deliveryData));
        } catch (error) {
          if (error?.response?.status === 401) {
            return dispatch(logOutUser());
          }
          if (counter > 0) {
            counterReducer = counter;
            counterReducer -= 1;
            setTimeout(getDelivery, 3000, counterReducer);
          }
          dispatch(
            addAlert(
              'Ошибка получения тарифов доставки, попробуйте позже',
              'danger',
            ),
          );
        } finally {
          dispatch(setTariffLoading(false));
        }
      },
      [
        clearTariffs,
        deliveryData,
        dispatch,
        order?.products,
        order?.totalPrice,
      ],
    );

    const handleFieldReset = useCallback(() => {
      deliveryData.deliveryPrice = 0;
      deliveryData.tariff = null;
      dispatch(setDeliveryData(deliveryData));
    }, [deliveryData, dispatch]);

    useEffect(() => {
      setListShow(
        Boolean(list.length) && (searchAddress || focusAddress),
      );
    }, [list, searchAddress, focusAddress]);

    useEffect(() => {
      if (searchAddress) {
        deliveryData.fiasId = null;
        deliveryData.dadataInfo = null;
        dispatch(setDeliveryData(deliveryData));
      }
    }, [searchAddress, deliveryData, dispatch]);

    useEffect(() => {
      if (changeDeliveryPriceOrder) {
        changeDeliveryPriceOrder(deliveryData.deliveryPrice);
      }
    }, [
      deliveryData.deliveryPrice,
      changeDeliveryPriceOrder,
      dispatch,
    ]);

    useEffect(() => {
      if (
        deliveryData.fiasId &&
        order?.totalPrice &&
        deliveryData.start
      ) {
        getDelivery(3);
      }
    }, [
      getDelivery,
      deliveryData.start,
      deliveryData.fiasId,
      order?.totalPrice,
      order?.products,
    ]);

    useEffect(() => {
      const getDadata = async () => {
        setIsLoadingAdres(true);
        try {
          const { data } = await httpClient.get(
            dadataUrl + deliveryData.currentAddress,
          );
          setList(data);
          dispatch(setDeliveryAddressError(false));
        } catch (error) {
          if (error?.response?.status === 401) {
            return dispatch(logOutUser());
          }
          dispatch(setDeliveryAddressError(true));
        } finally {
          setIsLoadingAdres(false);
        }
      };

      if (
        deliveryData.currentAddress.length >= 3 &&
        (searchAddress || focusAddress)
      ) {
        getDadata();
      } else {
        setList([]);
      }
    }, [
      deliveryData.currentAddress,
      searchAddress,
      focusAddress,
      dispatch,
    ]);

    useEffect(() => {
      if (!startOrder) return;

      clearErrors();

      if (validateOrder()) {
        createOrder();
      }

      onOrderLoaderFlagToggle(false);
      startOrderOff();
    }, [
      startOrder,
      clearErrors,
      createOrder,
      onOrderLoaderFlagToggle,
      startOrderOff,
      validateOrder,
    ]);

    useEffect(() => {
      if (!isDeliveryAddressError) return;
      setAddressError(true);
      setAddressErrorText(
        'Введите другой адрес или ближайший город\n' +
          '(с Вами свяжется оператор)',
      );
    }, [isDeliveryAddressError]);

    useEffect(() => {
      if (isPartner || !deliveryData.deliveryList.length) return;
      const minimalTariffID =
        deliveryData.deliveryList[0].urgentTypeId;
      changeTariff(minimalTariffID);
    }, [isPartner, deliveryData.deliveryList, changeTariff]);

    useEffect(() => {
      if (!isDeliveryFormWasEdit) return;

      const userCheckResult = checkName() && checkPhoneLength();

      const deliveryCheckResult =
        deliveryData.deliveryType === 'Доставка'
          ? checkAddress() && checkTariff()
          : true;

      const res = userCheckResult && deliveryCheckResult;

      dispatch(setDeliveryFormReady(res));
    }, [
      isDeliveryFormWasEdit,
      deliveryData.contactName,
      deliveryData.contactPhone,
      deliveryData.fiasId,
      deliveryData.tariff,
      deliveryData,
      checkAddress,
      checkName,
      checkPhoneLength,
      checkTariff,
      dispatch,
    ]);

    useEffect(() => {
      handleFieldReset();
    }, [userType, handleFieldReset]);

    return (
      <div
        className={
          `${styles['order-delivery']} d-flex flex-column ` +
          ` ${aside ? '' : styles['pl-2']}`
        }
      >
        <div className="head">
          <div className="head pb-4">
            <Row className="align-items-center">
              <Col xs={6}>
                <div className={styles.title}>
                  {deliveryData.deliveryType}
                </div>
              </Col>

              <Col xs={6}>
                <img
                  className={styles['img-size']}
                  src={
                    deliveryData.deliveryType === 'Доставка'
                      ? truckIcon
                      : bagIcon
                  }
                  alt=""
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="body flex-md-shrink-1 flex-md-grow-1">
          <div className="switch-tabs">
            <SwitchTabs />
          </div>

          <form action="">
            {isAuth && (
              <div>
                <Row>
                  <Col className="mt-4" xs={12} md={aside ? 12 : 6}>
                    <CustomInput
                      testId="order-del-1"
                      label="Контактное лицо"
                      placeholder={
                        deliveryData.deliveryType === 'Доставка'
                          ? 'Введите ФИО'
                          : 'ФИО или наименование ТК'
                      }
                      theme="edo"
                      type="text"
                      value={deliveryData.contactName}
                      onChange={changeName}
                      error={nameError ? nameErrorText : ''}
                    />
                  </Col>

                  <Col className="mt-4" xs={12} md={aside ? 12 : 6}>
                    <CustomInput
                      testId="order-del-2"
                      label="Контактный телефон"
                      placeholder="Введите номер телефона"
                      legacyMode={false}
                      theme="edo"
                      type="tel"
                      value={deliveryData.contactPhone}
                      maxLength={18}
                      onChange={(e) => changePhone(e)}
                      onFocus={(e) => {
                        if (
                          e.target.value === '' ||
                          e.target.value === '+7 '
                        ) {
                          e.target.value = '+7 ';
                        } else {
                          e.target.value = deliveryData.contactPhone;
                        }

                        return e.target.value;
                      }}
                      error={phoneError ? phoneErrorText : ''}
                    />
                  </Col>
                </Row>

                {deliveryData.deliveryType === 'Доставка' ? (
                  <div>
                    <Row>
                      <Col className="mt-4 position-relative">
                        <CustomInput
                          testId="order-del-3"
                          label="Адрес доставки"
                          placeholder="Введите адрес"
                          legacyMode={false}
                          theme="edo"
                          type="text"
                          value={deliveryData.currentAddress}
                          onChange={changeCurrentAddress}
                          onFocus={
                            changeCurrentAddressWithoutSearching
                          }
                          onBlur={hideListAfterDelay}
                          isLoading={isLoadingAdres}
                          withClearButton
                        />
                        <AutocompleteList
                          show={listShow}
                          list={list}
                          changeCurrentAddressFromSuggest={
                            changeCurrentAddressFromSuggest
                          }
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        {addressError && addressErrorText && (
                          <div className={styles.error}>
                            {addressErrorText}
                          </div>
                        )}
                      </Col>
                    </Row>

                    {isPartner && (
                      <Row>
                        <Col className="mt-4">
                          <CustomSelect
                            placeholder="Тариф доставки"
                            legacyMode={false}
                            options={deliveryData.deliveryList}
                            disabled={
                              !deliveryData.deliveryList.length
                            }
                            onChange={changeTariff}
                            onReset={handleFieldReset}
                            testId="order-del-1"
                            defValue={
                              deliveryData.tariff?.urgentTypeId || ''
                            }
                            isLoading={isTariffLoading}
                          />
                          {Boolean(tariffErrorText.length) && (
                            <p className={styles.error}>
                              Выберите тариф
                            </p>
                          )}
                        </Col>
                      </Row>
                    )}
                  </div>
                ) : (
                  <div className="mt-4">
                    <Row className="align-items-end">
                      <Col xs={12} md={aside ? 12 : 6}>
                        <div className={styles['col-name']}>
                          Адрес доставки
                        </div>
                        <div className={styles['col-desc']}>
                          {PICKUP_ADRESS}
                        </div>
                      </Col>

                      <Col
                        xs={12}
                        md={aside ? 12 : 6}
                        className="mt-3 mt-md-0 d-flex justify-content-md-end align-items-baseline"
                      >
                        <Link
                          to="/information#pickup"
                          data-testid="his-order-subitem-link-4"
                          className={styles.link}
                        >
                          Подробнее о самовывозе
                        </Link>

                        <div>
                          <img src={linkIcon} alt="" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            )}

            <div className={styles.block}>
              <div className="mt-3 position-relative">
                <label className={styles.label}>
                  Комментарии к заказу
                </label>

                <textarea
                  data-testid="order-del-textarea-1"
                  className={styles.textarea}
                  onInput={changeComments}
                  value={deliveryData.comments}
                />
              </div>
            </div>
          </form>

          {deliveryData.deliveryType === 'Доставка' && (
            <div
              className={`${styles['items-price']} mt-3 d-none d-md-flex justify-content-between align-items-baseline`}
            >
              <span className={styles['price-delivery']}>
                Цена доставки:
              </span>
              <span className={`${styles.price} ms-3`}>
                {deliveryData.tariff === null
                  ? currency(0)
                  : currency(deliveryData.deliveryPrice)}{' '}
                ₽
              </span>
            </div>
          )}
        </div>
      </div>
    );
  },
);

OrderDelivery.displayName = 'OrderDelivery';

export default OrderDelivery;
