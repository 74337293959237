import { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  IS_OFFER_ACCEPTED,
  IS_OFFER_ACCEPTED_SUCCESS,
} from 'services/actions/actionTypes';

import styles from '../modal-custom-edooffer/modal-custom-edooffer.module.css';
import OfferAcceptanceForm from './offer-acceptance-form';
import OfferAcceptanceReady from './offer-acceptance-ready';

const OfferAcceptance = memo(() => {
  const [isShowReady, setIsShowReady] = useState(false);

  const { isOfferAccepted } = useSelector((store) => store.isOffer);

  const dispatch = useDispatch();

  const handleShowReady = useCallback(() => {
    setIsShowReady(true);
    dispatch({
      type: IS_OFFER_ACCEPTED,
    });
    dispatch({
      type: IS_OFFER_ACCEPTED_SUCCESS,
      isOfferAccepted: true,
    });
  }, [dispatch]);

  if (isOfferAccepted && isShowReady) {
    return (
      <div className={styles['modal-col']}>
        <OfferAcceptanceReady />
      </div>
    );
  }

  if (!isOfferAccepted) {
    return (
      <div className={styles['modal-col']}>
        <OfferAcceptanceForm onShowReady={handleShowReady} />
      </div>
    );
  }

  return null;
});

OfferAcceptance.displayName = 'OfferAcceptance';

export default OfferAcceptance;

OfferAcceptance.propTypes = {};
