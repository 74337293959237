import { combineReducers } from 'redux';

import { IDeliveryState } from 'shared/models/delivery.model';
import { IOrderState } from 'shared/models/order.model';

import authReducer from './auth';
import docFlowReducer from './check-doc-flow';
import isEdoReducer from './check-edo';
import isOfferReducer from './check-offer';
import deliveryReducer from './delivery';
import docsReducer from './documents';
import loaderReducer from './loader';
import menuReducer from './menu-state';
import modalReducer from './modal';
import orderReducer from './order';
import orgReducer from './organization';
import pageAlertsReducer, { IPageAlertsState } from './page-alerts';
import productCardInfoReducer from './product-card-info';
import productsReducer from './products';
import successOrderReducer from './success-order';

const rootReducer = combineReducers({
  auth: authReducer,
  loader: loaderReducer,
  menu: menuReducer,
  pageAlerts: pageAlertsReducer,
  org: orgReducer,
  docs: docsReducer,
  products: productsReducer,
  document: productCardInfoReducer,
  description: productCardInfoReducer,
  characteristic: productCardInfoReducer,
  order: orderReducer,
  isEdoFlow: docFlowReducer,
  isEdo: isEdoReducer,
  isOffer: isOfferReducer,
  modal: modalReducer,
  deliveryData: deliveryReducer,
  successOrder: successOrderReducer,
});

export type TState = {
  order: IOrderState;
  deliveryData: IDeliveryState;
  pageAlerts: IPageAlertsState;
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
