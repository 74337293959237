import classNames from 'classnames/bind';
import { memo } from 'react';

import DropdownItem from 'components/dropdown-item/dropdown-item';

import styles from './dropdown-list.module.css';

const cx = classNames.bind(styles);

const DropdownList = memo(({ dropdownData }) =>
  dropdownData.map((item, index) => (
    <div key={item.href} className={cx('base-text')}>
      <DropdownItem
        testId={`dropdown-item-${index + 1}`}
        title={item.title}
        icon={item.icon}
        content={item.content}
        href={item.href}
      />
    </div>
  )),
);

DropdownList.displayName = 'DropdownList';

export default DropdownList;
