import httpClient from 'shared/utils/httpClient/httpClient';

import { orgUrl } from '../../urls';
import {
  GET_ORG,
  GET_ORG_FAILED,
  GET_ORG_SUCCESS,
} from './actionTypes';
import { logOutUser } from './auth';
import { addAlert } from './page-alerts';

const getOrg = () => async (dispatch) => {
  dispatch({
    type: GET_ORG,
  });
  try {
    const { data } = await httpClient.get(orgUrl);
    dispatch({
      type: GET_ORG_SUCCESS,
      org: data,
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_ORG_FAILED,
    });
    if (error?.response?.status === 401) {
      return dispatch(logOutUser());
    }
    dispatch(
      addAlert('Ошибка получения данных пользователя', 'danger'),
    );
    return false;
  }
};

export default getOrg;
