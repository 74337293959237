import cx from 'classnames';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDeliveryData } from 'services/actions/delivery';

import { Indicator } from 'components/indicator/indicator';
import ProductCounter from 'components/product-counter/product-counter';

import { PRODUCTS_STATUSES } from 'shared/const';
import { useOrder } from 'shared/hooks/use-order.hook';

import basketIconRed from 'images/icons/basket-red.svg';
import basketIcon from 'images/icons/basket.svg';

import { currencyInt } from '../../utils';
import styles from './order-item.module.css';

const OrderItem = memo(
  ({ product, price, qty, availableStatus, index }) => {
    const { deliveryData } = useSelector(
      (store) => store.deliveryData,
    );
    const dispatch = useDispatch();
    const orderController = useOrder(product);

    const deleteProduct = useCallback(() => {
      orderController.deleteProduct();
      deliveryData.start = true;
      dispatch(setDeliveryData(deliveryData));
    }, [deliveryData, dispatch, orderController]);

    return (
      <div className={`${styles.item} mt-3`}>
        <div
          className={cx('pb-3', {
            [styles.disabled]:
              availableStatus === PRODUCTS_STATUSES.NOT_FOR_ORDER,
          })}
        >
          <div className="d-flex justify-content-between">
            <div className={styles.name}>
              <div className={styles.number}>{index + 1}.</div>
              <div className={styles['title"']}> {product.name}</div>
            </div>
            <div className="basket">
              <button
                type="button"
                data-testid="order-item-btn-1"
                className={styles['basket-btn']}
                onClick={deleteProduct}
              >
                {availableStatus ===
                PRODUCTS_STATUSES.NOT_FOR_ORDER ? (
                  <img src={basketIconRed} alt="" />
                ) : (
                  <img
                    className={styles.trash}
                    src={basketIcon}
                    alt=""
                  />
                )}
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            {availableStatus !== PRODUCTS_STATUSES.NOT_FOR_ORDER && (
              <div className="d-flex flex-column justify-content-end">
                <div
                  className={`${styles.qty} d-flex align-items-baseline justify-content-end`}
                >
                  {currencyInt(price)} ₽ x {qty} шт.
                  <Indicator
                    full={false}
                    remainder={product.availableStatus}
                  />
                </div>
                <div className={styles['count-wrap']}>
                  <div>
                    <ProductCounter
                      id={product.id}
                      product={product}
                    />
                  </div>
                  <div className={styles.sum}>
                    {currencyInt(price * qty)} ₽
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

OrderItem.displayName = 'OrderItem';

export default OrderItem;

OrderItem.propTypes = {
  aside: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  qty: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  product: PropTypes.shape({
    price: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.number,
    availableStatus: PropTypes.number,
  }).isRequired,
  availableStatus: PropTypes.number,
};

OrderItem.defaultProps = {
  availableStatus: PropTypes.null,
};
