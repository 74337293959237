import {
  SHOW_MENU,
  SHOW_ON_MOBILE,
} from 'services/actions/actionTypes';
import { MenuActionTypes } from 'services/actions/menu-state';

const initialState = {
  isShow: false,
  isShowOnMobile: false,
};

const menuReducer = (
  state = initialState,
  action: MenuActionTypes,
) => {
  switch (action.type) {
    case SHOW_MENU: {
      return { ...state, isShow: action.isShow };
    }
    case SHOW_ON_MOBILE: {
      return { ...state, isShowOnMobile: action.isShow };
    }
    default: {
      return state;
    }
  }
};

export default menuReducer;
