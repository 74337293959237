import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { memo } from 'react';

import NumberedItem from 'components/numbered-item/numbered-item';

import styles from './numbered-list.module.css';

const cx = classNames.bind(styles);

const NumberedList = memo(({ data }) => (
  <ul className={cx('list')}>
    {data.map((item, index) => (
      <NumberedItem content={item} key={index} number={index + 1} />
    ))}
  </ul>
));

NumberedList.displayName = 'NumberedList';

export default NumberedList;

NumberedList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.node).isRequired,
};
