import { DeliveryData } from 'shared/models/delivery.model';

import {
  DELIVERY_ADDRESS_ERROR,
  DELIVERY_DATA,
  DELIVERY_FORM_READY,
  TARIFF_LOADING,
} from './actionTypes';

interface SetDeliveryDataAction {
  type: typeof DELIVERY_DATA;
  data: DeliveryData;
}

interface SetTariffLoadingAction {
  type: typeof TARIFF_LOADING;
  data: boolean;
}

interface SetDeliveryFormReadyAction {
  type: typeof DELIVERY_FORM_READY;
  data: boolean;
}

interface SetDeliveryAddressErrorAction {
  type: typeof DELIVERY_ADDRESS_ERROR;
  data: boolean;
}

export type DeliveryActionTypes =
  | SetDeliveryDataAction
  | SetTariffLoadingAction
  | SetDeliveryFormReadyAction
  | SetDeliveryAddressErrorAction;

export function setDeliveryData(
  data: DeliveryData,
): SetDeliveryDataAction {
  return { type: DELIVERY_DATA, data };
}

export function setTariffLoading(
  data: boolean,
): SetTariffLoadingAction {
  return { type: TARIFF_LOADING, data };
}

export function setDeliveryFormReady(
  data: boolean,
): SetDeliveryFormReadyAction {
  return { type: DELIVERY_FORM_READY, data };
}

export function setDeliveryAddressError(
  data: boolean,
): SetDeliveryAddressErrorAction {
  return { type: DELIVERY_ADDRESS_ERROR, data };
}
