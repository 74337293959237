import {
  IS_EDO_REGISTERED,
  IS_EDO_REGISTERED_FAILED,
  IS_EDO_REGISTERED_SUCCESS,
} from 'services/actions/actionTypes';

const initialState = {
  isEdoRegistered: false,
  isEdoRegisteredRequest: false,
  isEdoRegisteredFailed: false,
};

const isEdoReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_EDO_REGISTERED: {
      return {
        ...state,
        isEdoRegisteredRequest: true,
        isEdoRegisteredFailed: false,
      };
    }
    case IS_EDO_REGISTERED_SUCCESS: {
      return {
        ...state,
        isEdoRegistered: action.isEdoRegistered,
        isEdoRegisteredRequest: false,
      };
    }
    case IS_EDO_REGISTERED_FAILED: {
      return {
        ...state,
        isEdoRegisteredFailed: true,
        isEdoRegisteredRequest: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default isEdoReducer;
