import { memo } from 'react';

import styles from './loader-spinner.module.css';

const LoaderSpinner = memo(({ customClassName }) => (
  <svg
    className={[styles.loader, customClassName].join(' ')}
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient id="gradient">
        <stop offset="8%" stopColor="#ffffff" />
        <stop offset="70%" stopColor="#EA8242" />
      </linearGradient>
    </defs>
    <circle
      cx="50"
      cy="50"
      r="30"
      fill="none"
      stroke="url(#gradient)"
    />
  </svg>
));

LoaderSpinner.displayName = 'LoaderSpinner';

export default LoaderSpinner;
