import { memo, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import showModal from 'services/actions/modal';

import EdoRegistration from 'components/edo-registration/edo-registration';
import Modal from 'components/modal/modal';
import OfferAcceptance from 'components/offer-acceptance/offer-acceptance';

import styles from './modal-custom-edooffer.module.css';

const modalRoot = document.getElementById('modals');

const ModalCustomEdooffer = memo(() => {
  const dispatch = useDispatch();

  const { isOfferAccepted } = useSelector((store) => store.isOffer);

  const { isEdoRegistered } = useSelector((store) => store.isEdo);

  const { isEdoFlow } = useSelector((store) => store.isEdoFlow);

  const [headerText, setHeaderText] = useState('');

  const onClose = useCallback(() => {
    dispatch(showModal(false));
  }, [dispatch]);

  useEffect(() => {
    let modalText;

    const withoutOffer = !isOfferAccepted;
    const withoutEdo = isEdoFlow && !isEdoRegistered;
    const withoutRegistration = !isEdoFlow && !isEdoRegistered;

    if (withoutOffer && withoutEdo) {
      modalText = 'Согласие с Офертой и регистрация в ЭДО';
    } else if (withoutOffer) {
      modalText = 'Согласие с Офертой';
    } else if (withoutEdo) {
      modalText = 'Регистрация в ЭДО';
    } else if (withoutRegistration) {
      onClose();
    } else {
      modalText = 'Вы зарегистрированы в ЭДО и приняли Оферту';
    }

    setHeaderText(modalText);
  }, [isOfferAccepted, isEdoRegistered, isEdoFlow, onClose]);

  return ReactDOM.createPortal(
    <Modal onClose={onClose} headerText={headerText}>
      <div className={styles['modal-row']}>
        <OfferAcceptance onClose={onClose} />

        {isEdoFlow && !isEdoRegistered && (
          <div className={styles['modal-col']}>
            <EdoRegistration />
          </div>
        )}
      </div>
    </Modal>,
    modalRoot,
  );
});

ModalCustomEdooffer.displayName = 'ModalCustomEdooffer';

export default ModalCustomEdooffer;
