import { memo, useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import showLoader from 'services/actions/loader';
import showMenu, {
  showMenuOnMobile,
} from 'services/actions/menu-state';

import CustomBtn from 'components/custom-btn/custom-btn';
import OrderDelivery from 'components/order-delivery/order-delivery';
import OrderItems from 'components/order-items/order-items';

import { tokens } from 'shared/utils/tokens.util';

import { currency } from '../utils';
import styles from './order.module.css';

const Order = memo(() => {
  const { order } = useSelector((store) => store.order);

  const { isTariffLoading } = useSelector(
    (store) => store.deliveryData,
  );

  const [deliveryPrice, setDeliverPrice] = useState(0);
  const [orderLoaderFlag, setOrderLoaderFlag] = useState(false);
  const [startOrder, setStartOrder] = useState(false);
  const isAuth = tokens.getAccess();

  const dispatch = useDispatch();

  const startOrderOn = useCallback(() => {
    window.scrollTo(0, 0);
    if (!isAuth) {
      dispatch(showMenu(true));
      dispatch(showMenuOnMobile(true));
      return;
    }
    dispatch(showLoader(true));
    setOrderLoaderFlag(true);
    setStartOrder(true);
  }, [dispatch, isAuth]);

  const startOrderOff = useCallback(() => {
    setStartOrder(false);
    window.scrollTo(0, 0);
    dispatch(showLoader(false));
  }, [dispatch]);

  return (
    <div className="order mt-4 mb-4">
      <Container>
        <div className="main">
          <div className="d-md-flex">
            <OrderItems aside={false} />

            <OrderDelivery
              aside={false}
              startOrder={startOrder}
              onOrderLoaderFlagToggle={setOrderLoaderFlag}
              startOrderOff={startOrderOff}
              changeDeliveryPriceOrder={setDeliverPrice}
            />
          </div>

          <div
            className={`${styles.block} mt-3 d-flex justify-content-end`}
          >
            <div>
              <div
                className={`${styles['items-price']} mt-3 d-flex justify-content-end align-items-baseline`}
              >
                <span>Сумма заказа:</span>

                <span className={`${styles.price} ms-3`}>
                  {Boolean(order?.totalPrice) && (
                    <span className={`${styles.price} ms-3`}>
                      {`${currency(
                        order.totalPrice + deliveryPrice,
                      )} ₽`}
                    </span>
                  )}
                </span>
              </div>

              <div className="mt-4">
                <CustomBtn
                  theme="edo"
                  type="button"
                  text={isAuth ? 'Заказать' : 'Авторизуйтесь'}
                  onClick={startOrderOn}
                  testId="order-btn-1"
                  loading={orderLoaderFlag || isTariffLoading}
                  isLink={false}
                  customCls={null}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
});

Order.displayName = 'Order';

export default Order;
