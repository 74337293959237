import { TAuthProviders } from 'shared/models/authProviders.model';

export const USER_TYPES = {
  PK: 'pk',
  LK: 'lk',
};

export const AUTH_FORM_TYPES = {
  SIGN_UP: 'signUp',
  SIGN_IN: 'signIn',
};

export const AUTH_PROVIDERS = {
  http: 'http',
  keycloak: 'keycloak',
} as { [key in TAuthProviders]: TAuthProviders };
