import { FC, memo } from 'react';

import awaitingShipmentIcon from 'images/icons/awaiting-shipment.svg';
import pickupDisabledIcon from 'images/icons/bag-disabled.svg';
import shippedIcon from 'images/icons/delivered-pickup.svg';
import readyToPickupIcon from 'images/icons/ready-to-pickup.svg';

import styles from '../history-orders-item.module.css';

interface PickupStatusIconProps {
  deliveryStatusName: string;
}

export const PickupStatusIcon: FC<PickupStatusIconProps> = memo(
  ({ deliveryStatusName = null }) => {
    let statusIcon;

    switch (deliveryStatusName) {
      case 'Сборка заказа':
        statusIcon = awaitingShipmentIcon;
        break;

      case 'Готов к выдаче':
        statusIcon = readyToPickupIcon;
        break;

      case 'Отгружен':
        statusIcon = shippedIcon;
        break;

      default:
        statusIcon = pickupDisabledIcon;
        break;
    }

    return <img src={statusIcon} className={styles.icon} alt="" />;
  },
);
