import { useMask } from '@react-input/mask';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { FC, memo } from 'react';

import AuthMenu from 'components/auth-menu/auth-menu';
import CustomBtn from 'components/custom-btn/custom-btn';
import CustomInput from 'components/custom-input/custom-input';
import PhoneInput from 'components/phone-input/phone-input';

import { useAuthFormToggle } from 'shared/hooks/use-auth-form-toggle';
import { tokens } from 'shared/utils/tokens.util';

import authLogo from 'images/icons/auth-logo.svg';
import passIconClosed from 'images/icons/pass-icon-closed.svg';
import passIconOpened from 'images/icons/pass-icon-opened.svg';
import userLogo from 'images/icons/user.svg';

import InputSearch from './components/input-search/input-search';
import {
  useCaptcha,
  useEmailInput,
  useForm,
  useOrgInfo,
  usePasswordInput,
  usePhoneInput,
} from './hooks';
import styles from './registration.module.css';

export interface RegistrationProps {
  toggleMenu: (d: any) => void;
}

export const Registration: FC<RegistrationProps> = memo(
  ({ toggleMenu }) => {
    const isAuth = tokens.getAccess();
    const phone = usePhoneInput();
    const email = useEmailInput();
    const password = usePasswordInput();
    const organization = useOrgInfo();
    const captcha = useCaptcha();
    const form = useForm({
      email: email.value,
      phone: phone.cleanPhoneNumber,
      password: password.value,
      passwordRepeat: password.repeat,
      selectedOrg: organization.data,
      token: captcha.token,
      showCaptcha: captcha.handleChallengeVisible,
      anyFormErrors: [
        phone.error,
        email.error,
        password.error,
        organization.error,
      ],
    });
    const authFormToggle = useAuthFormToggle();
    const inputRef = useMask(phone.maskOptions);

    return (
      <div className={styles.registration}>
        <AuthMenu
          logo={isAuth ? userLogo : authLogo}
          toggleMenu={toggleMenu}
        />

        <hr />

        <div className={styles.header}>
          <div>Регистрация</div>

          <button
            className={styles['sign-in']}
            onClick={authFormToggle.showSignIn}
          >
            Войти
          </button>
        </div>

        <form onSubmit={form.handleSubmit}>
          <PhoneInput
            ref={inputRef}
            placeholder=""
            label="Номер мобильного телефона (Логин)"
            type="text"
            theme="edo"
            margin="mt"
            value={phone.value}
            onFocus={phone.resetError}
            onChange={phone.handleChange}
            onBlur={phone.checkValidation}
          />

          <div className={styles.errors}>{phone.error}</div>

          <CustomInput
            placeholder=""
            label="Адрес электронной почты"
            type="text"
            theme="edo"
            margin="mt"
            value={email.value}
            onFocus={email.resetError}
            onChange={email.handleChange}
            onBlur={email.checkValidation}
          />

          <div className={styles.errors}>{email.error}</div>

          <CustomInput
            placeholder=""
            label="Придумайте пароль"
            type="password"
            theme="edo"
            margin="mt"
            revealFlag={true}
            passIconOpened={passIconOpened}
            passIconClosed={passIconClosed}
            value={password.value}
            onFocus={password.resetError}
            onChange={password.handleValueChange}
          />

          <CustomInput
            placeholder=""
            label="Повторите пароль"
            type="text"
            theme="edo"
            margin="mt"
            revealFlag={true}
            passIconOpened={passIconOpened}
            passIconClosed={passIconClosed}
            value={password.repeat}
            onFocus={password.resetError}
            onChange={password.handleRepeatChange}
            onBlur={password.checkIdentical}
          />

          <div className={styles.errors}>{password.error}</div>

          <InputSearch onOrgSelect={organization.onSearch} />

          <div className={styles.captcha}>
            <InvisibleSmartCaptcha
              sitekey="ysc1_DoDK6gKBDfQkEt1WtOEgbDzhdzgtuBiTWqkpi60cf827566b"
              onSuccess={captcha.handleSuccess}
              onChallengeHidden={captcha.handleChallengeHidden}
              hideShield={true}
              visible={captcha.isVisible}
              test={captcha.isEnableTest}
            />

            <div className={styles.errors}>
              {captcha.status.message ?? ''}
            </div>
          </div>

          <div className={form.errorMessage ? 'mt-2' : 'mt-4'}>
            <div className={`mb-2 text-center ${styles.errors}`}>
              {form.errorMessage}
            </div>

            <CustomBtn
              theme="edo"
              type="submit"
              text={'Зарегистрироваться'}
              disabled={false}
              testId="auth-2"
              loading={false}
              isLink={false}
              customCls={null}
            />

            <div className={`mt-2 ${styles.info}`}>
              Нажимая Зарегистрироваться, вы соглашаетесь с{' '}
              <a href="/">Условиями использования сайта</a> и{' '}
              <a href="/">Политикой конфиденциальности</a>
            </div>
          </div>
        </form>
      </div>
    );
  },
);

Registration.displayName = 'Registration';
