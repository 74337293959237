import classNames from 'classnames/bind';
import { memo } from 'react';

import styles from './table-row.module.css';

const cx = classNames.bind(styles);

const TableRow = memo(({ tableContent }) =>
  tableContent.map((item) => (
    <tr className={cx('row')} key={item.key}>
      <td className={cx('key')}>{item.key}</td>
      {item.value.map((value) => (
        <td
          key={item.value.length + Math.random(item.value.length)}
          align="center"
          className={cx({ value: item.value.length === 4 })}
        >
          {value}
        </td>
      ))}
    </tr>
  )),
);

TableRow.displayName = 'TableRow';

export default TableRow;
