import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_SUCCESS,
} from 'services/actions/actionTypes';

const initialState = {
  products: null,
  productsRequest: false,
  productsFailed: false,
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCTS: {
      return {
        ...state,
        productsRequest: true,
        productsFailed: false,
      };
    }
    case GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.products,
        productsRequest: false,
      };
    }
    case GET_PRODUCTS_FAILED: {
      return {
        ...state,
        productsFailed: true,
        productsRequest: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default productsReducer;
