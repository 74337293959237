import { PRODUCTS_STATUSES } from 'shared/const';
import { ProductResponse } from 'shared/models/products.model';
import { IError } from 'shared/models/simple-response.model';
import httpClient from 'shared/utils/httpClient/httpClient';

import { productsUrl } from '../../urls';
import {
  GET_PRODUCTS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_SUCCESS,
} from './actionTypes';
import { logOutUser } from './auth';
import { AppDispatch } from './check-doc-flow';
import showLoader from './loader';
import { addAlert } from './page-alerts';

const getProducts = () => async (dispatch: AppDispatch) => {
  let products;

  dispatch({ type: GET_PRODUCTS });

  dispatch(showLoader(true));

  try {
    const { data } = await httpClient.get(productsUrl);
    products = (data as ProductResponse[]).filter(
      (item) =>
        item.availableStatus !== PRODUCTS_STATUSES.DO_NOT_DISPLAY,
    );
    dispatch({ type: GET_PRODUCTS_SUCCESS, products });
  } catch (error) {
    dispatch({ type: GET_PRODUCTS_FAILED });

    const err = error as IError;

    if (err?.response?.status === 500) {
      window.location.href = '/error500';
      return;
    }

    if (err?.response?.status === 401) {
      return dispatch(logOutUser());
    }

    if (!!err?.response?.status) {
      dispatch(addAlert('Ошибка получения товаров', 'danger'));
    }
  } finally {
    dispatch(showLoader(false));
  }
};

export default getProducts;
