import { SHOW_MODAL } from 'services/actions/actionTypes';

const initialState = {
  modal: false,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      return {
        modal: action.modal,
      };
    }
    default: {
      return state;
    }
  }
};

export default modalReducer;
