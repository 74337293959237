export const AUTH_USER_TYPE = 'AUTH_USER_TYPE';
export const AUTH_FORM_TYPE = 'AUTH_FORM_TYPE';
export const AUTH_PASSWORD_ERROR = 'AUTH_PASSWORD_ERROR';

export const SHOW_MENU = 'SHOW_MENU';
export const SHOW_ON_MOBILE = 'SHOW_ON_MOBILE';
export const SHOW_LOADER = 'SHOW_LOADER';
export const SHOW_MODAL = 'SHOW_MODAL';

export const DELIVERY_TYPE = 'DELIVERY_TYPE';
export const DELIVERY_DATA = 'DELIVERY_DATA';
export const DELIVERY_FORM_READY = 'DELIVERY_FORM_READY';
export const DELIVERY_ADDRESS_ERROR = 'DELIVERY_ADDRESS_ERROR';

export const TARIFF_LOADING = 'TARIFF_LOADING';

export const IS_EDO_FLOW = 'IS_EDO_FLOW';
export const IS_EDO_FLOW_FAILED = 'IS_EDO_FLOW_FAILED';
export const IS_EDO_FLOW_SUCCESS = 'IS_EDO_FLOW_SUCCESS';

export const IS_EDO_REGISTERED = 'IS_EDO_REGISTERED';
export const IS_EDO_REGISTERED_FAILED = 'IS_EDO_REGISTERED_FAILED';
export const IS_EDO_REGISTERED_SUCCESS = 'IS_EDO_REGISTERED_SUCCESS';

export const IS_OFFER_ACCEPTED = 'IS_OFFER_ACCEPTED';
export const IS_OFFER_ACCEPTED_FAILED = 'IS_OFFER_ACCEPTED_FAILED';
export const IS_OFFER_ACCEPTED_SUCCESS = 'IS_OFFER_ACCEPTED_SUCCESS';

export const GET_ORG = 'GET_ORG';
export const GET_ORG_FAILED = 'GET_ORG_FAILED';
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS';
export const CLEAR_ORG = 'CLEAR_ORG';

export const ADD_ALERT = 'ADD_ALERT';
export const DELL_ALERT = 'DELL_ALERT';
export const DELL_ALERT_BY_TEXT = 'DELL_ALERT_BY_TEXT';

export const GET_DOCS = 'GET_DOCS';
export const GET_DOCS_FAILED = 'GET_DOCS_FAILED';
export const GET_DOCS_SUCCESS = 'GET_DOCS_SUCCESS';
export const SET_DOCS_TYPES = 'SET_DOCS_TYPES';

export const CHANGE_ORDER = 'CHANGE_ORDER';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';

export const GET_INFO = 'GET_INFO';
export const GET_IMAGES_FAILED = 'GET_IMAGES_FAILED';
export const GET_DOC_SUCCESS = 'GET_DOC_SUCCESS';
export const GET_DESC_SUCCESS = 'GET_DESC_SUCCESS';
export const GET_CHAR_SUCCESS = 'GET_CHAR_SUCCESS';

export const SUCCESS_ORDER = 'SUCCESS_ORDER';
