import { FC, memo, useMemo, useState } from 'react';
import { orderDocsUrl } from 'urls';

import DownloadLink from 'components/download-link/download-link';

import paidDisabledIcon from 'images/icons/paid-disabled.svg';
import notPaidIcon from 'images/icons/paid-err.svg';
import paidIcon from 'images/icons/paid-success.svg';
import readyShipment from 'images/icons/ready-shipment.svg';

interface PayStatusIconProps {
  payStatusName: string;
  orderId: number;
  invoiceFileInfo: {
    orderId?: number;
    docType?: string;
    docId?: number;
    docFile?: {
      docName?: string;
      docBody?: string;
    };
  };
}

export const PayStatusIcon: FC<PayStatusIconProps> = memo(
  ({ payStatusName, orderId, invoiceFileInfo = null }) => {
    let statusIcon;
    const [showIcon, setShowIcon] = useState(false);

    const isPaid = useMemo(() => {
      return payStatusName !== 'Не оплачен';
    }, [payStatusName]);

    switch (payStatusName) {
      case 'Не оплачен':
        statusIcon = notPaidIcon;
        break;

      case 'Оплачен':
        statusIcon = paidIcon;
        break;

      case 'Частично оплачен':
        statusIcon = readyShipment;
        break;

      default:
        statusIcon = paidDisabledIcon;
        break;
    }
    return (
      <div
        onMouseEnter={() => isPaid && setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
        onClick={(e) => e.stopPropagation()}
        role="button"
        tabIndex={0}
        aria-hidden="true"
      >
        <DownloadLink
          link={`${orderDocsUrl}${orderId}/invoice`}
          payload={{ responseType: 'blob' }}
          textLink="Скачать счет на оплату"
          linkStyle="icon"
          fileName={invoiceFileInfo?.docFile?.docName}
          documentType="application/pdf"
          iconSrc={statusIcon}
          showedDownloadIcon={isPaid && showIcon}
          isDisabled={!isPaid}
        />
      </div>
    );
  },
);
