import {
  AUTH_FORM_TYPE,
  AUTH_PASSWORD_ERROR,
  AUTH_USER_TYPE,
} from 'services/actions/actionTypes';

import { AUTH_FORM_TYPES } from 'shared/const/user.const';

const initialState = {
  userType: null,
  authFormType: AUTH_FORM_TYPES.SIGN_IN,
  passwordError: '',
};

export type TAuthAction =
  | { type: typeof AUTH_USER_TYPE; data: string }
  | {
      type: typeof AUTH_FORM_TYPE;
      data: typeof AUTH_FORM_TYPES[keyof typeof AUTH_FORM_TYPES];
    }
  | { type: typeof AUTH_PASSWORD_ERROR; data: string };

const authReducer = (state = initialState, action: TAuthAction) => {
  switch (action.type) {
    case AUTH_USER_TYPE: {
      return {
        ...state,
        userType: action.data,
      };
    }
    case AUTH_FORM_TYPE: {
      return {
        ...state,
        authFormType: action.data,
      };
    }
    case AUTH_PASSWORD_ERROR: {
      return {
        ...state,
        passwordError: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
