import PropTypes from 'prop-types';
import { memo } from 'react';

import closeIcon from 'images/icons/close.svg';

import styles from './modal-header.module.css';

const ModalHeader = memo(({ text, onClose }) => (
  <div className={styles.header}>
    <div className="text" style={{ fontWeight: '700' }}>
      {text}
    </div>
    <div
      data-testid="modal-head-click-1"
      className={styles.close}
      onClick={onClose}
      role="button"
      aria-hidden="true"
      tabIndex="0"
    >
      <img src={closeIcon} alt="" />
    </div>
  </div>
));

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;

ModalHeader.propTypes = {
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
