import {
  DELIVERY_ADDRESS_ERROR,
  DELIVERY_DATA,
  DELIVERY_FORM_READY,
  TARIFF_LOADING,
} from 'services/actions/actionTypes';
import { DeliveryActionTypes } from 'services/actions/delivery';

const initialState = {
  isTariffLoading: false,
  isDeliveryFormReady: false,
  isDeliveryFormWasEdit: false,
  isDeliveryAddressError: false,
  deliveryData: {
    start: false,
    contactName: '',
    contactPhone: '',
    currentAddress: '',
    fiasId: null,
    dadataInfo: null,
    deliveryList: [],
    tariff: null,
    deliveryPrice: 0,
    deliveryType: 'Доставка',
    comments: '',
  },
};

const deliveryReducer = (
  state = initialState,
  action: DeliveryActionTypes,
) => {
  switch (action.type) {
    case DELIVERY_DATA: {
      return {
        ...state,
        deliveryData: action.data,
        isDeliveryFormWasEdit: true,
      };
    }

    case TARIFF_LOADING: {
      return {
        ...state,
        isTariffLoading: action.data,
      };
    }

    case DELIVERY_FORM_READY: {
      return {
        ...state,
        isDeliveryFormReady: action.data,
      };
    }

    case DELIVERY_ADDRESS_ERROR: {
      return {
        ...state,
        isDeliveryAddressError: action.data,
      };
    }

    default: {
      return state;
    }
  }
};

export default deliveryReducer;
