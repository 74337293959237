import {
  GET_CHAR_SUCCESS,
  GET_DESC_SUCCESS,
  GET_DOC_SUCCESS,
  GET_IMAGES_FAILED,
  GET_INFO,
} from 'services/actions/actionTypes';

const initialState = {
  document: [],
  description: [],
  characteristic: [],
  productCardInfoRequest: false,
  productCardInfoFailed: false,
};

const productCardInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INFO: {
      return {
        ...state,
        productCardInfoRequest: true,
        productCardInfoFailed: false,
      };
    }
    case GET_DOC_SUCCESS: {
      return {
        ...state,
        document: [...state.document, action.document],
        productCardInfoRequest: false,
      };
    }
    case GET_DESC_SUCCESS: {
      return {
        ...state,
        description: [...state.description, action.obj],
        productCardInfoRequest: false,
      };
    }
    case GET_CHAR_SUCCESS: {
      return {
        ...state,
        characteristic: [...state.characteristic, action.obj],
        productCardInfoRequest: false,
      };
    }
    case GET_IMAGES_FAILED: {
      return {
        ...state,
        productCardInfoFailed: true,
        productCardInfoRequest: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default productCardInfoReducer;
